<template>
  <!-- 案例详情 -->
  <div id="case">
    <div class="anli_detail_banner">
      <img
        src="../../assets/images/shouyepc.jpg"
      />

      <div class="text">
        <div class="w1000">
          <h2
            class="fnt_18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp"
          >
            智能锁行业领导品牌德施曼在高速发展中选择与金蝶合作，选择金蝶作为德施曼信息化战略的基石，项目方案切实可行且成功落地，满足了德施曼多元化的业务需求，实现业财税、产供销协同一体化，为德施曼高速发展提供了强有力的保障，同时也感谢项目组专业、积极的支持配合，希望尽快推进二期项目合作！
          </h2>
          <div
            class="banner-text fnt_16 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp"
          >
            ——袁良军 财务总监
          </div>
        </div>
      </div>
    </div>
    <div class="anli_detail_tag">
      <div class="w1000">
        <div class="anli_tag_text">
          <!-- <div class="bread-nav">
            <a href="https://www.kingdee.com/">金蝶云</a>
            <span>&gt;</span>
            <a href="https://www.kingdee.com/success-stories/"
              >数字化转型案例</a
            >
            <span>&gt;</span>
            <a href="javascript:;"></a
            ><a href="/success-stories/list-manufacture/">制造</a
            ><a href="/success-stories/list-mediumsize/">高成长型企业</a
            >转型案例
            <span>&gt;</span>
            <a
              href="https://www.kingdee.com/success-stories/1481563206945095682.html"
              >德施曼数字化转型案例</a
            >
          </div> -->
          <div class="img">
            <img
              src="https://www.kingdee.com/dsfile-static/temp/logo_0166_德施曼_1641882261727.jpg"
              class="img_pc"
            />
            <img
              src="https://www.kingdee.com/dsfile-static/temp/logo_0166_德施曼_1641882261727.jpg"
              class="img_mobil"
            />
          </div>
          <div class="text">
            <div class="title">
              <span class="fnt_24">德施曼</span>
              <span class="fnt_16"
                ><a href="/success-stories/list-manufacture/">制造</a> /
                <a href="/success-stories/list-mediumsize/"
                  >高成长型企业</a
                ></span
              >
            </div>
            <div class="fnt_16 subtitle" :style="{ display: 'none' }">
              产品与服务：智能门锁、智能猫眼、智能摄像头、智能保险箱等
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="anli_detail_num">
      <div class="w1000">
        <div class="num_list">
          <div class="num_item">
            <div class="fnt_42">
              <span>95%</span>
            </div>
            <div class="sub_text fnt_14">财务自动化</div>
          </div>
          <div class="num_item">
            <div class="fnt_42">
              <span>40%</span>
            </div>
            <div class="sub_text fnt_14">电商对账提升</div>
          </div>
          <div class="num_item">
            <div class="fnt_42">
              <span>20%</span>
            </div>
            <div class="sub_text fnt_14">生产效率提升</div>
          </div>
        </div>
      </div>
    </div>
    <div class="anli_detail_article">
      <div class="w1000">
        <div class="fnt_32 title">德施曼：用科技让生活更简单</div>

        <div class="text mo_text">
          <h2
            class="fnt_18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp"
          >
            智能锁行业领导品牌德施曼在高速发展中选择与金蝶合作，选择金蝶作为德施曼信息化战略的基石，项目方案切实可行且成功落地，满足了德施曼多元化的业务需求，实现业财税、产供销协同一体化，为德施曼高速发展提供了强有力的保障，同时也感谢项目组专业、积极的支持配合，希望尽快推进二期项目合作！
          </h2>
          <div
            class="banner-text fnt_16 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp"
          >
            ——袁良军 财务总监
          </div>
        </div>

        <div class="detail_article">
          <div class="anli_item">
            <div class="subtitle fnt_24">客户概述</div>
            <div class="summary fnt_16">
              <p>
                德施曼机电（中国）有限公司，是一家专注于智能家居及安防领域的企业，产品涉及智能猫眼、智能摄像头、智能手环、智能保险箱等，涵盖以智能门锁为核心的智能家居产业链，智能锁行业龙头企业，入选中国物联网企业50强的唯一智能锁企业。连续3年，京东/天猫平台销量遥遥领先。
              </p>
              <p>
                德施曼的愿景是让每一个家庭用上智能锁，成为最受信赖的品牌。专业、差异化的产品，助力德施曼在中国家居安防领域取得多项成绩。公司建立了集研发、生产、销售、服务为一体的国际化的高科技家居安防基地，并在全国设立了近百个集销售和服务为一体的分支机构，随着业务规模的高速发展，渠道、业务模式、生态链的协同、成本精细化核算变得越来越重要。
              </p>
              <p>
                匹配发展需要，德施曼急需一个业财税一体、产供销一体的数字化信息系统来支撑企业，打通各业务板块的壁垒，全面整合业务、财务数据，构建数字化管理平台，实现智慧管理分析与决策。
              </p>
            </div>
          </div>
          <div class="anli_item">
            <div class="subtitle fnt_24">业务挑战</div>
            <div class="summary fnt_16">
              <p>
                德施曼作为中国智能锁龙头企业，企业管理面临着重大挑战，原有的信息系统已不能满足其业务发展的需要，业务的创新发展促使企业要进行数字化转型。
              </p>
              <p><strong>信息化系统之间孤立，数据未有效连通</strong></p>
              <p>
                德施曼原使用K/3
                WISE系统，与德施曼的其它系统没有实现业务信息的充分共享，未形成统一的数据标准。内部小嘀电商系统与K/3
                WISE只做了简单对接，业务连贯性、追溯性、对账等方面存在一定断层以及不匹配的情况。
              </p>
              <p><strong>业财未打通，业务处理效率低</strong></p>
              <p>
                德施曼由于业务的复杂性，以及系统建设的滞后，造成大量业务与财务脱节，一些基础财务核算管理业务也需要花费大量时间和精力进行人工处理。如在系统外进行付款申请，由于不与采购订单关联，后续在花费大量的人工进行核对。从而造成财务数据滞后、准确度低。进而内部管理报表也需要耗费额外人力统计分析。
              </p>
              <p><strong>多元化销售业务，订单量大，收入对帐确认困难</strong></p>
              <p>
                德施曼拥有多种销售渠道和模式。淘宝、天猫等各大电商平台日单量最高达10万，在工程行业也有超2000个楼盘，需要按照智能锁的“安装”时点进行收入确认，并且销售收入确认还与客户金税开票相匹配；同时还有经销商、OEM等销售渠道。业务种类多，数据量大，导致对账困难。
              </p>
              <p><strong>产供销计划协同不畅，成本核算不精细</strong></p>
              <p>
                由于业务系统的数据并不互通，在产供销业务协同时，需耗费大量的人工和时间进行数据的获取，核准和转换，现在线下通过EXCEL来实现的计划预测与追踪表，数据量大、计算复杂，无法实现明细物料的预测和计划。同时费用分摊标准粗略，成本督导、考核、改善的规程不完善，难以做到精准核算成本。&nbsp;
              </p>
              <p><strong>上下游链接不足，业务协同效率低</strong></p>
              <p>
                德施曼以往依靠邮箱等线下方式与供应商进行互动，存在信息滞后，以及与供应商扯皮的情况，也很难掌握供应商的送货进度情况，不利于供应商备货及信息后续核查，同时存在供应商开票与收货不匹配的情况，发票勾稽工作量大，导致与供应商对账花费很多精力。
              </p>
            </div>
          </div>
          <div class="anli_item">
            <div class="subtitle fnt_24">解决方案</div>
            <div class="summary fnt_16">
              <p>
                在确立了信息化战略：实现集团化、精益化，实现创新驱动、产业协同和精准管理之后，德施曼希望从流程管理和数据中心两方面拉通业务领域，实现流程规范化，流程效率提升、以及流程节点及系统固化，数据资产的管理和应用，最终实现智慧运营，支撑企业创新发展。
              </p>
              <p>
                <img
                  style="display: block; margin-left: auto; margin-right: auto"
                  src="https://www.kingdee.com/dsfile-static/jeditor/12_1642067162447.png"
                  alt=""
                  width="586"
                  height="330"
                />
              </p>
              <p>
                <strong
                  >消除“信息孤岛”，实现六个统一、五个规范、四个体系、三个一体化</strong
                >
              </p>
              <p>
                德施曼借助金金蝶云·星空实现搭建统一的数字化运营平台，进行基础管理全面梳理和优化，统一了物料主数据、客商数据、BOM、会计科目表、核算与管控体系、数据标准化，同时规范了端到端流程、业务操作规则、管理制度、操作标准、绩效考核，并且构建了业务架构体系、运营管控体系、系统架构体系、IT运维与安全体系，从而搭建了业务财务税务一体化、产供销一体化、经营分析一体化的数字化平台。
              </p>
              <p>
                <strong
                  >业财税一体化，提高数据及时性，环环相扣，高效合规</strong
                >
              </p>
              <p>
                项目实现与业务系统全面打通，规范业务操作，同时与金税系统直连，在线查验发票真伪，降低税务风险，实现报表自动取数，便于统计分析。整体业务满足IPO合规性要求，同时提高了数据及时性，实现了业务财务税一体化。德施曼总账会计单红燕说到：”这次的系统升级，在原有K/3系统上进行了很大的优化改进，会计再也不用生成凭证后自己修改了，存货核算比以前线下表格计算效率提升了很多，费用和发票业务也减轻了很大的工作量，费用报销、采购付款和银企互联等线上一体化非常好用，既实现与业务打通，同时也满足内控要求，降低经验风险。”
              </p>
              <p><strong>自动大数据量对帐，打造多元化销售渠道</strong></p>
              <p>
                针对日单量最高达10万电商销售模式，采取大数据处理方式，实现了出库及按照智能锁的“安装”时点进行收入确认，提升单据处理效率。目前已平稳度过“618”等重大节日。
              </p>
              <p>
                而对于工程项目销售模式实现了合同-项目-订单的线上全流程跟踪，订单上可以查询工程项目所需的智能锁信息，同时增加里程碑事件，实现里程碑节点管理，提升了工程业务的可追溯性，同时可以查询整个工程业务智能锁安装的各个节点，工程对账单可批量自动生成，提升对账效率。
              </p>
              <p>
                <strong
                  >产供销协同，打造精益化生产系统，实现精确核算成本</strong
                >
              </p>
              <p>
                按照德施曼精益生产的管理要求，通过实施计划管理功能，并对BOM设计及流程进行优化，建立产供销协同的计划和执行体系，方便生产人员快速掌控生产进度，实现产供销平衡。正如计划物控主管邱三凤说到：“将物料按照采购周期分为15天、30天、45天和60天以上四种，并考虑对应的车间制定相应的计划运算方案，通过计划运算产生详细完备的预留关系网络，方便查询与追踪，也极大降低了新员工上手的难度。”&nbsp;
              </p>
              <p>
                新系统的JIT功能，实现按车间产能需要进行物料配送，减少库存积压，提升出库效率。生产经理程昭上提到：这次升级至星空，不仅仅是系统功能的完善和丰富，更重要的是生产流程和车间管理制度的确定，在流程及制度设计时以系统单据为依据，将工作与责任划分清晰划分，使生产的效率提高了20%。
              </p>
              <p>
                精益化生产系统为成本核算提供数据基础。财务总监袁良军说：“通过整个生产环节的打通，不仅有效地管控了生产过程中的各个环节，提升了业务处理效率，成本核算也从原来的6天缩减到现在的1天“。
              </p>
              <p><strong>链接伙伴，与供应商多维度协同</strong></p>
              <p>
                实现了与供应商的订单协同，交货协同，信息协同、库存协同等；供应商可以实时掌握订单进度以及各种消息，并及时进行处理，信息沟通线上留痕，减少与供应商的扯皮现象，业务协同更高效。“德施曼每天与供应商之间存在大量的信息互动，如采购订单、订单变更、交货计划、质量信息、财务对账信息等。供应商协同平台和提料计划的使用，建立了跨组织双赢的业务联动机制，通过双方数据信息的实时共享，实现采购、生产、质量、仓储、财务等部门与供应商的业务协同化管理，提高内外部的信息交互速度，以及时响应市场需求。“采购总监沈伟提到。
              </p>
            </div>
          </div>
          <div class="anli_item">
            <div class="subtitle fnt_24">项目价值</div>
            <div class="summary fnt_16">
              <p><strong>一体化信息运营平台，统一规范管理</strong></p>
              <p>
                搭建了统一的信息化平台，统一基础资料、规范业务流程及核算政策，建立供应商协同平台，连接企业上下游形成生态圈，确保服务及时，管理透明。同时实现与小嘀系统、协同办公等系统集成，为德施曼搭建一套一体化运营平台。
              </p>
              <p><strong>业财一体化，实现了高效合规</strong></p>
              <p>
                建设业财一体化管控及运营平台，95%凭证自动生成，实现产供销人财物全面协同；同时业务系统与财务系统的信息集成。星空多组织特性，同一数据中心管理以及统一基础资料、业务流程，消除信息孤岛。
              </p>
              <p><strong>销售服务平台，为多元化销售业务赋能提效</strong></p>
              <p>
                对多元销售业务进行了整合，新系统日处理单量高达10万单，为多元化的销售业务提供了保障，其中，对电商业务的细分，预计根据智能锁安装时点做收入确认，满足业务合规性；项目销售管理统领下游供应链单据，实时获取销售、生产、发货、收付款数据，整个销售业务可统一查询，便于管理分析与决策；
              </p>
              <p><strong>精益生产，精细化管理降本增效</strong></p>
              <p>
                生产过程精细化管理，JIT系统的应用，车间实现智能锁生产过程的精细化管理，对BOM设计进行优化，考虑替代件、跳层件、损耗率等，方便生产人员快速掌控生产进度，管控生产过程。动态齐套拉动，通过物料齐套分析，拉动库存周转，提高库存周转，实现供应商按需提料配送，降低经营成本，同时也实现了成本的透明化、精细化管理。
              </p>
              <p><strong>多组织业务协同，供应链上下游合作共赢&nbsp;</strong></p>
              <p>
                与供应商实现询报价、订单、发货、入库、对账协同；实现查看供应商备货进度、供应商库存；打通供应链上下游企业关系，与供应商紧密合作，互信互利，提升供应效率。
              </p>
            </div>
          </div>
          <div class="anli_item">
            <div class="subtitle fnt_24">数字化转型价值</div>
            <div class="summary fnt_16">
              <p>
                搭建统一的产供销协同、业财税一体化管理平台，帮助德施曼重构数据驱动的能力，实现了科学高效的成本计算过程，智能化、透明化，精细化使成本计算更准确及时，做到事前预测成本、事中控制成本，事后管理成本，满足IPO内控管理的要求，支撑起德施曼实施战略与业务模式转型。
              </p>
              <p>
                通过金蝶云·星空平台建设，帮助德施曼实现由ERP向EBC转型，实现连接客户、连接员工、连接供应商，连接数据服务，共同打造生态利益共同体，信息化建设过程中链接50+家供应商到供应协同平台中；通过报销全员应用等，服务全公司90%以上员工，实现战略与业务模式转型。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="floor" style="display: none">
        <ul class="fnt_16">
          <li class="">客户概述</li>
          <li class="">业务挑战</li>
          <li class="">解决方案</li>
          <li class="">项目价值</li>
          <li class="active">数字化转型价值</li>
        </ul>
      </div>
    </div>
    <div class="more_anli">
      <div class="big_title fnt_32">更多客户案例</div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1575403500978855937.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/PC封面_0161_司尔特肥业拷贝_1664440428330.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1575403500978855937.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/logo_0163_司尔特肥业_1664440098157.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1575403500978855937.html" title="">
                    <span>司尔特：高效协同，流程再造，集团管理提质增效</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mediumsize/">高成长型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1575388733929185282.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-传化集团_1664442566168.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1575388733929185282.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log-传化集团_1664445067650.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1575388733929185282.html" title="">
                    <span>传化集团：有效链接客户，数据驱动发展</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-gq/">国企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1575306871244349442.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-山东九羊_1664432673728.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1575306871244349442.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log-山东九羊_1664420763465.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1575306871244349442.html" title="">
                    <span>山东九羊：智能制造下的钢铁数字“森林”</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1574695233621352450.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/PC封面_0149_中车株机拷贝_1664270980641.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1574695233621352450.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/logo_0151_中车_1637026783791.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1574695233621352450.html" title="">
                    <span>中车株机：人力资源共享模式下的数字化转型</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-yq/">央企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1574656918847250433.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-圣龙股份_1664434330215.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1574656918847250433.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log-圣龙股份_1664421259290.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1574656918847250433.html" title="">
                    <span>圣龙股份：全业务链数字化 助力圣龙国际化</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1574343968009773057.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-金力永磁_1664436562412.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1574343968009773057.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log_金力_1664423665012.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1574343968009773057.html" title="">
                    <span>金力永磁：为“碳中和”的伟大事业贡献磁动力</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mediumsize/">高成长型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- <div class="g_more mobil_more">
        <a href="/success-stories/list/" title="">更多案例 &gt;</a>
      </div> -->
    </div>
  </div>
</template>

  <script>
export default {
  name: "caseDetail",
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>
  <style lang="less" scoped>
// @import "./assets/mobile/v0.1/css/new-common.css";
// @import "./assets/mobile/v0.1/css/iconfont.css";
// @import "./assets/mobile/v0.1/css/animate.min.css";
// @import "./assets/mobile/v0.1/css/swiper.min.css";
// @import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
// @import "./assets/mobile/v0.1/css/mo_xkdetail.css";
// @import "./assets/mobile/v0.1/css/header.css";
// @import "./assets/mobile/v0.1/css/footer.css";

// @import "./assets/mobile/v02/css/mo_product_gather.css";
// @import "./assets/mobile/v02/css/mo_product_pages.css";

// @import "./assets/mobile/v0.1/css/mo_score.css";
#case {
  // font-size: 16px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: PingFangM, Arial, sans-serif;
}
.anli_detail_banner {
  position: relative;
  overflow: hidden;
  img {
    height: 4.2rem;
    object-fit: cover;
    width: 100%;
    vertical-align: top;
    transition: all 1s;
    border: 0;
    display: inline-block;
  }
  .text {
    display: none;
  }
}
.anli_detail_tag {
  height: 100px;
  .w1000 {
    width: 100%;
    margin: 0 auto;
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
    max-width: 100%;
    .anli_tag_text {
      padding: 0.3rem;
      background: #fff;
      font-size: 0;
      box-shadow: 1px 1px 20px 5px rgb(0 132 246 / 20%);
      transform: translateY(-30%);
      .bread-nav {
        position: absolute;
        left: 0;
        width: 100%;
        top: -40px;
        padding: 10px 0;
        a:link,
        a:visited,
        a:active,
        a {
          color: #fff;
          font-size: 14px;
          opacity: 0.5;

          text-decoration: none;
        }
        span {
          color: #fff;
          font-size: 14px;
          margin: 0 2px;
          opacity: 0.5;
        }
      }
      .img {
        padding: 0;
        border: none;
        display: inline-block;
        vertical-align: middle;
        .img_pc {
          display: none;
        }
        .img_mobil {
          display: block;
          width: 1.4rem;
        }
        img {
          width: 114px;
          vertical-align: top;
        }
      }
      .text {
        width: calc(100% - 100px);
        margin-left: 0.3rem;
        display: inline-block;
        vertical-align: middle;
        color: #888888;
      }
      .title {
        width: 100%;
        margin-bottom: 8px;
        span:first-child {
          font-weight: 600;
          color: #333333;
          margin-right: 20px;
        }
        span {
          display: block;
        }
      }
    }
  }
}
.anli_detail_num {
  margin-top: -30px;
  margin-bottom: 2.64%;
  .w1000 {
    padding: 5% 0;
    .num_list {
      font-size: 0;
      padding: 2.1% 0;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      .num_item {
        width: 33.3%;
        display: inline-block;

        color: #888888;
        text-align: center;
        position: relative;
        vertical-align: top;
        padding: 0 10px;
        .fnt_42 {
          font-size: 0.42rem;
          font-weight: 600;
          color: #0084f6;
        }
      }
      .num_item:after {
        height: 0.8rem;
        content: "";
        width: 1px;
        // height: 80px;
        background: #e6e6e6;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.anli_detail_article {
  .title {
    margin-bottom: 1em;
  }
  .subtitle {
    font-size: 0.42rem;
    margin-bottom: 0.5em;
    color: #2386ee;
  }
  .summary {
    font-size: 16px;
    line-height: 2em;
    p {
      margin-bottom: 1em;
      line-height: 2em;
    }
  }
  img {
    max-width: 100%;
    height: auto !important;
    vertical-align: top;
  }
}
.more_anli {
  padding: 0.4rem 0 0.75rem;
  background: #f6f9fe;
  .big_title {
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
  }
  .swiper-container {
    width: 100%;
    margin: 0 auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-bottom: 0.6rem;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
    list-style: none;
    z-index: 1;
  }
  .swiper-wrapper {
    display: block;
    transform: translate3d(0, 0, 0);

    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    .swiper-slide {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      dl {
        margin-top: 0.4rem;
        width: 100%;
        margin-right: 0;
        box-shadow: none;
        background: transparent;
        border-radius: 0;
        font-size: 0;
        float: left;
        overflow: hidden;
        dt {
          display: inline-block;
          width: 23.18%;
          vertical-align: middle;
          position: relative;
          .img {
            display: none;
            width: 70px;
            height: 70px;
            bottom: -30px;
            line-height: 70px;
            overflow: hidden;

            text-align: center;

            border-radius: 100%;
            background: #fff;
            position: absolute;

            left: 30px;
            z-index: 3;
            box-shadow: 0 4px 10px #ddd;
            a {
              height: auto;
              padding: 0;
              display: block;
              overflow: hidden;
            }
          }
          .img_box {
            padding-bottom: 100%;
            width: 100%;
            height: 0px;

            transition: all 0.5s ease-in-out;
          }
          a {
            padding-bottom: 100%;
            display: block;
            height: 0px;
            overflow: hidden;
          }
          a:link,
          a:visited,
          a:active,
          a {
            text-decoration: none;
          }
        }
        dd {
          display: inline-block;
          width: 76.82%;
          padding: 0;
          padding-left: 5%;
          vertical-align: middle;
          h3 {
            border: none;
            padding-bottom: 10px;
            a {
              display: block;
              height: auto;
              overflow: auto;

              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;

              line-height: 1.5;
              color: #333333;
              span:first-child {
                display: block;
                font-size: 0.32rem;
                color: #333333;
              }
              span:last-child {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                line-height: 1.5;
              }
            }
          }
          ul {
            margin-top: 0;
            li {
              padding: 3px 5px;
              display: inline-block;
              border-radius: 5px;
              margin-right: 5px;
              a {
                font-size: 0.18rem;
              }
            }
            li:nth-of-type(1) {
              background: #e3f4fc;
              color: #0086f9;
            }
            li:nth-of-type(1) a {
              color: #0086f9;
            }
            li:nth-of-type(2) {
              background: #fcede3;
              color: #f98d00;
            }
            li:nth-of-type(2) a {
              color: #f98d00;
            }
            li:nth-of-type(3) {
              background: #e3f6fc;
              color: #00c5f1;
            }
            li:nth-of-type(3) a {
              color: #00c5f1;
            }
          }
          .fnt_20 {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.mobil_more {
  display: block;
  font-size: 14px;
  a {
    width: 1.96rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    background: #fff;
    margin: 0 auto;
    display: block;
    font-size: 0.22rem;
    border-radius: 5px;
  }
}

.w1000 {
  width: 100%;
  margin: 0 auto;
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
  max-width: 100%;
}
.fnt_14 {
  font-size: 12px;
}
.fnt_32 {
  font-size: 22px;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.mo_text {
  color: #333333;
  display: block;
  margin-bottom: 0.2rem;
  h2 {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 2em;
  }
  h2:before {
    width: 0.72rem;
    height: 0.64rem;
    content: "";
    display: block;

    // background: url(../img/anli_detail/yinhao2.png) no-repeat;
    background-size: 100%;
    opacity: 0.9;
    margin-bottom: 20px;
  }
  .banner-text {
    text-align: right;
    font-size: 0.28rem;
  }
}

@media only screen and (min-width: 1025px) {
  .swiper-container{
    text-align: center!important;
  }

  .swiper-slide{
    width: 49%!important;
    display: inline-block;
  }
  .anli_detail_tag{
    padding: 0 8%;
  }
  .anli_detail_num{
    padding: 0 8%;
  }
  .anli_detail_article{
    padding: 0 8%;
  }
  .swiper-container{
    padding:  0 8%!important;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"register"}},[_c('div',{staticClass:"top-background",staticStyle:{"height":"1rem"}}),_c('div',{style:({
    'text-align': 'center',
    'font-size': '22px',
    'font-weight': '700',
    'margin-top': '20px',
    'margin-bottom': '10px',
  })},[_c('h1',[_vm._v(_vm._s(_vm.data.name))])]),_c('div',{staticClass:"time"},[_c('span',{staticStyle:{"margin-top":"6px"}},[_vm._v(_vm._s(_vm.data.creatTime))]),_c('a',{staticClass:"download_btn date-download-bnt",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.openRegister('立即获取')}}},[_vm._v("立即获取")])]),_vm._m(0),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"text-1"},_vm._l((_vm.data.images),function(image,index){return _c('p',{key:index,staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"max-width":"750px"},style:({ width: '6.7rem' }),attrs:{"src":image}})])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_c('a',{staticStyle:{"display":"none"},attrs:{"href":"#","id":"download"}},[_vm._v("下载链接")])])
}]

export { render, staticRenderFns }
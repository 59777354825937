import api from '../api/request'
export default {
    AddAction(clickType) {
        let operationLog = sessionStorage.getItem('operationLog')
        operationLog = operationLog + "-" + clickType
        if (JSON.parse(sessionStorage.getItem('userInfo'))) {
            let param = {
                Value: operationLog,
                Source: "",
                NickName: JSON.parse(sessionStorage.getItem('userInfo')).nickname,
                OpenId: JSON.parse(sessionStorage.getItem('userInfo')).openid,
            }
            api.beyoudsky.AddAction(param).then((res) => {
                console.log(res);
                if (res.data.errorCode == "-99") {
                    console.log("出错了");
                }
            })
        }

    },
    // 获取当前时间yy年mm月dd日
    getCurrentTime() {
        const currentDate = new Date();

        const year = currentDate.getFullYear(); // 获取年份
        const month = currentDate.getMonth() + 1; // 获取月份（注意月份是从0开始计算的，所以需要加1）
        const day = currentDate.getDate(); // 获取日期
        const hours = ('0' + currentDate.getHours()).slice(-2);
        const minutes = ('0' + currentDate.getMinutes()).slice(-2);
        const seconds = ('0' + currentDate.getSeconds()).slice(-2);

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    },
    //对后端返回时间带有T进行删除
    removeT(value) {
        if (!value) return ''
        return value.replace(/T/g, ' ')
    },
}
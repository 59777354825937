<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "authorization",
  data() {
    return {
      code:'',
      ID:'',
      qrCodesId:'',
      followURL:'',
    };
  },
  created(){
    if (sessionStorage.getItem('qrCodesId')=='' || sessionStorage.getItem('qrCodesId')==null) {
      sessionStorage.setItem('qrCodesId',this.$route.query.qrCodesId);
      this.getCode()
      // alert(sessionStorage.getItem('qrCodesId'))
    } else {
      this.getCode()
      // alert(sessionStorage.getItem('qrCodesId'))
    }
    
  },
  methods:{
    getCode() {
      // let data = {
      //   openid: "o-W8KjxozWkgsA3K1l8lYXk5iGcc",
      //   nickname: "",
      //   sex: 0,
      //   province: "",
      //   city: "",
      //   country: "",
      //   headimgurl:
      //     "https://thirdwx.qlogo.cn/mmopen/vi_32/HrbY1o4wvbcY2OhZEhH4BWp41GHuDuNKia1rNVJuBQH5ybIXVQnE2uj5Ef9Bok6A1WJWhkIia4vZPuic2lLYlqlJA/132",
      //   privilege: [],
      //   unionid: "",
      // };
      // sessionStorage.setItem("userInfo", JSON.stringify(data));
      // console.log(JSON.parse(sessionStorage.getItem("userInfo")));
      // 非静默授权，第一次有弹框
      this.code = "";
      sessionStorage.removeItem("code")
      if (this.page == "shopproductdetails") {
        var local = "http://docuvix.com/#/authorization";
      } else {
        var local = "http://docuvix.com/#/authorization";
      }


      this.appid = "wx27e60c547a5ab724"; //公众号里有自己查，不会找的查一下百度
      this.code = this.getUrlCode().code; // 截取code

      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
          sessionStorage.setItem("code", this.code);
        } else {
        // alert(this.code);
        sessionStorage.setItem("code", this.code);
        // alert(sessionStorage.getItem('qrCodesId'));
        let param = {
          Code: sessionStorage.getItem('code'),
          ID:sessionStorage.getItem('qrCodesId'),
        };
        this.$api.beyoudsky.GetWXUserInfo(param).then((res) => {
          console.log(111);
          console.log(res);
          console.log(111);
          let data = JSON.parse(res.data.data);
          console.log(data.followUrl);
          this.followURL = data.followUrl
          if (res.data.errorCode == "1") {
            if (data.followStatus=="未关注公众号") {
              //跳转到关注页面
              // window.location.href = "https://mp.weixin.qq.com/s/4Ymm5Hm60hc201xyLf3evA"
              location.href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5OTgyNTkxMw==&scene=124&uin=&key=&devicetype=Windows+7+x64&version=63030073&lang=zh_CN&a8scene=7&fontgear=2#wechat_redirect";
            } else {
              this.$router.push({
                path: "/",
              })
            }
          }
          if (res.data.errorCode == "-99") {
            alert(res.data.errorText);
            return;
          }
          sessionStorage.setItem("userInfo", res.data.data);
        });
        // 你自己的业务逻辑
      }
    },
    // 截取url中的code方法
    getUrlCode() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest);
      return theRequest;
    },
  }
  
};
</script>
<style></style>

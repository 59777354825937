import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Register from '@/views/register/Register'
import PersonalInfo from '@/views/register/PersonalInfo'
import GuidePage from '@/views/GuidePage/index.vue'
import product from '@/views/GuidePage/product.vue'
import companyProfile from '@/views/GuidePage/companyProfile'
import caseCompany from '@/views/GuidePage/case'
import agreement from '@/views/GuidePage/agreement'
import allProduct from '@/views/GuidePage/allProduct'


import caseDetailTemp from '@/views/GuidePage/caseDetailTemp'
import caseDetail from '@/views/GuidePage/caseDetail'
import download from '@/views/GuidePage/download'
import sharingAgreement from '@/views/GuidePage/sharingAgreement'

import aboutMe from '@/views/GuidePage/aboutMe'
import aboutMeCopy from '@/views/GuidePage/aboutMe copy'
import followUp from '@/views/GuidePage/followUp'
import authorization from '@/views/authorization/index'
import ceshiVue from '@/views/ceshi.vue'
import testVue from '@/views/test.vue'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '道可维斯·数智文档'
    },
    // redirect:"guidepage",
    children: [
      {
        path: 'guidepage',
        name: 'guidepage',
        component: GuidePage,
        meta: {
          title: '道可维斯·数智文档'
        },
        children: [
          {
            path: 'product',
            name: 'product',
            component: product,
            meta: {
              title: '引导页'
            },
          },
          {
            path: 'companyProfile',
            name: 'companyProfile',
            component: companyProfile,
            meta: {
              title: '引导页'
            },
          },
          {
            path: 'agreement',
            name: 'agreement',
            component: agreement,
            meta: {
              title: '引导页'
            },
          },
          {
            path: 'caseCompany',
            name: 'caseCompany',
            component: caseCompany,
            meta: {
              title: '引导页'
            },
          },
        ]
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import ('@/views/ActivityPage'),
        meta: {
          title: '招募产品体验官-老系统性能提升加固优化方案'
        }
      },
      {
        path: 'allProduct',
        name: 'allProduct',
        component: allProduct,
        meta: {
          title: '道可维斯·数智文档-产品'
        }
      },
      {
        path:'aboutMe',
        name:'aboutMe',
        component:aboutMe,
        meta:{
          title:'关于我们'
        }
      },
      {
        path:'aboutMeCopy',
        name:'aboutMeCopy',
        component:aboutMeCopy,
        meta:{
          title:'关于我们'
        }
      },
      {
        path:'richText',
        name:'ceshiVue',
        component:ceshiVue,
        meta:{
          title:'自定义页面内容'
        }
      },
      {
        path: 'download',
        name: 'download',
        component: download,
        meta: {
          title: '资料下载'
        },
      },

      {
        path: 'caseDetail',
        name: 'caseDetail',
        component: caseDetail,
        meta: {
          title: '道可维斯·数智文档-案例详情'
        },
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        meta: {
          title: '加入我们'
        }
      },
      {
        path: 'complain',
        name: 'complain',
        component: () => import ('@/views/Complain'),
        meta: {
          title: '意见箱'
        }
      },
      
    ]
  },
  {
    path: '/question',
    name: 'question',
    component: () => import ('@/views/Question'),
    meta: {
      title: '我要提问'
    }
  },
  {
    path: '/answer',
    name: 'answer',
    component: () => import ('@/views/Question/answer'),
    meta: {
      title: '问题回复'
    }
  },
  {
    path: '/answerTo',
    name: 'answerTo',
    component: () => import ('@/views/Question/answerTo'),
    meta: {
      title: '问题回复'
    }
  },
  {
    path: '/distributeContacts',
    name: 'distributeContacts',
    component: () => import ('@/views/Question/distributeContacts'),
    meta: {
      title: '分配联系人'
    }
  },
  {
    path: '/need',
    name: 'need',
    component: () => import ('@/views/Question/need'),
    meta: {
      title: '需求'
    }
  },
  {
    path: '/sharingAgreement',
    name: 'sharingAgreement',
    component: sharingAgreement,
    meta: {
      title: '分成协议'
    }
  },
  {
    path: '/followUp',
    name: 'followUp',
    component: followUp,
    meta: {
      title: '线索跟进'
    }
  },
  
  {
    path: '/authorization',
    name: 'authaorization',
    component: authorization,
    meta: {
      title: '授权登录'
    }
  },
  
]



const router = new VueRouter({
  // mode: 'history',
  // base: '/internetmarketing_v0.2',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { y: 0 }
  },
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // console.log(router);
  if(to.path=="/") {
    next("/guidepage")
  }
  else {
    next()
  }
})


export default router

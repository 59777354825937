<template>
  <!-- 完善个人信息 -->

  <div id="PersonalInfo">
    <div class="title">
      <p class="welcome-name">完善个人信息</p>
      <p class="cue-info">（注册成功后可在“我的”菜单中进行修改）</p>
      <!-- <p class="el-icon-user-solid head-icon"></p> -->
    </div>
    <div class="content">
      <div class="main-content">
        <el-form
          ref="userInfo"
          label-position="top"
          label-width="80px"
          :model="userInfo"
          :rules="rules"
        >
          <el-form-item prop="name">
            <el-input
              v-model="userInfo.name"
              placeholder="请输入姓名"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              v-model="userInfo.phone"
              placeholder="请输入手机号"
              prefix-icon="el-icon-mobile"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="userInfo.email"
              placeholder="请输入邮箱地址"
              prefix-icon="el-icon-message"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="bottom">
          <!-- <el-button @click="goback">上一步</el-button> -->
          <el-button type="primary" @click="submitForm">立即完善</el-button>
          <el-button @click="later">以后完善</el-button>
        </div>
      </div>
      <div class="logo">
        <img
          src="@/assets/images/logo1.png"
          alt="广州道可维斯技术有限公司"
          class="companyIcon"
        />
        <span id="line"></span>
        <img
          src="@/assets/images/logo2.png"
          alt="e企家族"
          class="companyIcon"
        />
      </div>
    </div>

    <!-- 协议 -->
    <div class="footer" v-show="hideshow">
      <p>CopyRight © 2009-2029 Docuvix All Rights Reserved</p>
      <p>粤ICP备13024405号</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalInfo",
  data() {
    return {
      userInfo: {
        name: "",
        phone: "",
        email: "",
      },
      rules: {
        name: [
          {
            min: 3,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "change",
          },
        ],
        phone: [
          { min: 11, max: 11, message: "长度为 11 个字符", trigger: "blur" },
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      docmHeight:
        document.documentElement.clientHeight || document.body.clientHeight,
      showHeight:
        document.documentElement.clientHeight || document.body.clientHeight,
      hideshow: true, //显示或者隐藏footer
    };
  },
  watch: {
    //监听显示高度
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        //隐藏
        this.hideshow = false;
      } else {
        //显示
        this.hideshow = true;
      }
    },
  },
  mounted() {
    //监听事件
    window.onresize = () => {
      return (() => {
        this.showHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      })();
    };
  },
  methods: {
    goback() {
      let type = this.$route.query.type;
      this.$router.push({ path: "/", query: { type: type } });
    },
    submitForm() {
      this.$refs["userInfo"].validate((valid) => {
        // console.log(valid);
        if (valid) {
          // alert("敬请期待！（注册完成后跳转引导页）");
          let param = {
            WeChatID: "o-W8Kj2bih689T6HcgLKzHfkHu9s",
            PhoneNum: this.userInfo.phone,
            Email: this.userInfo.email,
            Name: this.userInfo.name,
          };
          this.$api.beyoudsky.UpdateWXUserInfo(param).then((res) => {
            // console.log(res)
            this.$router.push("/guidepage");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 以后完善
    later() {
      // alert("敬请期待！（注册完成后跳转引导页）");
      this.$router.push("/guidepage");
    },
  },
};
</script>

<style lang="less" scoped>
#PersonalInfo {
  height: 100%;
  padding: 0 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
  background-image: url("@/assets/images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .title {
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    box-sizing: border-box;

    .welcome-name {
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .welcome-project {
      font-size: 20px;
    }

    .welcome-join {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: calc(80% - 15px);
    width: 100%;
    box-sizing: border-box;
    // background-color: #fff;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .logo {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      // height: 31px;
      text-align: center;
      // margin-top: 20px;

      img {
        // width: 200px;
        height: 36px;
      }

      #line {
        display: inline-block;
        height: 40px;
        width: 1px;
        background-color: #c0c4cc;
        margin: 0 15px;
      }
    }

    .main-content {
      width: 100%;
      background-color: #fff;
      border-radius: 20px;
      padding: 25px 20px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    /deep/.el-input {
      font-size: 20px;

      .el-input__inner {
        border: none;
        border-bottom: 2px solid #f7f7f8;
        border-radius: 0;
        padding-left: 35px;
        height: 48px;
        background-color: #f5f7f7;
        border-radius: 26px;
      }

      .el-input__icon {
        // top: -2px;
        // line-height: 48px !important;
      }
    }

    /deep/.el-form {
      width: 100%;
      margin-bottom: 10px;

      .el-form-item {
        width: 100%;
        margin-bottom: 18px;

        .el-form-item__content {
          margin-left: 0 !important;
          width: 100%;

          .el-form-item__error {
            // margin-left: 20px;
          }
        }
      }
    }

    .el-radio-group {
      width: 100%;
    }
    /deep/.el-radio {
      width: calc(50% - 15px);
      margin-left: 0;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 20%;
    width: 100%;
    margin-top: 50px;

    .el-button {
      width: 100%;
      border-radius: 26px;
      padding: 16px 20px;
      // box-shadow: 0 4px 12px 0 rgb(64 158 255 / 0.5);
      font-size: 20px;
      margin-bottom: 20px;
      margin-left: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      color: #909399;
      font-size: 14px;
    }
  }

  .footer {
    // position: absolute;
    // bottom: 20px;
    // right: 50%;
    // margin-top: 20px;
    // transform: translate(50%, 0);
    text-align: center;
    font-size: 14px;
    color: #909399;
    height: 15px;

    header {
      margin-bottom: 20px;
    }
  }
}
</style>

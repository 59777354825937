<template>
  <div class="components-container" style="margin: 150px">
    <!-- <aside>
      Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.
      <a target="_blank" class="link-type" href="https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html">Documentation</a>
    </aside> -->
    <div>
      <tinymce v-model="content" :height="300" />
    </div>
    <el-button @click="save">保存</el-button>
    <!-- <video width="400" height="300" controls>
      <source src="http://112.74.163.215:50025/tempfiles/20230519025162034/d755dea2341c152c54797c4488a78fd9.mp4" type="video/mp4" />
      您的浏览器不支持 video 标签。
    </video> -->
  </div>
</template>

<script>
import tinymce from "@/components/Tinymce copy/index.vue";
// import '@/styles/index.scss' // global css
export default {
  name: "ceshi",
  components: {
    tinymce,
  },
  data() {
    return {
      content: ``,
      html: "",
      value: "",
      file: {},
    };
  },
  created() {
    // this.get();
  },
  updated() {},
  methods: {
    save() {
      console.log(this.content);
      let param = {
        Key: "html",
        Value: this.content,
      };
      // console.log(this.file);
      this.$api.DrawingIdentification.SaveViewMsg(param).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
#ceshi {
  margin-top: 160px;

  .editor-content {
    color: aqua;

    /deep/p {
      color: aqua;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
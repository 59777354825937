<template>
  <!-- 分成协议 -->
  <div id="sharingAgreement" class="wrapper">
    <el-container>
      <el-header class="header">      
        <p @click="backTo"><i class="el-icon-arrow-left"></i>返回</p>
        <!-- <img
          src="@/assets/images/logo.png"
          alt="广州道可维斯技术有限公司"
          class="companyIcon"
      /> -->
      </el-header>
      <el-main>
        <div class="article">
          <p class="title">分成协议</p>
          <div class="articlePart">
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;1、如果组合成员的年投资回报率少于10%，则管理人不收取管理费用，全部收益归投资人；
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;2、组合成员年投资回报率超过10%以上部分，组合成员和管理人各得50%;
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;3、自组合成员参加之日起，每半年计算收益和进行分配一次；
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;4、如果组合成员退出，则同时结算收益并进行分配；
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;5、组合成员进出自由，投资亏损管理人无须负责；
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;6、组合分成协议每年修订一次，在12月20日前公布；
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;
              7、以上协议自2007年1月1日起执行，协议如有未尽事宜，由投资人和管理人协商解决。
            </p>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
  
  <script>
export default {
  name: "sharingAgreement",
  data() {
    return {

    };
  },
  methods: {
    //返回签约页面
    backTo() {
        this.$router.back()
    }
  },
};
</script>
  
<style lang="less" scoped>
#sharingAgreement {
  width: 100%;
  height: 100%;
  .el-container {
    width: 100%;
    height: 100%;
    .el-header {
        padding-left: 10px;
      height: 50px !important;
      p {
        font-size: 20px;
        line-height: 50px;
      }
      .companyIcon {
        float: left;
        margin-top: 15px;
        width: 80px;
      }
    }

    .el-main {
      padding-top: 0;
      height: 100%;
      .article {
        .title {
          color: #000;
          text-align: center;
          font-size: 32px;
        }
        .articlePart {
          padding-top: 10px;
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
}
</style>
  
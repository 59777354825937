import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui'
import Vant from 'vant';
import 'vant/lib/index.css';
import api from './api/request'
import utils from './utils/utils.js';
import $ from 'jquery'
import axios from '@/utils/http'; // 导入http中创建的axios实例
import './assets/iconfont/iconfont.css'
import  QRCode  from "qrcodejs2";
import VueClipboard from 'vue-clipboard2'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
    inline: false, // 是否启用inline模式
    button: true, // 是否显示右上角关闭按钮
    navbar: true, // 是否显示缩略图底部导航栏
    title: true, // 是否显示当前图片标题，默认显示alt属性内容和尺寸
    toolbar: true, // 是否显示工具栏
    tooltip: true, // 放大或缩小图片时，是否显示缩放百分比，默认true
    fullscreen: true, // 播放时是否全屏，默认true
    loading: true, // 加载图片时是否显示loading图标，默认true
    loop: true, // 是否可以循环查看图片，默认true
    movable: true, // 是否可以拖动图片，默认true
    zoomable: true, // 是否可以缩放图片，默认true
    rotatable: true, // 是否可以旋转图片，默认true
    scalable: true, // 是否可以翻转图片，默认true
    toggleOnDblclick: true, // 放大或缩小图片时，是否可以双击还原，默认true
    transition: true, // 使用 CSS3 过度，默认true
    keyboard: true, // 是否支持键盘，默认true
    zoomRatio: 0.1, // 鼠标滚动时的缩放比例，默认0.1
    minZoomRatio: 0.01, // 最小缩放比例，默认0.01
    maxZoomRatio: 100, // 最大缩放比例，默认100
    url: 'data-source' , //设置大图片的url
})

// import Vconsole from 'vconsole';
// const vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
Vue.prototype.$api = api; // 将api挂载到vue的原型上
Vue.prototype.$utils = utils; // 将js方法挂载到vue的原型上
Vue.prototype.$message = Message;
Vue.prototype.$ = $;
Vue.prototype.QRCode = QRCode;



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/**
 * 基础参数的管理
 */
 const inputJson = {
    PageName: "index",
    ClassName: "GeneralDb",
    AppID: "0001",
}
const appID = "0001"
export {inputJson, appID};




<template>
  <!-- 口号宣传页 -->
  <div id="guidepage">
    <el-container>
      <el-header class="header"
        ><img
          src="@/assets/images/logo.png"
          alt="广州道可维斯技术有限公司"
          class="companyIcon"
      /></el-header>
      <el-main>
        <div class="slogan">
          <P>一次分享 三年收成</P>
          <el-button type="primary" class="agencyBtn" :disabled="!checked"
            >立即签约</el-button
          >
          <div class="agreementTip">
            <div class="word">
              <el-checkbox v-model="checked"> </el-checkbox> 点击 “立即签约”
              即表示您同意并愿意遵守<span
                class="agreement"
                @click="openAgreement"
                >分成协议</span
              >
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "agreement",

  data() {
    return {
      checked: false,
    };
  },
  methods: {
    //打开提问框
    openQuestion() {
      this.drawer = true;
    },
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
  },
};
</script>

<style lang="less" scoped>
#guidepage {
  height: 100%;
  width: 100%;
  position: relative;

  .el-container {
    width: 100%;
    height: 100%;
    .el-header {
      height: 50px !important;
      .companyIcon {
        float: left;
        margin-top: 15px;
        width: 80px;
      }
    }

    .el-main {
      height: 100%;
      .slogan {
        height: 100%;
        text-align: center;
        position: relative;
        .agencyBtn {
          padding: 15px 20px;
          bottom: 90px;
          left: 0;
          position: absolute;
          width: 100%;
          font-size: 20px;
        }
        .agreementTip {
          position: absolute;
          bottom: 65px;
          left: calc(50% - 164px);
          font-size: 14px;
          text-align: center;
          .agreement {
            color: red;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>

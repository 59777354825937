<template>
  <!-- 案例详情 -->
  <div id="case">
    <div class="anli_detail_banner">
      <!-- 顶部背景 -->
      <img
        :src="(caseDetails[indexss].BasicInfo.backgroundImg)"
      />
    </div>
    <!-- 公司信息 -->
    <div class="anli_detail_tag">
      <div class="w1000">
        <div class="anli_tag_text">
          <div class="img">
            <img
              :src="caseDetails[indexss].BasicInfo.logo"
              class="img_pc"
            />
            <img
              :src="caseDetails[indexss].BasicInfo.logo"
              class="img_mobil"
            />
          </div>
          <!-- 公司标签 -->
          <div class="text">
            <div class="title">
              <span class="fnt_24">{{caseDetails[indexss].BasicInfo.companyName}}</span>
                <span class="fnt_16">
                 <a :href="caseDetails[indexss].BasicInfo.tags[0].link">{{caseDetails[indexss].BasicInfo.tags[0].tag}}</a> /
                <a :href="caseDetails[indexss].BasicInfo.tags[1].link"
                  >{{caseDetails[indexss].BasicInfo.tags[1].tag}}</a>
                </span
              >
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 数据展示 -->
    <div class="anli_detail_num">
      <div class="w1000">
        <div class="num_list">
          <div class="num_item" v-for="bid,index in caseDetails[indexss].BasicInfo.data" :key="index">
            <div class="fnt_42">
              <span>{{bid.percent}}</span>
            </div>
            <div class="sub_text fnt_14">{{bid.describe}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="anli_detail_article">
      <div class="w1000">
        <!-- 公司名：主题 -->
        <div class="fnt_32 title">{{caseDetails[indexss].BasicInfo.companyName}}：{{caseDetails[indexss].BasicInfo.subject}}</div>
        <!-- 领导语录 -->
        <div class="text mo_text" >
          <h2
            class="fnt_18 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp"
          >
            {{caseDetails[indexss].BasicInfo.quotation}}</h2>
          <div
            class="banner-text fnt_16 wow fadeInUp animated"
            style="visibility: visible; animation-name: fadeInUp"
          >
            {{caseDetails[indexss].BasicInfo.leaderName}}
          </div>
        </div>

        <div class="detail_article" style="margin-bottom:30px">
          <div class="anli_item">
            <div class="subtitle fnt_24">客户概述</div>
            <div class="summary fnt_16" v-for="o,oindex in caseDetails[indexss].Overview" :key="oindex">
              <p>{{o}}
              </p>
            </div>
          </div>

          <div class="anli_item">
            <div class="subtitle fnt_24">业务挑战</div>
            <div class="summary fnt_16" >
              <p v-for="cs,csindex in caseDetails[indexss].Challenges.summary" :key="csindex">
                {{cs}}
              </p>
              <p>
                <img
                  style="display: block; margin-left: auto; margin-right: auto"
                  :src="caseDetails[indexss].Challenges.summaryPic"
                  alt=""
                  width="586"
                  height="330"
                  v-show="caseDetails[indexss].Challenges.summaryPic != ''"
                />
              </p>
              <div v-for="cp,cpindex in caseDetails[indexss].Challenges.parts" :key="cpindex">
                <p><strong>{{cp.title}}</strong></p>
              <p v-for="cc,ccindex in cp.content" :key="ccindex">
                {{cc}}
              </p>
              <p>
                <img
                  style="display: block; margin-left: auto; margin-right: auto"
                  :src="cp.imgSrc"
                  alt=""
                  width="586"
                  height="330"
                  v-show="cp.imgSrc != ''"
                />
              </p>
              </div>
            </div>
          </div>

          <div class="anli_item">
            <div class="subtitle fnt_24">解决方案</div>
            <div class="summary fnt_16">
              <p v-for="ss,ssindex in caseDetails[indexss].Solution.summary" :key="ssindex">
                {{ss}}
              </p>
              <p>
                <img
                  style="display: block; margin-left: auto; margin-right: auto"
                  :src="caseDetails[indexss].Solution.summaryPic"
                  alt=""
                  width="586"
                  height="330"
                  v-show="caseDetails[indexss].Solution.summaryPic != ''"
                />
              </p>
              <div v-for="sp,spindex in caseDetails[indexss].Solution.parts" :key="spindex">
                <p>
                <strong
                  >{{sp.title}}</strong
                >
              </p>
              <p v-for="sc,scindex in sp.content" :key="scindex">
                {{sc}}
              </p>
              <p>
                <img
                  style="display: block; margin-left: auto; margin-right: auto"
                  :src="sp.imgSrc"
                  alt=""
                  width="586"
                  height="330"
                  v-show="sp.imgSrc != ''"
                />
              </p>
              </div>
            </div>
          </div>

          <div class="anli_item" v-show="caseDetails[indexss].ProjectValue.showFlag">
            <div class="subtitle fnt_24">项目价值</div>
            <div class="summary fnt_16">
              <p>
                {{caseDetails[indexss].ProjectValue.summary}}
              </p>
            </div>
            <div class="summary fnt_16" v-for="pvp,pvpindex in caseDetails[indexss].ProjectValue.parts" :key="pvpindex">
              <p><strong>{{pvp.title}}</strong></p>
              <p>
                {{pvp.content}}
              </p>
            </div>
          </div>

          <div class="anli_item" v-show="caseDetails[indexss].TransformationValue.length > 0">
            <div class="subtitle fnt_24" >数字化转型价值</div>
            <div class="summary fnt_16" v-for="tv,tvindex in caseDetails[indexss].TransformationValue" :key="tvindex">
              <p>
                {{tv}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="floor" style="display: none">
        <ul class="fnt_16">
          <li class="">客户概述</li>
          <li class="">业务挑战</li>
          <li class="">解决方案</li>
          <li class="">项目价值</li>
          <li class="active">数字化转型价值</li>
        </ul>
      </div>
    </div>
    <!-- <div class="more_anli">
      <div class="big_title fnt_32">更多客户案例</div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1575403500978855937.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/PC封面_0161_司尔特肥业拷贝_1664440428330.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1575403500978855937.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/logo_0163_司尔特肥业_1664440098157.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1575403500978855937.html" title="">
                    <span>司尔特：高效协同，流程再造，集团管理提质增效</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mediumsize/">高成长型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1575388733929185282.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-传化集团_1664442566168.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1575388733929185282.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log-传化集团_1664445067650.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1575388733929185282.html" title="">
                    <span>传化集团：有效链接客户，数据驱动发展</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-gq/">国企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1575306871244349442.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-山东九羊_1664432673728.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1575306871244349442.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log-山东九羊_1664420763465.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1575306871244349442.html" title="">
                    <span>山东九羊：智能制造下的钢铁数字“森林”</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1574695233621352450.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/PC封面_0149_中车株机拷贝_1664270980641.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1574695233621352450.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/logo_0151_中车_1637026783791.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1574695233621352450.html" title="">
                    <span>中车株机：人力资源共享模式下的数字化转型</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-yq/">央企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1574656918847250433.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-圣龙股份_1664434330215.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1574656918847250433.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log-圣龙股份_1664421259290.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1574656918847250433.html" title="">
                    <span>圣龙股份：全业务链数字化 助力圣龙国际化</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-large/">大型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div class="swiper-slide">
            <dl>
              <dt>
                <a
                  href="/success-stories/1574343968009773057.html"
                  title=""
                  rel="nofollow"
                >
                  <div
                    class="img_box"
                    style="
                      background: url(https://www.kingdee.com/dsfile-static/temp/pc-金力永磁_1664436562412.jpg)
                        no-repeat center;
                      background-size: cover;
                    "
                  ></div>
                </a>
                <div class="img">
                  <a
                    href="/success-stories/1574343968009773057.html"
                    title=""
                    rel="nofollow"
                    ><img
                      src="https://www.kingdee.com/dsfile-static/temp/log_金力_1664423665012.jpg"
                      alt=""
                  /></a>
                </div>
              </dt>
              <dd>
                <h3 class="fnt_20">
                  <a href="/success-stories/1574343968009773057.html" title="">
                    <span>金力永磁：为“碳中和”的伟大事业贡献磁动力</span>
                  </a>
                </h3>
                <ul>
                  <li>
                    <a href="/success-stories/list-manufacture/">制造</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mq/">民企</a>
                  </li>
                  <li>
                    <a href="/success-stories/list-mediumsize/">高成长型企业</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="g_more mobil_more">
        <a href="/success-stories/list/" title="">更多案例 &gt;</a>
      </div>
    </div> -->
  </div>
</template>

  <script>
import {caseDetails} from '@/utils/caseDetails'
export default {
  name: "caseDetail",
  data() {
    return {
      indexss:0,
      caseDetails : caseDetails
    };
  },
  created() {
    var title = this.$route.query.title
    // if(title.includes("德施曼")){
    //   this.indexss = 0
    // }else 
    if(title.includes("彩生活")){
      this.indexss = 1
    }else if(title.includes("汉森")){
      this.indexss = 2
    }else if(title.includes("洁柔")){
      this.indexss = 3
    }else if(title.includes("欧诗漫")){
      this.indexss = 4
    }else if(title.includes("沈阳特变")){
      this.indexss = 5
    }else if(title.includes("盈德气体")){
      this.indexss = 6
    }else if(title.includes("招商港口")){
      this.indexss = 7
    }else if(title.includes("正通")){
      this.indexss = 8
    }
    
  },
  methods: {},
  mounted() {},
};
</script>
  <style lang="less" scoped>
#case {
  // font-size: 16px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: PingFangM, Arial, sans-serif;
}
.anli_detail_banner {
  position: relative;
  overflow: hidden;
  img {
    height: 4.2rem;
    object-fit: cover;
    width: 100%;
    vertical-align: top;
    transition: all 1s;
    border: 0;
    display: inline-block;
  }
  .text {
    display: none;
  }
}
.anli_detail_tag {
  height: 100px;
  .w1000 {
    width: 100%;
    margin: 0 auto;
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
    max-width: 100%;
    .anli_tag_text {
      padding: 0.3rem;
      background: #fff;
      font-size: 0;
      box-shadow: 1px 1px 20px 5px rgb(0 132 246 / 20%);
      transform: translateY(-30%);
      .bread-nav {
        position: absolute;
        left: 0;
        width: 100%;
        top: -40px;
        padding: 10px 0;
        a:link,
        a:visited,
        a:active,
        a {
          color: #fff;
          font-size: 14px;
          opacity: 0.5;

          text-decoration: none;
        }
        span {
          color: #fff;
          font-size: 14px;
          margin: 0 2px;
          opacity: 0.5;
        }
      }
      .img {
        padding: 0;
        border: none;
        display: inline-block;
        vertical-align: middle;
        .img_pc {
          display: none;
        }
        .img_mobil {
          display: block;
          width: 1.4rem;
        }
        img {
          width: 114px;
          vertical-align: top;
        }
      }
      .text {
        width: calc(100% - 100px);
        margin-left: 0.3rem;
        display: inline-block;
        vertical-align: middle;
        color: #888888;
      }
      .title {
        width: 100%;
        margin-bottom: 8px;
        span:first-child {
          font-weight: 600;
          color: #333333;
          margin-right: 20px;
        }
        span {
          display: block;
        }
      }
    }
  }
}
.anli_detail_num {
  margin-top: -30px;
  margin-bottom: 2.64%;
  .w1000 {
    padding: 5% 0;
    .num_list {
      font-size: 0;
      padding: 2.1% 0;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      .num_item {
        width: 33.3%;
        display: inline-block;

        color: #888888;
        text-align: center;
        position: relative;
        vertical-align: top;
        padding: 0 10px;
        .fnt_42 {
          font-size: 0.42rem;
          font-weight: 600;
          color: #0084f6;
        }
      }
      .num_item:after {
        height: 0.8rem;
        content: "";
        width: 1px;
        // height: 80px;
        background: #e6e6e6;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.anli_detail_article {
  .title {
    margin-bottom: 1em;
  }
  .subtitle {
    font-size: 0.42rem;
    margin-bottom: 0.5em;
    color: #2386ee;
  }
  .summary {
    font-size: 16px;
    line-height: 2em;
    p {
      margin-bottom: 1em;
      line-height: 2em;
    }
  }
  img {
    max-width: 100%;
    height: auto !important;
    vertical-align: top;
  }
}
.more_anli {
  padding: 0.4rem 0 0.75rem;
  background: #f6f9fe;
  .big_title {
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
  }
  .swiper-container {
    width: 100%;
    margin: 0 auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-bottom: 0.6rem;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
    list-style: none;
    z-index: 1;
  }
  .swiper-wrapper {
    display: block;
    transform: translate3d(0, 0, 0);

    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    .swiper-slide {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      dl {
        margin-top: 0.4rem;
        width: 100%;
        margin-right: 0;
        box-shadow: none;
        background: transparent;
        border-radius: 0;
        font-size: 0;
        float: left;
        overflow: hidden;
        dt {
          display: inline-block;
          width: 23.18%;
          vertical-align: middle;
          position: relative;
          .img {
            display: none;
            width: 70px;
            height: 70px;
            bottom: -30px;
            line-height: 70px;
            overflow: hidden;

            text-align: center;

            border-radius: 100%;
            background: #fff;
            position: absolute;

            left: 30px;
            z-index: 3;
            box-shadow: 0 4px 10px #ddd;
            a {
              height: auto;
              padding: 0;
              display: block;
              overflow: hidden;
            }
          }
          .img_box {
            padding-bottom: 100%;
            width: 100%;
            height: 0px;

            transition: all 0.5s ease-in-out;
          }
          a {
            padding-bottom: 100%;
            display: block;
            height: 0px;
            overflow: hidden;
          }
          a:link,
          a:visited,
          a:active,
          a {
            text-decoration: none;
          }
        }
        dd {
          display: inline-block;
          width: 76.82%;
          padding: 0;
          padding-left: 5%;
          vertical-align: middle;
          h3 {
            border: none;
            padding-bottom: 10px;
            a {
              display: block;
              height: auto;
              overflow: auto;

              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;

              line-height: 1.5;
              color: #333333;
              span:first-child {
                display: block;
                font-size: 0.32rem;
                color: #333333;
              }
              span:last-child {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                line-height: 1.5;
              }
            }
          }
          ul {
            margin-top: 0;
            li {
              padding: 3px 5px;
              display: inline-block;
              border-radius: 5px;
              margin-right: 5px;
              a {
                font-size: 0.18rem;
              }
            }
            li:nth-of-type(1) {
              background: #e3f4fc;
              color: #0086f9;
            }
            li:nth-of-type(1) a {
              color: #0086f9;
            }
            li:nth-of-type(2) {
              background: #fcede3;
              color: #f98d00;
            }
            li:nth-of-type(2) a {
              color: #f98d00;
            }
            li:nth-of-type(3) {
              background: #e3f6fc;
              color: #00c5f1;
            }
            li:nth-of-type(3) a {
              color: #00c5f1;
            }
          }
          .fnt_20 {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.mobil_more {
  display: block;
  font-size: 14px;
  a {
    width: 1.96rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    background: #fff;
    margin: 0 auto;
    display: block;
    font-size: 0.22rem;
    border-radius: 5px;
  }
}

.w1000 {
  width: 100%;
  margin: 0 auto;
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
  max-width: 100%;
}
.fnt_14 {
  font-size: 12px;
}
.fnt_32 {
  font-size: 22px;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.mo_text {
  color: #333333;
  display: block;
  margin-bottom: 0.2rem;
  h2 {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 2em;
  }
  h2:before {
    width: 0.72rem;
    height: 0.64rem;
    content: "";
    display: block;

    // background: url(../img/anli_detail/yinhao2.png) no-repeat;
    background-size: 100%;
    opacity: 0.9;
    margin-bottom: 20px;
  }
  .banner-text {
    text-align: right;
    font-size: 0.28rem;
  }
}

@media only screen and (min-width: 1025px) {
  .swiper-container{
    text-align: center!important;
  }

  .swiper-slide{
    width: 49%!important;
    display: inline-block;
  }
  .anli_detail_tag{
    padding: 0 8%;
  }
  .anli_detail_num{
    padding: 0 8%;
  }
  .anli_detail_article{
    padding: 0 8%;
  }
  .swiper-container{
    padding:  0 8%!important;
  }
}
</style>
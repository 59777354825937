<template>
  <!-- 案例目录页面 -->
  <div id="guidepage">
    <el-container>
      <el-header class="header"
        ><img
          src="@/assets/images/logo.png"
          alt="广州道可维斯技术有限公司"
          class="companyIcon"
      /></el-header>
      <el-main>
        <div class="slogan">
          <P class="title">案例名录</P>
          <div class="companyList">
            <div class="image" v-for="(item, index) in company" :key="index">
              <img :src="item.image" :alt="item.name" />
            </div>
          </div>
          <el-button type="primary" class="agencyBtn" :disabled="!checked"
            >立即签约</el-button
          >
          <div class="agreementTip">
            <div class="word">
              <el-checkbox v-model="checked"> </el-checkbox> 点击 “立即签约”
              即表示您同意并愿意遵守<span
                class="agreement"
                @click="openAgreement"
                >分成协议</span
              >
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "caseCompany",
  data() {
    return {
      checked: false,
      company: [
        {
          name: "",
          image: require("@/assets/images/1.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/2.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/3.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/4.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/5.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/6.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/7.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/8.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/9.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/10.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/11.jpg"),
        },
        {
          name: "",
          image: require("@/assets/images/12.jpg"),
        },
      ],
    };
  },
  methods: {
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
  },
};
</script>

<style lang="less" scoped>
#guidepage {
  height: 100%;
  width: 100%;
  position: relative;

  .el-container {
    width: 100%;
    height: 100%;
    .el-header {
      height: 50px !important;
      .companyIcon {
        float: left;
        margin-top: 15px;
        width: 80px;
      }
    }

    .el-main {
      height: 100%;

      .slogan {
        height: 100%;
        text-align: center;
        position: relative;
        .title {
          font-size: 32px;
          text-align: left;
          color: #fff;
        }
        .companyList {
          margin-top: 10px;
          background-color: rgb(242, 242, 242);
          height: calc(100% - 210px);
          // border: 1px solid #000;
          border-radius: 5px;
          overflow: auto;
          text-align: center;
          // display: flex;
          // word-break: break-all;
          .image {
            display: inline-block;
            //   flex: 0.5;
            width: 150px;
            height: 102px;
            margin-right: 5px;
            img {
              width: 150px;
              height: 102px;
              // display: flex;
            }
          }
        }
        .agencyBtn {
          padding: 15px 20px;
          bottom: 90px;
          left: 0;
          position: absolute;
          width: 100%;
          font-size: 20px;
        }
        .agreementTip {
          position: absolute;
          bottom: 65px;
          left: calc(50% - 164px);
          font-size: 14px;
          text-align: center;
          .agreement {
            color: red;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>

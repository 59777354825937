<template>
  <!-- 产品页面 -->
  <div id="guidepage">
    <el-container>
      <el-header class="header"
        ><img
          src="@/assets/images/logo.png"
          alt="广州道可维斯技术有限公司"
          class="companyIcon"
      /></el-header>
      <el-main>
        <div class="productView">
          <P class="productTitle">全系列产品支持</P>
          <div class="allProduct">
            <div class="product" v-for="(item, index) in product" :key="index">
              <img :src="item.image" :alt="item.name" />
              <div class="productMsg">
                <p class="productTitle">
                  {{ item.name
                  }}<el-button
                    type="info"
                    class="questionBtn"
                    @click.native="openQuestion(item.name)"
                    >我要提问</el-button
                  >
                </p>
                <p>{{ item.msg }}</p>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "product",
  data() {
    return {
      checked: false,
      product: [
        {
          name: "e企验票",
          image: require("@/assets/images/pro1.jpg"),
          msg: "产品介绍这是个能够帮助用户XXXXXX",
        },
        {
          name: "e企拆表",
          image: require("@/assets/images/pro1.jpg"),
          msg: "产品介绍这是个能够帮助用户XXXXXX",
        },
        {
          name: "e企归档",
          image: require("@/assets/images/pro1.jpg"),
          msg: "产品介绍这是个能够帮助用户XXXXXX",
        },
        {
          name: "e企验票",
          image: require("@/assets/images/pro1.jpg"),
          msg: "产品介绍这是个能够帮助用户XXXXXX",
        },
        {
          name: "e企验票",
          image: require("@/assets/images/pro1.jpg"),
          msg: "产品介绍这是个能够帮助用户XXXXXX",
        },
        {
          name: "e企验票",
          image: require("@/assets/images/pro1.jpg"),
          msg: "产品介绍这是个能够帮助用户XXXXXX",
        },
      ],
    };
  },
  methods: {
    openQuestion(data) {
      console.log(1);
      this.$emit("question", data);
    },
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
  },
};
</script>

<style lang="less" scoped>
#guidepage {
  height: 100%;
  width: 100%;
  position: relative;

  .el-container {
    width: 100%;
    height: 100%;
    .el-header {
      height: 50px !important;
      .companyIcon {
        float: left;
        margin-top: 15px;
        width: 80px;
      }
    }

    .el-main {
      height: 100%;
      .productView {
        height: 100%;
        .productTitle {
          font-size: 32px;
          color: #ebeef5;
        }
        .allProduct {
          margin-top: 10px;
          border: 0px solid #909399;
          border-radius: 10px;
          height: calc(100% - 60px);
          padding: 20px 15px;
          overflow: auto;
          background-color: #fff;
          .product {
            // border: 1px solid #606266;
            //   border-radius: 10px;
            height: 130px;
            padding: 10px;
            margin-bottom: 10px;
            //   background-color: #c0c4cc;
            border-bottom: 1px solid #c0c4cc;
            img {
              border-radius: 10px;
              width: 110px;
              float: left;
            }

            .productMsg {
              font-size: 16px;
              line-height: 25px;
              padding: 0 0px 0 120px;
              .productTitle {
                color: #909399;
                font-size: 20px;
              }
              .questionBtn {
                float: right;
                height: 22px;
                padding: 4px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width:1024px) {
  .container .header-container{
    background: #2183E9;
  }
}
</style>

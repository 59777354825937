<template>
  <!-- 发票信息-列表展示 -->
  <div>
      <el-container>
        <el-main class="">
          <div class="title">
            <div class="titleBox">
              <p
                class="fnt22 fw700"
                :class="{
                  isRed: clueCount.Percent < 60,
                  isOrigan: clueCount.Percent >= 60 && clueCount.Percent < 90,
                  isGreen: clueCount.Percent >= 90,
                }"
              >
                {{ clueCount.Percent ? clueCount.Percent.toFixed(2) : 0 }}%
              </p>
              <p class="fontColor">跟进完成率</p>
            </div>
            <div class="titleBox">
              <p>
                <span class="isGreen fnt22 fw700">{{ clueCount.End }}</span>
              </p>
              <p class="fontColor">已跟进数</p>
            </div>
            <div class="titleBox">
              <p class="fnt22 fw700 isRed">
                {{ clueCount.NotEnd }}
              </p>
              <p class="fontColor">未跟进数</p>
            </div>
          </div>
          <!-- <hr /> -->
          <div class="detailClueData">
            <div>
              <p class="detail fnt16">
                <i class="el-icon-user"> </i>
                <span>姓名</span>
                <span class="des">{{ currentData.Name }}</span>
              </p>
              <p class="detail fnt16">
                <i class="el-icon-phone-outline"> </i>
                <span>电话</span>
                <span class="des">{{ currentData.PhoneNum }}</span>
              </p>
              <p class="detail fnt16">
                <i class="el-icon-office-building"> </i>
                <span>公司名称</span>
                <span class="des">{{ currentData.Company }}</span>
              </p>
              <p class="detail fnt16">
                <i class="el-icon-message"> </i>
                <span>邮箱</span>
                <span class="des">{{ currentData.Email }}</span>
              </p>
              <p class="detail fnt16">
                <i class="el-icon-postcard"> </i>
                <span>联系人类型</span>
                <span class="des">{{ currentData.PartnerType }}</span>
              </p>
              <p class="detail fnt16">
                <i class="el-icon-date"> </i>
                <span>登记时间</span>
                <span class="des">{{
                  currentData.CreatedTime
                    ? currentData.CreatedTime.replace("T", " ")
                    : ""
                }}</span>
              </p>
              <p class="detail fnt16" @click="openNeed">
                <i class="el-icon-document"></i>
                <span>需求</span>
                <span class="des">
                  <i class="el-icon-arrow-right"></i>
                </span>
              </p>
                <el-collapse accordion class="demandBox" v-model="activeNames" style="font-size: 16px;">
                  <el-collapse-item class="demand fnt16" name="1">
                    <template slot="title">
                      <i class="el-icon-document"></i>备注
                    </template>
                    <div>
                      {{ currentData.Remark }}
                      <i class="el-icon-edit" @click="openDialog"></i>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              <!-- 第二个抽屉 -->
              <el-drawer
                title="需求"
                :append-to-body="true"
                :visible.sync="innerDrawer"
              >
                <el-tabs type="border-card">
                  <el-tab-pane label="待回复">
                    <ul>
                      <li v-for="(item, index) in notReplied" :key="index">
                        <el-card class="box-card">
                          <div slot="header" class="clearfix">
                            <span>{{ item.Type }}</span>
                            <span>{{ item.CheckTime }}</span>
                          </div>
                          <div class="text item">
                            {{ item.Content }}
                          </div>
                        </el-card>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="已回复">
                    <ul>
                      <li v-for="(item, index) in Replied" :key="index">
                        <el-card class="box-card">
                          <div slot="header" class="clearfix">
                            <span>{{ item.Type }}</span>
                            <span>{{ item.CheckTime }}</span>
                          </div>
                          <div class="text item">
                            {{ item.Content }}
                          </div>
                        </el-card>
                      </li>
                    </ul>
                  </el-tab-pane>
                </el-tabs>
              </el-drawer>
            </div>
          </div>
          <!-- <hr /> -->
          <div class="block">
            <p class="fnt20 fw700 text">跟进日志</p>
            <el-empty
              :image-size="200"
              v-if="activities ? !activities.length : 0"
            ></el-empty>
            <el-timeline v-if="activities ? activities.length : 0">
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-main>

        <el-footer class="bottomBox">
          <el-button class="searchHistory" @click="qidai"
            >搜索跟进记录</el-button
          >
          <el-button
            class="editClue"
            type="primary"
            @click="dialogVisible = true"
            >填写跟进记录</el-button
          >
        </el-footer>
        <el-dialog
          title="填写跟进记录"
          :visible.sync="dialogVisible"
          width="80%"
          append-to-body
          class="dislog"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="请输入内容"
            v-model="textarea"
            maxlength="250"
            show-word-limit
          >
          </el-input>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForrow">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="请输入备注" :visible.sync="dialogFormVisible" width="85%">
      <el-form :model="form">
        <el-form-item>
          <el-input
            v-model="form.remark"
            autocomplete="off"
            type="textarea"
            maxlength="150"
            :autosize="{ minRows: 6, maxRows: 8 }"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRemark">确 定</el-button>
      </div>
    </el-dialog>
      </el-container>
  </div>
</template>
    
<script>
export default {
  name: "FileList",
  components: {
  },
  data() {
    return {
      textarea: "", //
      dialogVisible: false, //填写记录弹框
      activeNames: ["1"],
      clueCount: {},
      isEdit: false, //标题是否可被输入
      drawerTitle: "未命名线索主题",
      multipleSelection: [],
      drawer: false,
      innerDrawer: false,
      PageSize: 10,
      PageIndex: 1,
      Replied: [], //已回复的数组
      notReplied: [], //未回复的数组
      dialogFormVisible: false,
      form: {
        remark: "",
      },
      replydata: {
        fileList: [
          {
            name: "food.jpeg",
            url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
        ],
      }, //回复数据
      activities: [
        {
          content: "未跟进",
          timestamp: "2023-02-08 20:46 机器人提醒跟进",
          size: "large",
          type: "primary",
          icon: "el-icon-more",
        },
        {
          content: "XXXX",
          timestamp: "2023-02-08 20:46 自行跟进",
          color: "#0bbd87",
        },
        {
          content: "XXXX",
          timestamp: "2023-02-08 20:46 自行跟进",
          color: "#0bbd87",
        },
      ],
      currentData: {}, //当前点击的数据
    };
  },
  created() {
    this.getCode();
    this.getClueForrowData()
    // this.getAnswer()
  },
  methods: {
    //打开需求页面
    openNeed() {
      // let userInfo1 = {
      //   openid: "o-W8KjxozWkgsA3K1l8lYXk5iGcc",
      //   nickname: "",
      //   sex: 0,
      //   province: "",
      //   city: "",
      //   country: "",
      //   headimgurl:
      //     "https://thirdwx.qlogo.cn/mmopen/vi_32/HrbY1o4wvbcY2OhZEhH4BWp41GHuDuNKia1rNVJuBQH5ybIXVQnE2uj5Ef9Bok6A1WJWhkIia4vZPuic2lLYlqlJA/132",
      //   privilege: [],
      //   unionid: "oQOYU58OU6q631vyMfpdtWBWzGC8",
      // };
      // sessionStorage.setItem("userInfo", JSON.stringify(userInfo1));
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.$router.push({
        path: "/need",
        query: {
          userID: userInfo.openid,
        },
      });
    },
    
    //根据线索id获取跟进列表和跟进数
    getClueForrowData() {
      let param = {
        ClueId: this.$route.query.QAID,
      };
      this.$api.beyoudsky.GetClueRecode(param).then((res) => {
        if (res.data.errorCode == "1") {
          let data = JSON.parse(res.data.data);
          console.log(data);
          this.clueCount = data.Number;
          this.activities = [];
          data.List.forEach((item) => {
            if (item.Status == "已跟进") {
              this.activities.push({
                content: item.RecordMsg ? item.RecordMsg : "",
                timestamp:
                  item.CreatedTime.replace("T", " ") + " " + item.Status,
                size: "large",
                type: "primary",
                color: "#0bbd87",
              });
            } else if (!item.Status) {
              this.activities.push({
                content: "",
                timestamp: item.CreatedTime.replace("T", " ") + " " + "未跟进",
                size: "noraml",
                type: "danger",
                icon: "el-icon-warning-outline",
              });
            } else {
              console.log(item.Status);
              this.activities.push({
                content: item.RecordMsg ? item.RecordMsg : "",
                timestamp:
                  item.CreatedTime.replace("T", " ") + " " + item.Status,
                size: "noraml",
                type: "danger",
                icon: "el-icon-warning-outline",
              });
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: `获取跟进记录数据失败！请联系管理员，失败原因为：${res.data.errorText}`,
          });
        }
      });
    },
    //提交备注
    submitRemark() {
      let param = {
        ContractId: this.$route.query.QAID,
        Remark: this.form.remark,
        IsRead: "已读",
      };
      this.$api.beyoudsky.UpdateContractMsg(param).then((res) => {
        if (res.data.errorCode == "1") {
          this.getAnswer();
          this.dialogFormVisible = false;
        } else {
          this.$message({
            type: "warning",
            message: `提交失败！请联系管理员，失败原因为：${res.data.errorText}`,
          });
        }
      });
    },
    openDialog() {
      this.dialogFormVisible = true;
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest);
      return theRequest;
    },
    getCode() {
      // let data = {
      //   access_token:
      //     "65_e2f-h1clr6yFmGAEcvfNGhWKs3LXx5XRwVxXhjHNh467LJGKXGlM-PyIYu5eKeAf5Z-9BFsDuoLBMCrG85j2Yi5m4j6fYwU4p0k6RJUc6mI",
      //   expires_in: 7200,
      //   refresh_token:
      //     "65_67n4f8WLmnbugicbhMDqcl_Zbc3imnNN5sEYK9jF63NOkd1PBtOWq791CzGJecSHjxy94kNqk-e-xIUVdFc5r-s6YNU1uugOHTCQBoCDyWc",
      //   openid: "o-W8KjxozWkgsA3K1l8lYXk5iGcc",
      //   scope: "snsapi_base",
      // };
      // sessionStorage.setItem("userInfo", JSON.stringify(data));
      // console.log(JSON.parse(sessionStorage.getItem("userInfo")));
      // this.getAnswer();
      // 非静默授权，第一次有弹框
      this.code = "";
      if (this.page == "shopproductdetails") {
        var local =
          "http://docuvix.com/#/distributeContacts?QAID=" +
          this.$route.query.QAID;
      } else {
        var local =
          "http://docuvix.com/#/distributeContacts?QAID=" +
          this.$route.query.QAID;
      }
      this.appid = "wx27e60c547a5ab724"; //公众号里有自己查，不会找的查一下百度
      this.code = this.getUrlCode().code; // 截取code
      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&isOpenDrawer=true&response_type=code&scope=snsapi_userinfo#wechat_redirect";
      } else {
        // alert(this.code);
        let param = {
          Code: this.code,
        };
        this.$api.beyoudsky.GetWXScopeInfo(param).then((res) => {
          // alert(res)
          console.log(res);
          sessionStorage.setItem("userInfo", res.data.data);
          // this.$router.push({
          //   path: "/",
          //   query: {
          //     isOpenDrawer: true,
          //   },
          // });
          this.getAnswer();
        });
        // 你自己的业务逻辑
      }
    },
    getAnswer() {
      console.log(7);
      let param = {
        ContactId: this.$route.query.QAID,
      };
      this.$api.beyoudsky.GetContactByCid(param).then((res) => {
        if (res.data.errorCode == "1") {
          let data = JSON.parse(res.data.data);
          console.log(data);
          this.currentData = data;
          this.salePerson = data.DirectorName
        }
      });
    },
    requirementsTable() {
      this.innerDrawer = true;
      console.log(this.PageSize);
      console.log(this.PageIndex);
      let params = {
        WeChatID: this.currentData.WeChatID,
      };
      this.$api.beyoudsky
        .GetMsgHisList(params, this.PageSize, this.PageIndex)
        .then((res) => {
          let data = JSON.parse(res.data.data);
          let qalist = data.QAList;
          qalist.map((item) => {
            item.CheckTime = item.CheckTime.replace("T", " ");
            if (item.Status == "已回复") {
              this.Replied.unshift(item);
            } else {
              this.notReplied.unshift(item);
            }
          });
          console.log(this.Replied);
          console.log(this.notReplied);
        });
    },
    inputBlur() {
      let params = {
        ContractId: this.currentData.ID,
        Remark: this.currentData.Remark,
        IsRead: this.currentData.IsRead,
      };
      this.$api.beyoudsky.UpdateContractMsg(params).then((res) => {
        console.log(res.data);
      });
    },
    inputMessage() {
      let childMessage = this.$refs.messages;
      setTimeout(() => {
        childMessage.focus();
      }, 1);
    },
    //抽屉需求点击跳转
    requirementsDetails() {
      // this.closeDrawer()
      this.drawer = false;
      this.$emit("openRequirementsDetails");
    },
    //drawer关闭前的回调
    closeDrawer() {
      console.log("drawer关闭前的回调");
      if (this.currentData.ClueTitle != this.drawerTitle) {
        this.$emit("flush");
      }
    },
    // 获取当前的时间
    getNowTime() {
      let dt = new Date();
      var y = dt.getFullYear();
      var mt = (dt.getMonth() + 1).toString().padStart(2, "0");
      var day = dt.getDate().toString().padStart(2, "0");
      var h = dt.getHours().toString().padStart(2, "0");
      var m = dt.getMinutes().toString().padStart(2, "0");
      this.nowtime = y + "-" + mt + "-" + day + " " + h + ":" + m;
      console.log("此刻时间", this.nowtime);
    },
    //提交跟进记录
    submitForrow() {
      console.log(this.currentData);
      let param = {
        ClueId: this.currentData.ID,
        RecordMsg: this.textarea,
      };
      this.$api.beyoudsky.SaveClueRecord(param).then((res) => {
        if (res.data.errorCode == "1") {
          this.$message({
            type: "success",
            message: `提交成功！`,
          });
          this.getClueForrowData();
          this.textarea = "";
          let param1 = {
            ClueId: this.currentData.Id,
            Status: "跟进中",
          };
          this.$api.beyoudsky.UpdateContractMsg(param1).then((res) => {
            if (res.data.errorCode == "1") {
              // this.$message({
              //   type: "success",
              //   message: `修改成功！`,
              // });
            } else {
              this.$message({
                type: "warning",
                message: `修改线索状态失败！请联系管理员，失败原因为：${res.data.errorText}`,
              });
            }
          });
          this.dialogVisible = false;
        } else {
          this.$message({
            type: "warning",
            message: `提交失败！请联系管理员，失败原因为：${res.data.errorText}`,
          });
        }
      });
    },
    //保存线索标题
    disabledDrawerTitle() {
      let param = {
        ClueId: this.currentData.Id,
        ClueTitle: this.drawerTitle,
      };
      this.$api.beyoudsky.UpdateClueMsg(param).then((res) => {
        if (res.data.errorCode == "1") {
          this.$message({
            type: "success",
            message: `修改成功！`,
          });
          this.isEdit = false;
        } else {
          this.$message({
            type: "warning",
            message: `获取数据失败！请联系管理员，失败原因为：${res.data.errorText}`,
          });
        }
      });
    },
    qidai() {
      this.$message({
        type: "warning",
        message: "敬请期待！",
      });
    },
    //编辑标题
    editDrawerTitle() {
      this.isEdit = true;
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查看
    handleClick(item) {
      let routeData = this.$router.resolve({
        name: "read",
        query: {
          id: item.number,
        },
      });
      //必要操作，否则不会打开新页面
      window.open(routeData.href, "_blank");
    },
    //回复
    reply(item) {
      this.currentData = item;
      this.getClueForrowData();
      this.drawerTitle = item.ClueTitle ? item.ClueTitle : "未命名线索主题";
      console.log(item);
      this.drawer = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    //点击行
    rowClick(row) {
      console.log(111);
      this.currentData = row;
      console.log(row);
      this.getClueForrowData();
      this.drawerTitle = row.ClueTitle ? row.ClueTitle : "未命名线索主题";
      this.drawer = true;
    },
  },
};
</script>
<style lang="less" scoped>
  .block {
    border-top: 1px solid #ccc;
    padding-bottom: 60px;
    margin-left: 5px;
    .text {
      margin: 10px 0;
      font-weight: 700;
    }
    
  }
  .bottomBox {
    padding: 10px;
    height: 60px;
    width: 100%;
    // position: absolute;
    // bottom: 0;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .searchHistory {
      width: 34%;
    }
    .editClue {
      width: 70%;
    }
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .titleBox {
      padding: 10px;
      height: 80px;
      // border: 1px solid #000;
      border-radius: 4px;
      background-color: #f4f4f4;
      text-align: center;
      line-height: 30px;
    }
    .titleBox:nth-child(1),
    .titleBox:nth-child(3) {
      width: 30%;
    }
    .titleBox:nth-child(2) {
      width: 30%;
    }
    p {
      margin: 0;
    }
  }
  .el-main {
      height: 100%;
      overflow: scroll;
    }
    .detail {
      height: 65px;
      line-height: 65px;
      margin: 0;
      padding: 0 10px;
      color: #575d6c;
      i {
        margin-right: 10px;
      }
      .des {
        float: right;
      }
    }
    .detail:hover {
      background-color: #f4f4f4;
    }
    .detailClueData {
    margin-top: 10px;
    // text-align: center;
    width: 100%;
    // height: 600px;
    // line-height: 600px;
    border-bottom: 1px solid #ccc;
    .detail {
      height: 65px;
      line-height: 65px;
      margin: 0;
      padding: 0 10px;
      color: #575d6c;
      i {
        margin-right: 10px;
      }
      .des {
        float: right;
      }
    }
    .detail:hover {
      background-color: #f4f4f4;
    }
    .el-collapse {
      border: 0;
    }
    .demandBox {
      padding: 0 10px;
      .dedemand {
      }
      /deep/.el-collapse-item__header {
        height: 65px;
        line-height: normal;
        border: 0;
        font-size: 16px;
        // padding: 0 0 0 10px;
        color: #575d6c;
        i {
          margin-right: 10px;
        }
      }
      .el-collapse-item__header:hover {
        background-color: #f4f4f4 !important;
      }
      .el-collapse-item__content {
        padding-left: 35px;
        font-size: 14px;
      }
    }
  }
    //通用样式
.fnt22 {
  font-size: 22px;
}
.fnt20 {
  font-size: 20px;
}
.fnt18 {
  font-size: 18px;
}
.fnt16 {
  font-size: 16px;
}
.fw700 {
  font-weight: 700;
}
.nicheng {
  font-weight: 700;
}
.isRed {
  color: #f56c6c;
}
.isOrigan {
  color: #f29e22;
}
.isGreen {
  color: #67c23a;
}
.fontColor {
  color: #575d6c;
}
</style>
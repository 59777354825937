<template>
  <!-- 下载-->
  <div id="register">
    <div class="top-background" style="height: 1rem;"></div>
    <div class="" :style="{
      'text-align': 'center',
      'font-size': '22px',
      'font-weight': '700',
      'margin-top': '20px',
      'margin-bottom': '10px',
    }">
      <h1>{{ data.name }}</h1>
    </div>
    <div class="time">
      <span style="margin-top: 6px;">{{ data.creatTime }}</span>
      <!-- <span>下载：{{ data.downloadCount }}</span> -->
      <a href="javascript:;" class="download_btn date-download-bnt" @click="openRegister('立即获取')">立即获取</a>
    </div>
    <div style="display: none">
      <a href="#" id="download" style="display: none">下载链接</a>
    </div>

    <div class="banner">
      <div class="text-1">
        <p style="text-align: center" v-for="(image, index) in data.images" :key="index">
          <img :src="image" style="max-width: 750px" :style="{ width: '6.7rem' }" />
        </p>
        <!-- <p class="down">获取详细方案，请点击右上角立即下载</p> -->
      </div>
    </div>
    <!-- <div class="download-bottom">
      <div class="download_btn date-download-bnt1">
        <p style="font-size: 0.28rem">下载</p>
        <p class="download_file">{{ data.name }}</p>
      </div>
    </div> -->


    <!-- <div class="footer-title" style="padding-bottom: 1rem">
      <div class="footer-title-row" v-if="currentProductNum != 0">
        <p style="font-size: 0.28rem; font-weight: bold">上一篇：</p>
        <a href="/download/56712">
          {{ currentProduct[currentProductNum - 1].name }}</a
        >
      </div>
      <div class="footer-title-row">
        <p
          style="font-size: 0.28rem; font-weight: bold"
          v-if="currentProduct[currentProductNum + 1]"
        >
          下一篇：
        </p>
        <a href="/download/56760">
          {{
            currentProduct[currentProductNum + 1]
              ? currentProduct[currentProductNum + 1].name
              : ""
          }}</a
        >
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      // isActive:true,
      userMsg: {
        type: "请选择",
      },
      isInput: 0,
      userInfo: {
        type: "1",
      },
      rules: {
        type: [
          { required: true, message: "请选择伙伴类型", trigger: "change" },
        ],
      },
      data: {}, //显示的产品信息
      currentProductNum: 0, //当前是第几个文件
      currentProduct: [], //当前产品的全部数据
      productMsg: [
        {
          // productName: "道可维斯·e企拆表",
          productName: "e企拆表",
          data: [
            {
              name: "e企拆表·产品介绍文档",
              images: [
                require("@/assets/mobile/v0.1/images/product/e_chaitu/01/chaitu_01.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_chaitu/01/chaitu_02.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_chaitu/01/chaitu_03.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_chaitu/01/chaitu_04.jpg"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
            {
              name: "e企拆表·用户故事",
              images: [
                // require("@/assets/mobile/v0.1/images/product/e_chaitu/02/chaitu_00.png"),
                require("@/assets/mobile/v0.1/images/product/e_chaitu/02/chaitu_01.png"),
                require("@/assets/mobile/v0.1/images/product/e_chaitu/02/chaitu_02.png"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
          ],
        },
        {
          productName: "道可维斯·e企验票",
          // productName: "e企验票",
          data: [
            {
              name: "e企验票·产品介绍文档",
              images: [
                require("@/assets/mobile/v0.1/images/product/e_yanpiao/01/yanpiao_00.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_yanpiao/01/yanpiao_01.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_yanpiao/01/yanpiao_02.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_yanpiao/01/yanpiao_03.jpg"),
                require("@/assets/mobile/v0.1/images/product/e_yanpiao/01/yanpiao_04.jpg"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
          ],
        },
        {
          // productName: "道可维斯·e企档案",
          productName: "e企档案",
          data: [
            {
              name: "e企档案·产品介绍文档",
              images: [
                require("@/assets/mobile/v0.1/images/product/e_dangan/01/dangan_01.png"),
                require("@/assets/mobile/v0.1/images/product/e_dangan/01/dangan_02.png"),
                require("@/assets/mobile/v0.1/images/product/e_dangan/01/dangan_03.png"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
          ],
        },
        {
          // productName: "道可维斯·e企签收",
          productName: "e企签收",
          data: [
            {
              name: "e企签收·产品介绍文档",
              images: [
                require("@/assets/mobile/v0.1/images/product/e_qianshou/01/qianshou_01.png"),
                require("@/assets/mobile/v0.1/images/product/e_qianshou/01/qianshou_02.png"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
          ],
        },
        {
          // productName: "道可维斯·电子影像",
          productName: "电子影像",
          data: [
            {
              name: "业财税档一体化方案",
              images: [
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/01/yingxiang_01.png"),
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/01/yingxiang_02.png"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
            {
              name: "电子影像中台解决方案",
              images: [
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/02/yingxiang_01.png"),
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/02/yingxiang_02.png"),
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/02/yingxiang_03.png"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            },
            {
              name: "财务共享影像操作手册",
              images: [
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/03/yingxiang_01.png"),
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/03/yingxiang_02.png"),
                require("@/assets/mobile/v0.1/images/product/dianziyingxiang/03/yingxiang_03.png"),
              ],
              downloadCount: 77,
              creatTime: "2022/08/03",
            }
          ],
        },
      ],
    };
  },
  created() {
    console.log('1', JSON.parse(this.$route.query.product));
    let data = JSON.parse(this.$route.query.product);
    let productName = this.$route.query.productName;
    console.log('1', productName)
    let product = this.productMsg.filter((item, index) => {
      return item.productName === productName;
    });
    console.log(product)
    this.currentProduct = product[0].data;
    let currentProductMsg = product[0].data.filter((item, index) => {
      if (data.title == item.name) {
        this.currentProductNum = index;
        return data.title == item.name;
      }
    });
    this.data = currentProductMsg[0];
  },
  methods: {
    // scrollChange() {
    //   // console.log("滚动高度", window.pageYOffset);

    //   // console.log("距离顶部高度", this.$refs.menu.getBoundingClientRect().top);
    //   if (window.pageYOffset < 100) {
    //     this.isActive = false;
    //   } else {
    //     this.isActive = true;
    //   }
    // },
    openRegister(type) {
      let name = this.$route.query.productName + type;
      this.$utils.AddAction(name)
      this.$router.push({ path: "/register", query: { type: "下载", DemandType: "需求" } });
    },
    selectType(val) {
      this.userMsg.type = val;
      this.isInput = 0;
    },
    submitForm() {
      this.$refs["userInfo"].validate((valid) => {
        // console.log(valid);
        if (valid) {
          // alert("注册成功");
          this.$router.push({
            path: "/personalinfo",
            query: { type: this.userInfo.type },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./assets/mobile/v0.1/css/new-common.css";
@import "./assets/mobile/v0.1/css/iconfont.css";
@import "./assets/mobile/v0.1/css/font.css";
@import "./assets/mobile/v0.1/css/animate.min.css";
@import "./assets/mobile/v0.1/css/swiper.min.css";
@import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
@import "./assets/mobile/v02/css/mo_download_inquiry.css";
@import "./assets/mobile/v0.1/css/header.css";
@import "./assets/mobile/v0.1/css/info-details.css";
@import "./assets/mobile/v0.1/css/footer.css";

#register {
  // padding-top: 0.84rem;

  .title>h1 {
    width: 6.7rem;
    width: 6rem;
    margin: 0 auto;
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 0.5rem;
    text-align: left;
    margin-top: 0.5rem;
  }

  .time {
    width: 750px;
    width: 6.7rem;
    padding-top: 0.1rem;
    margin: 0 auto;
    margin-bottom: 16px;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    justify-content: space-between;

    .date-download-bnt {
      font-size: 0.213333rem;
      width: 1.5rem;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-bottom: 0.1rem;
      text-align: center;
      background-image: linear-gradient(90deg, #1359f7, #0daeff);
      color: #fff;
    }
  }

  .date-download-bnt1 {
    padding-left: 0.4rem;
    padding-top: 0.4rem;

    .download_file {
      padding-top: 0.1rem;
      font-weight: 600;
    }
  }

  .footer-title-row {
    width: 6.7rem;
    // margin: 0 auto;
    margin-left: 0.4rem;
    margin-top: 0.2rem;
  }
}

//   #register {
//     height: 100%;
//     padding: 0 20px 20px 20px;
//     width: 100%;
//     // background-image: linear-gradient(#409eff, #8cc5ff, #c6e2ff);
//     position: relative;
//     box-sizing: border-box;
//     background-image: url("@/assets/images/background.png");
//     background-repeat: no-repeat;
//     background-size: 100% 100%;

//     .title {
//       height: 25%;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       color: #fff;
//       box-sizing: border-box;

//       .welcome-name {
//         font-size: 32px;
//         font-weight: 500;
//       }

//       .welcome-project {
//         font-size: 20px;
//         margin-top: 20px;
//       }

//       .welcome-join {
//         margin-top: 10px;
//         font-size: 14px;
//       }
//     }

//     .content {
//       display: flex;
//       flex-direction: column;
//       height: calc(75% - 15px);
//       width: 100%;
//       box-sizing: border-box;

//       .logo {
//         display: flex;
//         flex: 1;
//         align-items: center;
//         justify-content: center;
//         // height: 31px;
//         text-align: center;
//         // margin-top: 20px;

//         img {
//           // width: 200px;
//           height: 36px;
//         }

//         #line {
//           display: inline-block;
//           height: 40px;
//           width: 1px;
//           background-color: #c0c4cc;
//           margin: 0 15px;
//         }
//       }

//       .main-content {
//         display: flex;
//         align-items: center;
//         background-color: #fff;
//         border-radius: 20px;
//         padding: 25px 20px;
//         box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//       }

//       /deep/.el-form {
//         width: 100%;

//         .el-form-item {
//           width: 100%;
//           margin-bottom: 0px;

//           .el-form-item__label {
//             color: #606266;
//             font-size: 20px;
//           }

//           .el-form-item__content {
//             // margin-left: 0 !important;
//             width: 100%;
//             color: #fff;
//           }

//           .el-radio__label {
//             font-size: 20px !important;
//           }
//         }
//       }

//       .el-radio-group {
//         width: 100%;
//       }
//       /deep/.el-radio {
//         width: 100%;
//         margin-left: 0;
//         // margin-top: 10px;
//         margin-bottom: 24px;
//         color: #909399;
//         font-size: 20px;

//         &:last-child {
//           margin-bottom: 0;
//         }
//       }

//       .el-radio.is-bordered {
//         height: 60px;
//         padding-top: 20px;
//         // border: 1px solid #c6e2ff;
//       }

//       #submit-btn {
//         display: flex;
//         align-items: center;
//         // height: 20%;
//         width: 100%;
//         margin-top: 40px;

//         .el-button {
//           width: 100%;
//           border-radius: 26px;
//           padding: 16px 20px;
//           // box-shadow: 0 4px 12px 0 rgb(64 158 255 / 0.5);
//           color: #fff;
//           background-color: #409eff;
//           font-weight: 500;
//           font-size: 20px;
//         }
//       }
//     }

//     .footer {
//       margin: 0 auto;
//       height: 15px;
//       // transform: translate(50%, 0);
//       // margin-top: 20px;
//       text-align: center;
//       font-size: 14px;
//       color: #909399;
//     }
//   }
.isBlock {
  display: block;
  transition: all 0.5s;
}

@media screen and (min-width:1024px) {
  .top-background {
    height: 1.4rem !important;
    // background: #2183E9!important;
  }

  .time {
    width: 60% !important;
  }

  .banner {
    .down {
      display: none;
    }

    .text-1 {
      padding: 0 8%;

      img {
        width: 100% !important;
      }
    }

  }

  .download-bottom {
    .download_btn {
      background: none;
      border: 1px solid #2183E9;
      border-radius: 4px;
      width: 400px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 14px;
      // vertical-align: middle;
    }

    .download_btn:hover {
      cursor: pointer;
    }
  }

  .footer-title {
    padding: 0 8% 1rem;
  }
}

// .download_btn{
//   border-radius: 4px;
//   border: 1px solid #2183E9;
//   font-size: 14px;
//   width: 1rem;
// }
// .isBlock {
//   display: block;
// }
</style>

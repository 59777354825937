<template>
  <div id="aboutme">
    <div class="topImg">
      <img
        class="banner_phone"
        src="../../assets/images/aboutmepc.png"
        alt="关于我们"
      />
    </div>
    <div class="main">
      <div class="comp">
        <h1>关于道可维斯</h1>
        <div class="info editor-content" v-html="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutme1",
  components: {},
  data() {
    return {
      content: "",
      companyInfo: {
        p1: "广州道可维斯信息技术有限公司（简称“道可维斯”或“docuvix”）成立于2009年10月26日，致力于打造中国最大最专业的企业内容管理（ECM）解决方案提供商。",
        p2: "13年来，道可维斯一直致力于为企业搭建离散文档内容整合智能高速公路”，聚焦企业离散文档内容数智化收发存管，帮助企业构建“三多四跨”的文档数智化协作能力，让业财融合更便捷。",
        p3: "道可维斯的核心技术围绕着企业信息生命周期（获取、处理、分析、存储、共享、访问）的核心理念，结合混合云、人工智能等高新技术，为具有远见企业用户提供包括咨询建议、技术方案、产品选型、项目实施、二次开发、系统交付、长期服务等完善的ECM解决方案服务。",
        p4: " 道可维斯一直向大陆地区的企业用户提供企业内容管理解决方案。不论企业的信息化完善程度如何，我们都可以运用企业内容管理解决方案为客户提供不同起点及切实可行的系统，以达成企业在商业内容管理上的目标。 ",
        p5: "每一天，全国超过200家企业集团， 1023座大中小城市，近90000个用户在三多四跨的环境下使用道可维斯产品与服务，确保超过630万协作文档不再散错漏慢。",
        p6: "道可维斯，用数智文档连接你我他！",
      },
      companyCulture: [
        {
          title: "使命",
          info: "用ECM提升企业经营效益",
        },
        {
          title: "愿景",
          info: '三个“持续专注”，成为“三面公司"',
        },
        {
          title: "业务定位",
          info: "聚焦企业内容数智化转型 提升运营效能实现降本增效",
        },
        {
          title: "价值观",
          info: "好学、渐进、可信 协作、质量、快捷",
        },
      ],
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      let param = {
        Key: "html"
      }
      this.$api.DrawingIdentification.GetViewMsg(param).then((res) => {
        // let data = JSON.parse(JSON.parse(res.data).errorText);
        let data = JSON.parse(res.data).errorText;
        console.log(data);
        this.content = data
      });
    },
  },
};
</script>

<style lang="less" scoped>
// @import "./assets/mobile/v0.1/css/new-common.css";
// @import "./assets/mobile/v0.1/css/iconfont.css";
// @import "./assets/mobile/v0.1/css/animate.min.css";
// @import "./assets/mobile/v0.1/css/swiper.min.css";
// @import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
// @import "./assets/mobile/v0.1/css/mo_xkdetail.css";
// @import "./assets/mobile/v0.1/css/header.css";
// @import "./assets/mobile/v0.1/css/footer.css";
// @import "./assets/mobile/v0.1/css/mo_score.css";

#aboutme {
  position: relative;
}

.topImg {
  position: absolute;
  width: 100%;
  height: 908px;
  // background-color: gray;
  z-index: -99;

  img {
    width: 100%;
    // height: 908px;
    height: 500px;
  }
}

// .main {
// width: 1490px;
// height: 732px;
// background-color: tomato;
// width: 100%;
// height: auto;
// height: 946px;
// position: relative;
// display: flex;
// justify-content: center;
// }
.main {
  width: 100%;
  // width: 1200px;
  // margin: 0 auto;
}

.comp {
  margin: 0 auto;
  margin-top: 100px;
  margin-top: 10%;
  // position: absolute;
  // width: 1598px;
  // width: 80%;
  width: 1200px;
  height: auto;
  // height: 600px;
  // background-color: gray;

  h1 {
    // width: 536px;
    // height: 89px;
    // padding-left: 50px;
    // font-size: 60px;
    font-size: 38px;
    // font-family: Microsoft YaHei;
    // font-weight: bold;
    color: #ffffff;
    line-height: 150px;
  }

  h1:nth-of-type(n + 2) {
    color: #000;
    margin-top: 30px;
  }

  .info {
    padding: 30px;
    // padding-top: 50px;
    // width: 1598px;
    width: 100%;
    width: 1200px;
    // height: 1088px;
    background: #ffffff;
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.4);
    border-radius: 13px;

    p {
      margin: 40px;
      // font-size: 26px;
      font-size: 16px;
      // font-weight: bold;
      color: #333333;
      line-height: 54px;
      line-height: 32px;

      .font-bold {
        font-weight: bold;
      }

      .font-blue {
        color: #1e8cd2;
        font-weight: bold;
      }
    }
  }

  .culture {
    width: 100%;
    width: 1200px;
    margin: 0 auto 20px;
    // height: 1088px;
    // height: 1088px;
    // background-color: aqua;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .culture-item {
      position: relative;
      width: 782px;
      width: 49%;
      // height: 464px;
      height: 340px;
      // background: #000000;
      margin: 10px 0;
      // opacity: 0.58;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      text-align: center;

      .aboutmecultureimg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -99;
      }

      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background: #000000;
        // opacity: 0.58;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 13px;
        z-index: -99;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
      }

      h2 {
        width: 100%;
        font-size: 32px;
        color: #ffffff;
        line-height: 32px;
        margin-bottom: 30px;
      }

      p {
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
}
</style>

<template>
  <!-- 产品详情页 -->
  <div id="guidepage1">
    <!--头部-->
    <!--banner-->
    <div class="product_xc_banner">
      <img class="banner_phone" :src="productE.backgroundImage" alt="banner" />
      <div class="text">
        <div class="w1440">
          <!-- <div class="fnt_42">{{ productE.name.replace('·','') }}</div> -->
          <div class="fnt_42">{{ productE.name }}</div>
          <div class="banner-text fnt_18">
            <!-- {{ productE.description }} -->
            {{ productE.description.split(',')[0] }}<br />
            {{ productE.description.split(',')[1] }}
            <br />
          </div>
          <div class="product_xc_banner_link">
            <a href="javascript:;" @click="openRegister('免费体验')">免费体验</a>

            <a href="javascript:;" @click="OpenVideo('介绍视频')">介绍视频</a>
            <!-- <a href="#" @click="openRegister()">申请试用</a> -->

            <a href="javascript:;" @click="openRegister('申请试用')" style="margin-top: 6px">申请试用</a>

          </div>
        </div>
      </div>
    </div>
    <!--banner end-->

    <!-- 概述 -->
    <div class="duibiao">
      <div class="w1440">
        <div class="title">
          <h2 class="fnt_32">{{ productE.summary }}</h2>
          <div class="fnt_14 subtit" style="margin-bottom: 25px">
            &nbsp;{{ productE.summaryTxt }}
            <br />
          </div>
        </div>
        <slider class="EBC-intro-list" ref="slider" :options="options" @slide="slide" @tap="onTap" @init="onInit">
          <slideritem class="clearfix swiper-wrapper"
            :style="{ 'white-space': 'normal', 'text-align': 'left', 'justify-content': 'space-between', 'flex-wrap': 'wrap', 'align-items': 'start' }"
            v-for="(item, index) in productE.peculiarity" :key="index">
            <div class="swiper-slide" v-for="(ele, i) in item.peculiarityItem" :key="i">
              <div class="icon">
                <img :src="ele.src" :alt="ele.name" />
              </div>
              <div class="txt">
                <h3 class="fnt_20">
                  <b>{{ ele.name }}</b>
                </h3>
                <p>
                  {{ ele.desc }}
                </p>
              </div>
            </div>
          </slideritem>
          <!-- v-if="offsetWhdth>1024" -->
          <!-- <slideritem
            v-else
            class="clearfix swiper-wrapper"
            :style="{ 'white-space': 'normal', 'text-align': 'left','justify-content': 'start','flex-wrap': 'nowrap' }"
            v-for="(item, index) in productE.peculiarity"
            :key="index+1"
          >
            <div
              class="swiper-slide"
              v-for="(ele, i) in item.peculiarityItem"
              :key="i"
            >
              <div class="icon">
                <img :src="ele.src" :alt="ele.name" />
              </div>
              <div class="txt">
                <h3 class="fnt_20">
                  <b>{{ ele.name }}</b>
                </h3>
                <p>
                  {{ ele.desc }}
                </p>
              </div>
            </div>
          </slideritem> -->
          <div slot="loading">loading...</div>
        </slider>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="product_detail_experience" style="padding-bottom: 0.7rem">
      <div class="top_title">
        <div class="big_title fnt_32">{{ productE.functionTitle }}</div>
      </div>
      <div class="product_list">
        <ul class="product_detail_list">
          <li v-for="(item, index) in productE.function" :key="index">
            <div class="w1440 clearfix">
              <!-- <div class="left">
                <div class="img">
                  <img src :alt="item.title" />
                </div>
              </div> -->
              <div class="right">
                <div class="title fnt_30">
                  {{ item.title }}
                </div>
                <div class="des fnt_16">
                  {{ item.desc }}
                </div>
                <div class="txt fnt_16 clearfix">
                  <p v-for="(ele, i) in item.details" :key="i">
                    {{ ele.name }}
                  </p>
                </div>
                <div class="product_detail_link"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 获取产品详情 -->
    <div class="huoqu"
      style="background: url('https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/04175401lvch.jpg') no-repeat center; background-size: cover; ">
      <div class="w1440" style="padding: 0.2rem 0">
        <div class="fnt_36">{{ productE.demoDesc }}</div>
        <a class="huoqu_more" href="javascript:;" @click="openRegister('预约演示')">预约演示</a>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="anli">
      <slider class="swiper-anli" ref="slider" :options="productE.case.length > 1 ? optionsTrue : options" @slide="slide"
        @tap="onTap" @init="onInit">
        <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }"
          v-for="(itemCase, index) in productE.case" :key="index">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img class="shadow" src="@/assets/default/v0.1/images/caiwu/shadow.png" alt="数智赋能，建设世界一流司库管理体系" />
              <img class="sw_bg" :src="itemCase.src" :alt="itemCase.title" />
              <div class="anli_txt">
                <div>
                  <img :src="itemCase.logo" :alt="itemCase.title" :style="{ height: '40px', width: 'auto' }" />
                  <div class="fnt_36 anli_tit">{{ itemCase.title }}</div>
                  <div class="fnt_20">
                    {{ itemCase.desc }}
                  </div>
                  <a href="javascript:;" class="anli_more" @click="openCaseDetail(itemCase.companyName, '案例详情')">
                    案例详情
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slideritem>
      </slider>
    </div>

    <!-- 服务 -->
    <div class="starting">
      <!-- <div class="w1440">
        <div
          class="fnt_32 starting_tit"
          v-for="(item, index) in productE.uesrPortrait"
          :key="index"
          :style="{ 'margin-bottom': '30px' }"
        >
          {{ item.title }}

          <div
            class="fnt_16 starting_sub"
            :style="{ 'margin-top': '20px', 'font-weight': '700' }"
          >
            {{ item.desc }}
          </div>
          <img :src="item.src" alt="" />
        </div>
      </div> -->
    </div>
    <!-- 资源中心 -->
    <div class="resources">
      <div class="w1440">
        <h3 class="fnt_32">了解{{ productE.name }}的更多信息</h3>
        <div class="fnt_18 sort">
          <a href="javascript:;"> 资料获取 </a>
        </div>
        <div class="choose">
          <div class="sw2 resource_center choose_act chose_act">
            <div class="swiper-container">
              <!-- 资料下载 -->
              <slider class="swiper-wrapper" ref="slider" :options="options" @slide="slide" @tap="onTap" @init="onInit">
                <slideritem :style="{
                  width: '220px',
                  'margin-right': '15px',
                  'background-color': 'white',
                }" v-for="(materialItem, index) in productE.material" :key="index">
                  <a :style="{ width: '220px', 'margin-right': '10px' }" class="swiper-slide swiper-slide-active"
                    href="javascript:;" @click="openDownload(materialItem, '获取材料')">
                    <div class="img">
                      <div :style="{
                        'background-image': `url(${materialItem.src})`,
                      }"></div>
                    </div>
                    <div class="resources_txt" :style="{ 'text-align': 'left' }">
                      <div class="fnt_16 resources_tit">
                        {{ materialItem.title }}
                      </div>
                      <!-- <div
                        class="resources_des"
                        :style="{
                          'font-size': '14px',
                          'white-space': 'normal',
                        }"
                      >
                      告别繁琐 拥有便捷一款提升拆图效率的工具，让拆图效率提高200%
                      </div> -->
                      <div class="resources_more resources_arrow" :style="{ 'font-size': '14px' }">
                        获取材料
                      </div>
                    </div>
                  </a>
                </slideritem>
              </slider>

              <div class="swiper-pagination"></div>
            </div>
            <div class="sw_prev"></div>
            <div class="sw_next"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 常见问题折叠面板 -->
    <!-- <div class="normalQuestion">

    </div> -->
    <div class="answer">
      <div class="w1440">
        <h3 class="fnt_32">常见问题</h3>
        <el-collapse accordion>
          <!-- <el-collapse-item
            :title="quesItem.ques"
            v-for="(quesItem, index) in productE.noramlQuestion"
            :key="index"
          >
            <div v-for="(answerItem, i) in quesItem.answer" :key="i">
              {{ answerItem }}
            </div>
          </el-collapse-item> -->

          <el-collapse-item :name="index" v-for="(quesItem, index) in productE.noramlQuestion" :key="index">
            <span class="collapse-title" slot="title">{{ quesItem.ques }}</span>
            <div v-for="(answerItem, i) in quesItem.answer" :key="i">
              {{ answerItem }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <!-- 
    <div class="vio">
      <div class="video">
        <svg
          t="1655108403808"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2122"
          width="30"
          height="30"
        >
          <path
            d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m0-572.330667L300.629333 240.213333a42.538667 42.538667 0 0 0-60.16 0.213334 42.410667 42.410667 0 0 0-0.213333 60.16L451.669333 512 240.213333 723.370667a42.538667 42.538667 0 0 0 0.213334 60.16 42.410667 42.410667 0 0 0 60.16 0.213333L512 572.330667l211.370667 211.413333a42.538667 42.538667 0 0 0 60.16-0.213333 42.410667 42.410667 0 0 0 0.213333-60.16L572.330667 512l211.413333-211.370667a42.538667 42.538667 0 0 0-0.213333-60.16 42.410667 42.410667 0 0 0-60.16-0.213333L512 451.669333z"
            fill="#fff"
            p-id="2123"
          ></path>
        </svg>
        <video id="video1" controls>
          <source type="video/mp4" />
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div> -->
    <!--视频弹窗-->
    <div class="vio" :class="{ vio_flx: isOpenVideo }" @click="isOpenVideo = false">
      <div class="video">
        <i class="el-icon-circle-close closeVideo" @click="isOpenVideo = false"></i>
        <!-- <svg
          t="1655108403808"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2122"
          width="30"
          height="30"
        >
          <path
            d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m0-572.330667L300.629333 240.213333a42.538667 42.538667 0 0 0-60.16 0.213334 42.410667 42.410667 0 0 0-0.213333 60.16L451.669333 512 240.213333 723.370667a42.538667 42.538667 0 0 0 0.213334 60.16 42.410667 42.410667 0 0 0 60.16 0.213333L512 572.330667l211.370667 211.413333a42.538667 42.538667 0 0 0 60.16-0.213333 42.410667 42.410667 0 0 0 0.213333-60.16L572.330667 512l211.413333-211.370667a42.538667 42.538667 0 0 0-0.213333-60.16 42.410667 42.410667 0 0 0-60.16-0.213333L512 451.669333z"
            fill="#fff"
            p-id="2123"
          ></path>
        </svg> -->
        <video id="video1" controls ref="video1">
          <source type="video/mp4" />
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div>

    <div id="videoPopup1">
      <div class="video-box">
        <video src="" controls autoplay disablepictureinpicture="" controlslist="nodownload " oncontextmenu="return false"
          muted="">
          This browser does not support HTML5 video
        </video>
        <div class="close">
          <span class="iconfont">&#xe61a;</span>
        </div>
      </div>
    </div>

    <!-- 评分 -->
    <!-- <div class="product_xc_pingfen">
      <div class="w1440 clearfix">
        <div class="mr">
          <div class="product_pingfen_title">该页面是否满足您的需求？</div>
          <div class="product_pingfen_zan">
            <img src="@/assets/default/v0.1/images/product/zan.png" />
          </div>
          <div class="product_pingfen_cai">
            <img src="@/assets/default/v0.1/images/product/cai.png" />
          </div>
        </div>
        <div class="dianzan">
          <div class="title">感谢！</div>
          <div class="des">您的认可和好评，将激励我们更好地提升网站体验</div>
        </div>
        <div class="diancai">
          <div class="title">您认为我们还存在哪些不足和需要改进之处？</div>
          <div class="diancai_choose">
            <div class="sub_title">请从以下选项中选择（可多选）：</div>
            <form onsubmit="return false">
              <div class="checkbox_list">
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen1"
                    value="内容不够清晰，不方便寻找或了解产品价值"
                  />
                  <label for="pingfen1"
                    >内容不够清晰，不方便寻找或了解产品价值</label
                  >
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen2"
                    value="行业方案陈旧，未契合真实业务场景的需要"
                  />
                  <label for="pingfen2"
                    >行业方案陈旧，未契合真实业务场景的需要</label
                  >
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen3"
                    value="产品体验（体验/试用/购买）不顺畅"
                  />
                  <label for="pingfen3">产品体验（体验/试用/购买）不顺畅</label>
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen4"
                    value="不能快捷找到服务和支持的入口"
                  />
                  <label for="pingfen4">不能快捷找到服务和支持的入口</label>
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen5"
                    value="页面设计混乱，操作方式不符合日常习惯"
                  />
                  <label for="pingfen5"
                    >页面设计混乱，操作方式不符合日常习惯</label
                  >
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen6"
                    value="存在技术问题（链接失效/显示问题/页面错误等）"
                  />
                  <label for="pingfen6"
                    >存在技术问题（链接失效/显示问题/页面错误等）</label
                  >
                </div>
                <div class="checkbox_item">
                  <input type="checkbox" id="pingfen7" value="其他" />
                  <label for="pingfen7">其他</label>
                </div>
              </div>
              <div class="input_qt">
                <input type="text" name="" id="" value="" />
                <span></span>
              </div>
              <span class="tixing"></span>
              <button>提交反馈</button>
            </form>
          </div>
        </div>
        <div class="after_diancai">
          <div class="title">感谢！</div>
          <div class="des">您的反馈和建议，将激励我们更好的提升网站体验</div>
        </div>
      </div>
    </div> -->

    <!-- 移动端图片放大 -->
    <div id="outerdiv" style="
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        z-index: 2;
        width: 100%;
        height: 100%;
        display: none;
      ">
      <div id="innerdiv" style="position: absolute">
        <img id="bigimg" style="width: 100%" src="" />
      </div>
    </div>
    <div class="mobil_footer" style="text-align: left">
      <div class="footer-right">
        <!-- <div class="footer-right-link-box">
          <div class="footer-link-title-box">
            <p class="footer-right-link-title">为什么选择金蝶</p>
          </div>
          <ul class="footer-link-detailed">
            <a
              href="https://www.kingdee.com/company"
              class="footer-right-link-list"
            >
              了解金蝶</a
            >
            <a
              href="https://www.kingdee.com/company/honor"
              class="footer-right-link-list"
            >
              金蝶荣誉</a
            >
            <a
              href="https://investor.kingdee.com/"
              class="footer-right-link-list"
              rel="nofollow"
            >
              投资者关系</a
            >
            <a
              href="https://www.kingdee.com/success-stories/"
              class="footer-right-link-list"
            >
              客户案例</a
            >
          </ul>
        </div>
        <div class="footer-right-link-box">
          <div class="footer-link-title-box">
            <p class="footer-right-link-title">产品与服务</p>
          </div>
          <ul class="footer-link-detailed">
            <a
              href="https://www.kingdee.com/products/financial_cloud.html"
              class="footer-right-link-list"
            >
              财务云</a
            >
            <a
              href="https://www.kingdee.com/products/taxcloud.html"
              class="footer-right-link-list"
            >
              税务云</a
            >
            <a
              href="https://www.kingdee.com/products/hcm.html"
              class="footer-right-link-list"
            >
              人力云</a
            >
            <a
              href="https://www.kingdee.com/products/cosmic_platform.html"
              class="footer-right-link-list"
            >
              可组装企业级PaaS</a
            >
            <a
              href="https://www.kingdee.com/products/cosmic.html"
              class="footer-right-link-list"
            >
              大型企业EBC</a
            >
          </ul>
        </div>
        <div class="footer-right-link-box">
          <div class="footer-link-title-box">
            <p class="footer-right-link-title">解决方案</p>
          </div>
          <ul class="footer-link-detailed">
            <a
              href="https://www.kingdee.com/solutions/manufacture.html"
              class="footer-right-link-list"
              >制造业</a
            >
            <a
              href="https://www.kingdee.com/solutions/service.html"
              class="footer-right-link-list"
              >服务业</a
            >
            <a
              href="https://www.kingdee.com/solutions/traffic_logistics.html"
              class="footer-right-link-list"
              >电子</a
            >
            <a
              href="https://www.kingdee.com/solutions/real_estate.html"
              class="footer-right-link-list"
              >房地产</a
            >
            <a
              href="https://www.kingdee.com/solutions/architecture.html"
              class="footer-right-link-list"
              >建筑</a
            >
          </ul>
        </div> -->
      </div>
    </div>
    <!-- 咨询小窗 -->
    <div class="zhezhao"></div>
    <div class="consultcon">
      <div class="consulttit">
        <span class="kfgirl"><img src="@/assets/mobile/v0.1/images/mobil_header/kfgirl.png" />金蝶客服</span>
        <span class="kfdownarrow" id="J_kfclose"><img
            src="@/assets/mobile/v0.1/images/mobil_header/kfdownarrow.png" /></span>
      </div>
      <iframe src="" class="kfiframe"></iframe>
    </div>
  </div>
</template>
<!-- <script type="text/javascript" src="@/assets/mobile/v0.1/js/jquery-1.11.1.min.js"></script>
<script src="@/assets/mobile/v0.1/js/new-index/mo_header.js" type="text/javascript" charset="utf-8"></script>
<script type="text/javascript" src="@/assets/mobile/v0.1/js/new-index/score.js" charset="utf-8"></script> -->
<script>
//   require("@/assets/mobile/v0.1/js/jquery-1.11.1.min.js");
// require("@/assets/mobile/v0.1/js/header.js");
import { slider, slideritem } from "vue-concise-slider";
import product from "./product.vue";
import companyProfile from "./companyProfile.vue";
import caseCompany from "./case.vue";
import agreement from "./agreement.vue";
export default {
  name: "allProduct",

  data() {
    return {
      productE: {},
      allProduct: [
        {
          name: "e企拆表",
          vedioSrc: require("@/assets/example1.mp4"),
          data: {
            name: "e企拆表",
            // backgroundImage: require("@/assets/mobile/v0.1/images/mobil_product_xc/sass_echaitu.png"),
            backgroundImage: require("@/assets/mobile/v0.1/images/mobil_product_xc/sass_eyanpiao.png"),
            description: "一款提升制造业拆图效率的工具,让拆图效率提高200%",
            summary: "聚焦制造业拆图场景的效率工具", //视频总结
            summaryTxt:
              "e企拆表是聚焦制造业拆图场景的效率工具，运用了目标检测、表格识别OCR、文本识别OCR等AI技术手段，实现了生产图纸的物料表自动提取，并自动整理成BOM。极大缩短了拆图环节的时间、消除了拆图工程师工作上的困难，减少人力成本，为机械制造企业实现生产降本增效！", //视频总结文字
            peculiarity: [
              {
                peculiarityItem: [
                  {
                    name: "颠覆传统的效率工具",
                    desc: "内置深度学习机器人、目标检测机器人、表格识别机器人、智能纠错机器人、图纸关联机器人、BOM表机器人，6大智能机器人帮助释放双手，极大提高效率。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/shipei.png"),
                  },
                  {
                    name: "个性化的图纸适配",
                    desc: "模板设置与AI训练的能力赋给企业，企业可根据自身所需，自由配置不同的样式模板，全面支持各种工业图纸，匹配实际需要，让效能的提高真正能落地。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/xiaolv.png"),
                  },
                  {
                    name: "极致数智体验",
                    desc: "基于微服务架构、分布式计算、AI等领先技术，保障图纸识别准、效率高。实现图纸识别率可达95%，平均1min完成30张图纸文本提取，是人力敲字的100倍以上。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/tiyan.png"),
                  },
                ],
              },
              // {
              //   peculiarityItem: [
              //     {
              //       name: "极致数智体验",
              //       desc: "基于微服务架构、分布式计算、AI等领先技术，保障图纸识别准、效率高。实现图纸识别率可达95%，平均1min完成30张图纸文本提取，是人力敲字的100倍以上。",
              //       src: require("@/assets/mobile/v0.1/images/mobil_product_xc/tiyan.png"),
              //     },
              //   ],
              // },
            ],
            functionTitle: "人工智能与业务的结合，真正实现拆图效率的飞跃提高",
            //产品功能
            function: [
              {
                title: "从手工到半自动到智能的体验成长",
                desc: "e企拆表是基于AI的成长型产品，使用e企拆表即可从全手工打字拆图的模式，变成框选图纸自动拆图的模式，而所有在业务中实际应用的图纸均会自动基于深度学习自动完成训练，最终实现全智能模式，释放双手。",
                details: [
                  {
                    name: "图纸识别区域检测裁剪",
                  },
                  {
                    name: "图纸文本自动提取",
                  },
                  {
                    name: "图纸自动训练",
                  },
                ],
              },
              {
                title: "图纸层级关系挖掘，物料自动计算",
                desc: "拆图的目标是BOM表的输出，e企拆表能基于AI提取的文件，自动挖掘同一项目中多张图纸的父子层级关系，并建立关联。并基于提取的物料数据与层级关系，自动计算并输出BOM。",
                details: [
                  {
                    name: "图纸关联与展示",
                  },
                  {
                    name: "BOM计算与输出",
                  },
                ],
              },
            ],
            demoDesc: "借助高新技术与实践，让拆图效率提高200%-380%",
            //产品案例
            case: [
              // {
              //   title: "文档整合助力财务信息化转型",
              //   companyName:"德施曼",
              //   src: require("@/assets/mobile/v0.1/images/mobil_product_xc/anli_tebian.png"),
              //   logo: require("@/assets/mobile/v0.1/images/mobil_product_xc/logo-xh.png"),
              //   desc: "基于道可维斯的文档数字化智能软件中台，完了共享中心的革命性的数字建设，实现“票据文件整理、数据采集、业财审核利用、凭证整理、归档管理等”全链条数管控与效率提升。",
              // },
              {
                title: "装备中国 装备世界",
                companyName: "沈阳特变",
                src: require("@/assets/companyImgs/特变电工背景.png"),
                logo: require("@/assets/mobile/v0.1/images/mobil_product_xc/logo-xh.png"),
                desc: "以AI智能识别技术为主，人工校验为辅的智能拆图工具，对PDF图纸进行智能能识别，提取每张图纸的明细栏，汇总生成产品BOM表，支持一键导出。降低人工重复操作，提高BOM源头数据采集的准确性和高效性。",
              },


            ],
            //用户画像
            uesrPortrait: [
              {
                title: "企业画像",
                desc: "机械制造业，根据外来图纸生产产品的企业",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/qiyehuaxiang.jpg"),
              },
              {
                title: "用户画像",
                desc: "工艺部、技术部的拆图工程师",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yonghuhuaxiang.jpg"),
              },
            ],
            //资料
            material: [
              {
                title: "e企拆表·产品介绍文档",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/download01.png"),
              },
              {
                title: "e企拆表·用户故事",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/chaitu_story.png"),
              },
            ],
            //常见问题
            noramlQuestion: [
              {
                ques: "e企拆表如何收费？",
                answer: [
                  "产品上，软件按年订阅付费，图纸识别按流量计费。",
                  "服务上，调试与培训按次付费，二次开发按实际开发人天付费。",
                ],
              },
              {
                ques: "e企拆表支持定制化开发吗？",
                answer: [
                  "可以支持定制化开发，定制化开发按实际人天收费。",
                  "如果提的二次开发需求符合我们产品规划方向，都会作为产品迭代进行升级，是免费的。",
                ],
              },
              {
                ques: "e企拆表限制用户数量吗？",
                answer: [
                  "不限制，管理员在可以自行配置用户。",
                ],
              },
              {
                ques: "e企拆表数据存在哪里吗？",
                answer: [
                  "混合云模式：存客户本地，但应用在在道可维斯的云上；",
                  "公有云模式：存在道可维斯的公有云上；"
                ],
              },
              {
                ques: "e企拆表的识别准确率有多少？",
                answer: [
                  "综合识别准确率为95%"
                ],
              },
            ],
          },
        },
        {
          name: "e企验票",
          vedioSrc: require("@/assets/example1.mp4"),
          data: {
            name: "e企验票",
            backgroundImage: require("@/assets/mobile/v0.1/images/mobil_product_xc/sass_eyanpiao.png"),
            description: "一个没有卡顿的验票工具，让报账单顺利完结",
            summary: "聚焦验票环节体验与效率的新一代验票产品", //视频总结
            summaryTxt:
              "e企验票是聚焦验票环节体验与效率的新一代验票产品！相对市面上传统的验票产品，e企验票解决的在自动化验票环节给经办人带来的各种不通过的困扰。e企验票贯彻的是既要管控，也要效率，还要用得舒服的理念，给企业带来提升员工满意度、缩短报账时效、减少财务人员人工成本的实际价值。", //视频总结文字
            peculiarity: [
              {
                peculiarityItem: [
                  {
                    name: "极致体验",
                    desc: "e企验票在市面的传统验票产品的基础上，再往前走了一公里。结合AI委托查验与产品架构的高并发能力，真正实现了验票环节“不卡顿”！",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_tiyan.png"),
                  },
                  {
                    name: "生态链接",
                    desc: "作为财务费控的一环，e企验票连接业务系统、财务系统、电子税局、税务系统、档案系统，助力企业实现业财税档一体化。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_shengtai.png"),
                  },
                ],
              },
            ],
            functionTitle: "13年财务影像经验与智能结合，为企业财税体系赋能",
            //产品功能
            function: [
              {
                title: "委托AI查验模式，不仅仅是传统OCR",
                desc: "用户提交的发票，自动委托AI机器人并发做查重、验伪、票单核查，AI机器人根据税局返回查验异常信息，自动对非人为的异常自动进行灰度处理。单据与票据处理进度，通过微信实时通知用户。",
                details: [
                  {
                    name: "发票查重验伪",
                  },
                  {
                    name: "票单核查",
                  },
                  {
                    name: "AI灰度处理",
                  },
                  {
                    name: "订阅通知",
                  },
                ],
              },
              {
                title: "数据深度利用，票据数据连接税务系统、档案系统",
                desc: "识别查验完的票据，自动形成企业的进项发票池，可根据实际需要对票据数据进行统计与分析。同时支持连接税务系统，实现进项发票数据的传递与自动抵扣。最终业务结束，实现自动化归档",
                details: [
                  {
                    name: "进项发票池",
                  },
                  {
                    name: "生态集成接口",
                  },
                ],
              },
            ],
            demoDesc: "一个没有卡顿的验票工具，让报账单顺利完结",
            //产品案例
            case: [
              {
                title: "财务共享票据影像管理",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/jiantou.png"),
                logo: require("@/assets/mobile/v0.1/images/mobil_product_xc/jiantou_logo.png"),
                desc: "e企验票与财务系统进行集成，从根本上告别了手工审单的时代，向影像化、数字化时代迈进。将审单真正实现了无纸化、信息化、流程化等，促使审单过程更加规范、统一、安全。",
              },
            ],
            //用户画像
            uesrPortrait: [
              {
                title: "企业画像",
                desc: "中小型及以上的企业",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/qiyehuaxiang.jpg"),
              },
              {
                title: "用户画像",
                desc: "CFO",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yonghuhuaxiang.jpg"),
              },
            ],
            //资料
            material: [
              {
                title: "e企验票·产品介绍文档",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/download03.png"),
              },
            ],
            //常见问题
            noramlQuestion: [
              {
                ques: "产品的交付周期有多长？",
                answer: [
                  "私有云，我们只交付标准产品，合同签订后，客户提供好服务器，一般2周左右完成交付。",
                  "公有云，即买即用，没有交付的问题，但我们可以安排培训的工作。",
                ],
              },
              {
                ques: "需要收取实施服务收费吗？怎么收？",
                answer: [
                  "私有云的情况下，标准10人天。如果还要做集群与灾备，则要根据实际情况评估。",
                  "公有云订阅不收取实施费用。",
                ],
              },
              {
                ques: "运维服务如何保障？ ",
                answer: [
                  "我们商务上采取订阅模式，只能用户还在订阅使用产品，我们都会保障系统稳定使用与产品迭代更新，以及响应客户的问题。",
                ],
              },
              {
                ques: "定制化需求怎么处理？需要收费吗？",
                answer: [
                  "一般来说，我们e企系列的产品没有客户定制化的说法。",
                  "如果客户觉得需要哪些产品上需要优化或增加的，可以向道可维斯提出。产品团队都会做分析，如果需求具有普适性，都会作为产品迭代进行升级，是免费的。",
                  "如果太过于个性化的需求，我们可以提供知识转移服务，让客户或伙伴进行开发。",
                ],
              },
              {
                ques: "私有化部署服务器最低配置要求怎样？",
                answer: [
                  "至少1台应用服务器+1台AI服务器（GPU服务器）。应用服务器：32G内存+1T硬盘可扩展+16核CPU；GPU服务器：32G内存+1T硬盘可扩展+16核CPU+显卡N卡16G显存。",
                  "详细可以致电联系我们。",
                ],
              },
            ],
          },
        },
        {
          name: "e企档案",
          vedioSrc: require("@/assets/example1.mp4"),
          data: {
            name: "e企档案",
            backgroundImage: document.body.offsetWidth > 768 ? require("@/assets/companyImgs/dangan.png") : require("@/assets/companyImgs/dangan_mobile.png"),
            description: "一款让交易内容+证明内容+说服内容,在企业跨业务跨组织跨系统协作中，集中共享高速流转的软件产品。",
            summary: "后ERP时代企业降本增效全新解决思路", //视频总结
            summaryTxt:
              "跨业务、跨组织、跨系统、跨供应链的商业协作已经是常态。传统的系统集成只解决了结构化数据互通问题，非结构化内容仍是散落一地的信息孤岛。e企档案聚焦后ERP大中企业三多四跨环境下的协作效率问题，从电子档案的角度着力，实现非结构化数据的实时共享，实现降本增效。", //视频总结文字
            peculiarity: [
              {
                peculiarityItem: [
                  {
                    name: "内容共享",
                    desc: "e企档案从“共享”模式出发，整合了来自线上与线下的业务链的各种材料与档案，再以共享的方式支援业务使用，在跨系统业务应用效果尤为突出。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_tiyan.png"),
                  },
                  {
                    name: "业务穿透",
                    desc: "通过业务规则配置，自动形成档案与档案之间的关系链，通过关系链实现档案之间的穿透利用，保障业务有迹可循，企业应对审计与抗风险能力显著提高。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_shengtai.png"),
                  },
                  {
                    name: "多方式采集",
                    desc: "除常见的扫描与API集成的档案采集方式外，还支持共享盘监听、网盘监听、邮箱监听等自动化采集方式，更简单地对企业多来源、多介质的材料做采集集中。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/xiaolv.png"),
                  },
                  {
                    name: "融合AI",
                    desc: "基于e企档案的AI-OCR的能力，可对档案文件内容进行解析提取（如果报表、图纸等），并将数据内容应用于检索、传递业务与分析，深度挖掘电子档案价值。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/tiyan.png"),
                  },
                ],
              },
            ],
            functionTitle: "从结果管理延展至过程管理，让档案在业务中深度利用",
            //产品功能
            function: [
              {
                title: "档案关联穿透整合，内容共享支援业务",
                desc: "e企档案可根据规则设置，贯穿业务链条对档案进行整合（例如：合同-验收单-付款申请-付款单-发票附件-银行回单-记账凭证），穿透整合的电子档案可用于跨系统全链条调阅利用与审计利用。",
                details: [
                  {
                    name: "电子档案穿透规则配置",
                  },
                  {
                    name: "电子档案穿透查询查阅",
                  },
                  {
                    name: "电子档案共享调阅",
                  },
                ],
              },
              {
                title: "完备的档案生命周期管理，场景使用更自由",
                desc: "e企档案基于ECM的信息生命周期而设计，在前端有档案采集、内容提取、协作利用与信息整合，在后端有电子档案的管理、信息搜索使用、档案销毁。可结合企业实际场景贴合配置使用。",
                details: [
                  {
                    name: "6种档案采集方式（扫描、导入、API、邮箱、网盘、共享盘）",
                  },
                  {
                    name: "档案管理能力（鉴定、上架、下架、销毁、组卷、实物管理等）",
                  },
                  {
                    name: "档案在线查阅、下载",
                  },
                ],
              },
            ],
            demoDesc: "深度挖掘档案利用价值，让您的档案活起来",
            //产品案例
            case: [
              {
                title: "互联网社区的开拓者",
                companyName: "彩生活",
                // src: "https://gw.colourlife.com/uploads/img1/20210402/606702ceddeb8.jpg",
                src: require("@/assets/companyImgs/彩生活背景.png"),
                // logo: "https://gw.colourlife.com/Public/Home/default/images/logo.png",
                logo: require("@/assets/companyImgs/logo/彩生活logo_white.png"),
                desc: "通过与OA、EAS系统对接，打通信息壁垒，实现电子会计档案的自动归档，节省人力劳动，免去纸张打印，减少纸张耗材。通过业务关系，实现电子档案自动勾稽关联，充分检查电子档案完整性，强化风险管控，提高档案管理的合规性，规避税务审计风险。",
              },
              {
                title: "汇聚善力量",
                companyName: "汉森",
                src: "http://www.hansenzy.com/uploadfile/2019/1224/20191224032910894.jpg",
                // logo: "http://www.hansenzy.com/statics/hs/img/index_02.png",
                logo: require("@/assets/companyImgs/logo/汉森logo_white.png"),
                desc: "与OA、EAS、云之家、生产管理等多个系统集成，实现档案自动化采集、集中归档，大大减少企业员工工作量，释放档案存放的物理空间，降低档案的保管成本。建立企业档案的全生命周期管理，提高档案管理的利用效率及安全性。",
              },
              {
                title: "享受高品质汽车生活",
                companyName: "正通",
                src: "https://www.zhengtongauto.com/images/index/2021_08_10_212430923.jpg",
                // logo:"https://www.zhengtongauto.com/images/Icon_n1.gif",
                logo: require("@/assets/companyImgs/logo/正通logo_white.png"),
                desc: "电子档案系统与EAS系统集成，打通不同系统之间的信息孤岛；增加档案接收、转递的信息采集，消除档案借阅流程的管控盲点；提高档案管理的安全性、合规性和高效性。",
              },
            ],
            //用户画像
            uesrPortrait: [
              {
                title: "企业画像",
                desc: "中小型及以上的企业",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/qiyehuaxiang.jpg"),
              },
              {
                title: "用户画像",
                desc: "CFO",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yonghuhuaxiang.jpg"),
              },
            ],
            //资料
            material: [
              {
                title: "e企档案·产品介绍文档",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/download03.png"),
              },
            ],
            //常见问题
            noramlQuestion: [
              {
                ques: "e企档案如何收费？",
                answer: [
                  "产品上，一般按年订阅付费，支持企业买断私有。",
                  "服务上，实施与开发按实际评估的服务人天付费。",
                ],
              },
              {
                ques: "e企档案支持定制化开发吗？",
                answer: [
                  "可以支持定制化开发，定制化开发按实际人天收费。",
                  "如果提的二次开发需求符合我们产品规划方向，都会作为产品迭代进行升级，是免费的。",
                ],
              },
              {
                ques: "e企档案限制用户数量吗？",
                answer: [
                  "不限制，管理员在可以自行配置用户。",
                ],
              },
              {
                ques: "e企档案数据存在哪里吗？",
                answer: [
                  "混合云模式：存客户本地，但应用在在道可维斯的云上；",
                  "私有云模式：存客户本地；"
                ],
              },
              {
                ques: "e企档案支持过程管理吗？",
                answer: [
                  "支持，e企档案支持过程管理，也支持结果管理。"
                ],
              },
            ],
          },
        },
        {
          name: "e企签收",
          vedioSrc: require("@/assets/example1.mp4"),
          data: {
            name: "e企签收",
            backgroundImage: document.body.offsetWidth > 768 ? require("@/assets/companyImgs/qianshou.png") : require("@/assets/companyImgs/qianshou_mobile.png"),
            description: "一款对物流签收工作，从发货在途，到抵达签收,再到收入确认的过程做了业务支撑与监控，帮助企业实现及时确认收入的软件产品。",
            summary: "聚焦快消品行业签收场景的效率工具", //视频总结
            summaryTxt:
              "e企签收是基于《企业会计准则第14号-收入》的规定中“收入应当在客户取得相关商品或服务（以下简称商品）控制权的时点确认”而设计。给快消品企业做财务客户结算、承运商运费结算、销售业绩提成结算的体系做了系统支撑与数据支持，帮助企业实现及时确认收入。", //视频总结文字
            peculiarity: [
              {
                peculiarityItem: [
                  {
                    name: "加快收入确认效率",
                    desc: "联动了企业与承运商两端的协同，对司机送货过程做了管控，保障及时拍摄收货回执，并自动推送发起收款流程，收入确认从几天骤降到几小时，保障收入确认的及时性。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/xiaolv.png"),
                  },
                  {
                    name: "智能化的签章审核",
                    desc: "e企签收系统具备签章智能比对能力，智能对收货回执盖章与客户实际备案章做核对，避免错章、少章的不合规业务进入收入流程，降低审核困难。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/shipei.png"),
                  },
                  {
                    name: "全程后台数据监控",
                    desc: "借助e企签收后台数据监控能力，企业与承运商能监控到送货单的过程情况数据，并汇总统计报表，为承运商运费计算提供了数据支撑。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_shengtai.png"),
                  },
                  {
                    name: "收入确认合法合规",
                    desc: "满足《企业会计准则第14号—收入》第十一条“相关收入应当在客户取得相关商品或服务（以下简称商品）控制权的时点确认”的要求，既快又合规。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/tiyan.png"),
                  },
                ],
              },
            ],
            functionTitle: "ECM在供应链的应用延展，收入确认百倍提速",
            //产品功能
            function: [
              {
                title: "收货回执及时回传",
                desc: "e企签收借助移动互联网与AI技术，结合业务协同的优化，确保收货确认回执快速回到企业，再通过AI能力检查能力结合人工补救机制，保障收货确认准确合规。最终达到收入确认又快又准的效果。",
                details: [
                  {
                    name: "收货回执与签收差异数据回传",
                  },
                  {
                    name: "签章智能比对",
                  },
                  {
                    name: "人工核对与补录",
                  },
                ],
              },
              {
                title: "物流签收过程监控",
                desc: "e企签收结合GPS，对送货过程环节做了监控，保障了送货情况有迹可循，运费结算有数可查，并在业务收尾连接了订单系统与财务报账系统，形成订单>发货>签收>收入的一体化管理方案。",
                details: [
                  {
                    name: "超时报警、围栏监控",
                  },
                  {
                    name: "后台数据监看、统计报表",
                  },
                  {
                    name: "送货数据同步、送货单影像调阅",
                  },
                ],
              },
            ],
            demoDesc: "道别收入确认的缓慢等待，效率合规双结合",
            //产品案例
            case: [
              {
                title: "只在乎您",
                companyName: "洁柔",
                src: "https://www.zsjr.com/upfiles/onepage/202112/1640336452319.jpg",
                logo: "https://www.zsjr.com/templates/dist/img/logo_wc.png",
                desc: "e企签收系统主要实现了签收功能，帮助JR企业提高了签收的效率，根据拍照签收确认收入，不需要等几天纸质回单再确认收入。满足了国家会计制度的要求，实现了收入确认从出库改为签收，实现了及时确认收入。",
              },
            ],
            //用户画像
            uesrPortrait: [
              {
                title: "企业画像",
                desc: "中小型及以上的企业",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/qiyehuaxiang.jpg"),
              },
              {
                title: "用户画像",
                desc: "CFO",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yonghuhuaxiang.jpg"),
              },
            ],
            //资料
            material: [
              {
                title: "e企签收·产品介绍文档",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/qianshou_introduce.jpg"),
              },
            ],
            //常见问题
            noramlQuestion: [
              {
                ques: "e企签收如何收费？",
                answer: [
                  "产品上，一般按年订阅付费，支持企业买断私有。",
                  "服务上，实施与开发按实际评估的服务人天付费。",
                ],
              },
              {
                ques: "e企签收支持定制化开发吗？",
                answer: [
                  "可以支持定制化开发，定制化开发按实际人天收费。",
                  "如果提的二次开发需求符合我们产品规划方向，都会作为产品迭代进行升级，是免费的。",
                ],
              },
              {
                ques: "e企签收限制用户数量吗？",
                answer: [
                  "不限制，管理员在可以自行配置用户。",
                ],
              },
              {
                ques: "e企签收数据存在哪里吗？",
                answer: [
                  "私有云模式：存客户本地；",
                ],
              },
              {
                ques: "e企签收支持司机是外包的业务吗？",
                answer: [
                  "支持",
                ],
              },
            ],
          },
        },
        {
          name: "电子影像",
          vedioSrc: require("@/assets/example1.mp4"),
          data: {
            name: "电子影像",
            backgroundImage: require("@/assets/mobile/v0.1/images/mobil_product_xc/sass_echaitu.png"),
            description: "一款借助影像共享技术，助力企业业财税档融合，助力财务升级的必备系统。",
            summary: "内容共享，助力实现业财税档融合", //视频总结
            summaryTxt:
              "道可维斯电子影像具备邮寄管理、单据扫描、影像展示、影像上传、业务流程对接、单据审核等能力，帮助企业各类单据、票据实现异地上传、集中审批。同时通过与业财税档系统的集成，实现数据共享与业财税档融合。", //视频总结文字
            peculiarity: [
              {
                peculiarityItem: [
                  {
                    name: "内容整合赋能业财",
                    desc: "电子影像整合了线上线下、纸质电子的文档材料，跨系统、跨业务为业务、财务审核业务单据提供审批的依据。减少人工的重复操作，提高流程准确度，加快流程的推进。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_shengtai.png"),
                  },
                  {
                    name: "生态集成稳健可靠",
                    desc: "道可维斯电子影像作为运营13年的产品，具有丰富的实践经验，产品成熟稳健可靠。在生态集成上具有众多API接口与国内众多ERP厂商无缝集成。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/shipei.png"),
                  },
                  {
                    name: "接入智能拥抱便捷",
                    desc: "电子影像底层架构上接入了OCR能力，针对不同的页面支持对文档内容做提取、分析、比对、查验（如发票、合同、表单等），释放业财审核人员生产力。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/xiaolv.png"),
                  },
                  {
                    name: "共享集中纸档管理",
                    desc: "电子影像适应纸质文档集中管理模式，具备邮寄管理能力，对共享收到的文档可以实现纸质与审批流的电子件实现智能化的对比，保障纸质归档材料的可靠性。",
                    src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yanpiao_tiyan.png"),
                  },
                ],
              },
            ],
            functionTitle: "全链条数字影像支援，消除信息孤岛",
            //产品功能
            function: [
              {
                title: "内容走向集中，支持全链条业务",
                desc: "电子影像通过扫描与导入集中线下文档，通过API集中线上文档，整合形成电子影像内容，并自动关联异构系统业务、财务单据，支撑全链条业务利用。",
                details: [
                  {
                    name: "生态集成接口",
                  },
                  {
                    name: "纸质文件扫描",
                  },
                  {
                    name: "影像与单据勾稽",
                  },
                ],
              },
              {
                title: "拥抱智能，内容深度应用",
                desc: "电子影像具备识别机器人、查验机器人、关联机器人、虚拟画图机器人等智能技术，支持发票、单据、表格、合同等业务材料做数据抽取、校验、勾稽、自动审核等深度应用，释放人力，提高效率。",
                details: [
                  {
                    name: "OCR识别",
                  },
                  {
                    name: "数据画图",
                  },
                  {
                    name: "数据比对",
                  },
                ],
              },
            ],
            demoDesc: "电子影像协同ERP，创造效益价值最大化",
            //产品案例
            case: [
              {
                title: "始于1967  源于珍珠 专于珍珠",
                companyName: "欧诗漫",
                // src: "http://www.osmun.com.cn/images/about4.jpg",
                src: require("@/assets/companyImgs/欧诗漫背景.png"),
                // logo:"http://www.osmun.com.cn/images/logo.png?v=3",
                logo: require("@/assets/companyImgs/logo/欧诗漫logo_white.png"),
                desc: "协同OA系统通过电子影像集成，结合OCR技术和发票识别查重服务，实现财务全流程电子化，减少人工的重复操作，提高流程准确度，加快流程的推进。",
              },
              {
                title: "万象盈德，不凡气韵",
                companyName: "盈德气体",
                src: "http://www.yingde.com/uploadfile/35f07591457f43d0a2868f4003382997.jpg",
                // logo: "http://www.yingde.com/uploadfile/606d1e5b349e489e88e138af2be634dd.svg",
                logo: require("@/assets/companyImgs/logo/盈德气体logo_white.png"),
                desc: "电子影像中台解决方案将文档转为非结构化数据，深度集成多个系统，完成多个系统的高效协同，实现文档数据的实时共享，帮助企业改善业务流程，提高业务效率。",
              },
              {
                title: "迈向世界一流的港口综合服务商",
                companyName: "招商港口",
                src: "https://www.cmhk.com/uploadfiles/202207/20220729145819731.jpg",
                // logo: "https://www.cmport.com.hk/images/logo.png",
                logo: require("@/assets/companyImgs/logo/招商港口logo_white.png"),
                desc: "电子影像系统解决方案为港口计费业务提供了基础的数据支持，实现将纸质单据电子化，集中生成数据被企业高效便捷利用，与计费系统对接，实现信息实时共享，让计费系统发挥重要作用。 ",
              },
            ],
            //用户画像
            uesrPortrait: [
              {
                title: "企业画像",
                desc: "中小型及以上的企业",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/qiyehuaxiang.jpg"),
              },
              {
                title: "用户画像",
                desc: "CFO",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yonghuhuaxiang.jpg"),
              },
            ],
            //资料
            material: [
              {
                title: "业财税档一体化方案",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yingxiang_download01.jpg"),
              },
              {
                title: "电子影像中台解决方案",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yingxiang_download02.jpg"),
              },
              {
                title: "财务共享影像操作手册",
                src: require("@/assets/mobile/v0.1/images/mobil_product_xc/yingxiang_download03.jpg"),
              },
            ],
            //常见问题
            noramlQuestion: [
              {
                ques: "电子影像如何收费？",
                answer: [
                  "产品上，一般按年订阅付费，支持企业买断私有。",
                  "服务上，实施与开发按实际评估的服务人天付费。",
                ],
              },
              {
                ques: "电子影像支持定制化开发吗？",
                answer: [
                  "可以支持定制化开发，定制化开发按实际人天收费。",
                  "如果提的二次开发需求符合我们产品规划方向，都会作为产品迭代进行升级，是免费的。",
                ],
              },
              {
                ques: "电子影像限制用户数量吗？",
                answer: [
                  "不限制，管理员在可以自行配置用户。",
                ],
              },
              {
                ques: "电子影像数据存在哪里吗？",
                answer: [
                  "私有云模式：存客户本地；",
                ],
              },
              {
                ques: "电子影像可以实现哪些场景的解决方案？",
                answer: [
                  "与ERP系统集成，可实现费用报销、财务共享、发票池、业财税档一体化、电子影像中台等解决方案",
                ],
              },
            ],
          },
        },
      ],
      vedioSrc: "",
      isActive: false,
      isOpenVideo: false,
      isShowquesetion: true,
      questionMessage: "",
      checked: false,
      question: "", //提问的问题
      drawer: false, //提问框
      offsetWidth: document.body.offsetWidth,//页面宽度
      someList: [
        {
          html: "slide1",
          style: {
            background: "#fff",
          },
        },
        {
          html: "slide2",
          style: {
            background: "#4bbfc3",
          },
        },
        {
          html: "slide3",
          style: {
            background: "#7baabe",
          },
        },
      ],
      //Slider configuration [obj]
      options: {
        // pagination: true,//pagination配置轮播图监视器（小圆点）
        pagination: false,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: true, // Scratch style
        speed: 300, // Sliding speed
        currentPage: 0,
        timingFunction: "ease", // Sliding mode
        // loop: false, // Infinite loop
        // autoplay: 0 // Auto play[ms]
      },
      optionsTrue: {
        pagination: true,//pagination配置轮播图监视器（小圆点）
        // pagination: false,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: true, // Scratch style
        speed: 300, // Sliding speed
        currentPage: 0,
        timingFunction: "ease", // Sliding mode
        // loop: false, // Infinite loop
        // autoplay: 0 // Auto play[ms]
      },
      systemPingtai: true,
      code: "",
    };
  },
  created() {
    console.log(this.$router.currentRoute);
    let name = this.$route.query.data;
    if (!sessionStorage.getItem('operationLog')) {
      let operationLog = `公众号-${name}`
      sessionStorage.setItem('operationLog', operationLog)
    }
    sessionStorage.setItem('from', name)
    let data = this.allProduct.filter((item) => {
      return item.name == name;
    });
    console.log(data);
    this.vedioSrc = data[0].vedioSrc;
    this.productE = data[0].data;
    let system = {};
    system.pingtai =
      /(Win32|Win16|WinCE|Mac68K|MacIntel|MacIntel|MacPPC|Linux mips64)/i.test(
        navigator.platform
      );
    console.log(system.pingtai);
    if (!system.pingtai) {
      console.log(1);
      //电脑
      console.log("移动端", system.pingtai);
      const userAgent = navigator.userAgent.toLowerCase();
      //是否微信浏览器
      if (userAgent.includes('micromessenger')) {//是
        if (
          !sessionStorage.getItem("userInfo") ||
          sessionStorage.getItem("userDetialInfo") == ""
        ) {
          this.getCode();
        }
      }
      
      // this.getCode();
    }
    // this.getCode()
    // console.log(this.productE);
  },
  methods: {
    getCode() {
      // let data = {
      //   openid: "o-W8KjxozWkgsA3K1l8lYXk5iGcc",
      //   nickname: "",
      //   sex: 0,
      //   province: "",
      //   city: "",
      //   country: "",
      //   headimgurl:
      //     "https://thirdwx.qlogo.cn/mmopen/vi_32/HrbY1o4wvbcY2OhZEhH4BWp41GHuDuNKia1rNVJuBQH5ybIXVQnE2uj5Ef9Bok6A1WJWhkIia4vZPuic2lLYlqlJA/132",
      //   privilege: [],
      //   unionid: "",
      // };
      // sessionStorage.setItem("userInfo", JSON.stringify(data));
      // console.log(JSON.parse(sessionStorage.getItem("userInfo")));
      // 非静默授权，第一次有弹框
      this.code = "";
      // if (this.systemPingtai) {
      //   var local = "http://docuvix.com";
      // } else {
      var local = window.location.href;
      // }
      this.appid = "wx27e60c547a5ab724"; //公众号里有自己查，不会找的查一下百度
      console.log(local);
      this.code = this.getUrlCode().code; // 截取code

      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
        sessionStorage.setItem("code", this.code);
      } else {
        // alert(this.code);
        console.log(this.$route.query.data);
        let param = {
          Code: this.code,
          source: this.$route.query.data,
        };
        console.log(param);
        //把code存到session
        sessionStorage.setItem("code", this.code);
        this.$api.beyoudsky.GetWXScopeInfo(param).then((res) => {
          if (res.data.errorCode == "1") {
            console.log('allProduct', res);
            let openid = JSON.parse(res.data.data).openid;
            console.log(openid);
            let param = { WXID: openid };
            this.GetUserPoByWX(param);
          }
          if (res.data.errorCode == "-99") {
            alert(res.data.errorText);
            return;
          }
          sessionStorage.setItem("userInfo", res.data.data);
        });
        // 你自己的业务逻辑

        // this.GetWXUserInfo();
      }
    },
    // 截取url中的code方法
    getUrlCode() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest);
      return theRequest;
    },
    //根据微信openID获取用户信息
    GetUserPoByWX(data) {
      this.$api.beyoudsky.GetUserPoByWX(data).then((res) => {
        if (res.data.errorCode == "1") {
          console.log(res);
          sessionStorage.setItem("userDetialInfo", res.data.data);
        } else {
          alert(res.data.errorText);
        }
      });
    },
    //打开案例详情页面
    openCaseDetail(title, type) {
      let name = this.$route.query.data + type;
      this.$utils.AddAction(name)
      this.$router.push({
        path: "/caseDetail",
        query: {
          title
        }
      });
    },
    openDownload(data, type) {
      let name = this.$route.query.data + type;
      this.$utils.AddAction(name)
      this.$router.push({
        path: "download",
        query: {
          product: JSON.stringify(data),
          productName: this.productE.name,
        },
      });
    },
    openRegister(type) {
      let name = this.$route.query.data + type;
      this.$utils.AddAction(name)
      this.$router.push({ path: "/register", query: { type: "预约演示", DemandType: "需求" } });
    },
    //打开视频
    OpenVideo(type) {
      this.isOpenVideo = true
      let name = this.$route.query.data + type;
      this.$utils.AddAction(name)
    },
    qidai() {
      alert("敬请期待!");
    },
    scrollChange() {
      // console.log("滚动高度", window.pageYOffset);

      // console.log("距离顶部高度", this.$refs.menu.getBoundingClientRect().top);
      if (window.pageYOffset < 100) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },
    //打开提问框
    openQuestion() {
      this.drawer = true;
    },
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
    //产品页的提示框
    questionOpen(data) {
      // console.log(data);
      this.drawer = true;
      this.questionMessage = `感谢您对道可维斯的${data}产品提出宝贵意见!`;
      this.question = "";
    },
    //滑动时触发的事件
    slide(data) {
      switch (data.currentPage) {
        case 0:
          this.isShowquesetion = true;
          this.questionMessage =
            "感谢您对道可维斯的使命-用文档整合链接你我他提出宝贵意见!";
          this.question = "";
          break;
        case 1:
          this.isShowquesetion = true;
          this.questionMessage =
            "感谢您对道可维斯的伙伴政策-一次分享三年分成提出宝贵意见!";
          this.question = "";
          break;
        case 2:
          this.isShowquesetion = false;

          break;
        case 3:
          this.isShowquesetion = true;
          this.questionMessage = "感谢您对道可维斯的案例名录提出宝贵意见!";
          this.question = "";
          break;
      }
      // console.log(data);
    },
    onInit(data) {
      // console.log(data);
    },
    onTap(data) {
      // console.log(data);
    },
  },
  components: {
    slider,
    slideritem,
    product,
    companyProfile,
    agreement,
    caseCompany,
  },
  mounted() {
    // 滚动条的获取
    // window.addEventListener("scroll", this.scrollChange, true);
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src = "./assets/mobile/v0.1/js/header.js";
    // document.body.appendChild(script);
    console.log(this.vedioSrc);
    this.$refs.video1.src = this.vedioSrc
  },
};
</script>
<style lang="less" scoped>
@import "./assets/mobile/v0.1/css/new-common.css";
@import "./assets/mobile/v0.1/css/iconfont.css";
@import "./assets/mobile/v0.1/css/animate.min.css";
@import "./assets/mobile/v0.1/css/swiper.min.css";
@import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
@import "./assets/mobile/v0.1/css/mo_xkdetail.css";
@import "./assets/mobile/v0.1/css/header.css";
@import "./assets/mobile/v0.1/css/footer.css";

// @import "./assets/mobile/v02/css/mo_product_gather.css";
// @import "./assets/mobile/v02/css/mo_product_pages.css";

@import "./assets/mobile/v0.1/css/mo_score.css";

.duibiao {
  padding: 50px 0;
}

.huoqu {
  padding: 30px 0;
}

.huoqu_more {
  color: #fff;
}

.resources {
  padding: 0.5rem 0;
}

.sort {
  margin-bottom: 0.5rem;
  padding-bottom: 20px;
}

.w1440 {
  padding-left: 20px !important;
  padding-right: 20px !important;
  box-sizing: border-box;

  .slider-item {
    margin-bottom: 50px;
  }
}

.epm_des {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 4;
}

.mobilheader_box {
  display: none;
}

.fnt_20 {
  font-size: 14px;
}

.fnt_32 {
  font-size: 22px;
}

.fnt_36 {
  font-size: 24px;
}

.fnt_16 {
  font-size: 16px;
}

.fnt_14 {
  font-size: 14px;
}

.manage {
  padding: 50px 0;
}

.anli .swiper-slide .sw_bg {
  object-fit: cover;
  width: 100%;
}

.resources_txt {
  padding: 20px;
  height: 120px;
}

.resources_des {
  margin-top: 20px;
}

.title {
  width: auto;
}

@media only screen and (max-width: 1024px) {
  .mobilheader_box {
    display: block;
  }

  .shadow {
    display: none;
  }

  .anli_txt {
    top: auto;
    transform: translate(-50%, 0);
    bottom: 75px;
    padding: 0 20px;

    .anli_more {
      font-size: 14px;
      color: #fff;
    }
  }

  .anli_tit {
    margin: 20px 0 10px;
  }

  .starting {
    padding: 30px 0;
  }

  .starting_sub {
    margin: 10px auto 20px;
    color: #575d6c;
  }

  .answer {
    padding: 30px 0;

    h3 {
      font-size: 24px;
    }
  }

  .fn_24 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1024px) {
  .mobilheader_box {
    display: block;
  }
}

@media only screen and (min-width:768px) {
  .product_detail_list li {
    border-left: solid 8px #0075ce;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 10px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    padding: 38px 0;
    background-color: #fff;
  }

  .product_detail_list .right {
    float: none !important;
    position: relative !important;
    width: 90% !important;
    padding-left: 30px;

  }
}

.mobile-lv3nav .product-small:not(:first-child),
.mobile-lv3nav .product-high:not(:first-child),
.mobile-lv3nav .product-large:not(:first-child),
.mobile-lv3nav .product-pass:not(:first-child) {
  margin-top: 0.5rem;
}

#innerdiv {
  top: 28% !important;
}

@media only screen and (min-width: 1024px) {
  .product_xc_banner {
    .text {
      top: 60%;
      left: 135px;

      .w1440 {
        text-align: left;
      }
    }
  }

  .product_xc_banner {
    .fnt_42 {
      font-size: 42px;
      font-weight: bold;
      transform: translateY(56px);
    }

    .product_xc_banner_link {}
  }

  .product_xc_banner .text {
    top: 60%;
    left: 135px !important;
  }

  .product_xc_banner .text .banner-text {
    margin-bottom: 220px;
    font-size: 22px;
    transform: translateY(60px);
  }

  .duibiao {
    .fnt_32 {
      font-size: 32px;
      font-weight: 800;
    }

    .fnt_14 {
      font-size: 16px;
    }
  }

  .product_detail_experience {
    .fnt_32 {
      font-size: 32px;
      font-weight: 800;
    }
  }

  .huoqu {
    text-align: center;

    .huoqu_more {
      margin: 0 auto;
      margin-top: 35px;
      text-align: center;
    }
  }

  .resources {
    .fnt_32 {
      font-size: 32px;
      font-weight: 800;
    }
  }



  .mobil_footer {
    display: none;
  }

  .anli {
    height: 12rem !important;

    .slider-pagination-bullets {
      text-align: center;
    }

    .shadow {
      left: 0;
    }

    .anli_txt {
      img {
        width: 80px;
      }
    }
  }

  .anli_txt {
    margin-top: 180px !important;
    padding: 0;
    margin-left: 100px;

    .fnt_36 {
      font-size: 36px;
    }

    .fnt_20 {
      font-size: 20px;
    }
  }

  .starting .w1440 {
    display: flex;
  }

  .starting .w1440 .fnt_32 {
    padding-top: 20px;
    width: 49% !important;
  }

  .starting .w1440 .fnt_32 img {
    height: 600px;
  }

  .starting .w1440 .fnt_16 {
    margin-bottom: 20px;
  }

  .duibiao {
    padding: 50px 135px;

    // .title{
    //   padding:  0 8%;
    //   margin-bottom: 70px ;
    // }
    .slider-container {

      // padding: 0  4.5%!important;
      .slider-wrapper {

        .swiper-slide {
          // width: 30%!important;
          padding: 10px;
          text-align: center;
        }
      }
    }
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt {
    padding: 0 !important;
  }

  .resources {
    padding: 0.9rem 135px;
  }

  .answer {
    padding: 0 135px;

    .fnt_32 {
      margin-top: 20px;
      font-size: 32px;
    }
  }

  .product_detail_experience {
    padding: 0 135px;
    background-color: #e1e9f3;

    .product_detail_list {
      li {
        width: 99% !important;
        // height: 300px;
        margin-bottom: 30px;
      }
    }
  }

  .huoqu {
    padding: 30px 135px;
  }


  /* e企拆表 */
  .product_detail_list li {
    border-left: solid 8px #0075ce;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 10px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    padding: 38px 0;
    background-color: #fff;
  }

  .product_detail_list .right {
    float: none !important;
    position: relative !important;
    width: 90% !important;
    padding-left: 30px;

  }
}

.normalQuestion {
  padding: 0.6rem 0;
}

.el-collapse {
  border-top: none;
}

// 常见问题图标
.collapse-title {
  flex: 1 0 90%;
  margin-left: 10px;
  order: 1;

  .el-collapse-item__header {
    flex: 1 0 auto;
    order: -1;
  }
}

// .el-collapse-item {
//   padding: 0.3rem 0;
// }
.answer /deep/ .el-collapse-item__header {
  height: 58px;
  line-height: 58px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/deep/.el-collapse-item__content {
  font-size: 16px;
  padding-left: 32px;
}

.isBlock {
  display: block;
}

.EBC-intro-list .swiper-wrapper .swiper-slide {
  width: 50%;
  margin-bottom: 20px;

  .txt {
    padding: 0 6%;

    p {
      color: #575d6c;
      font-size: 14px;
      line-height: 20px;
    }

    h3 {
      margin-bottom: 6px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}

.EBC-intro-list .swiper-wrapper {
  white-space: "normal";
  text-align: "left";
  // display: flex;
  // align-content: start;
}

.EBC-intro-list .swiper-wrapper .swiper-slide {
  float: left;
  width: 25%;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .icon {
  max-width: 85px;
  margin: 0 auto 20px;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .icon img {
  max-width: 100%;
  transition: all 0.5s;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt {
  padding: 0 6%;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt h3 {
  text-align: center;
  margin-bottom: 12px;
  color: #252b3a;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt p {
  color: #575d6c;
  line-height: 28px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .EBC-intro-list {
    overflow: hidden;
  }

  .EBC-intro-list .swiper-wrapper {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide {
    width: 50%;
    margin-bottom: 20px;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .icon {
    margin-bottom: 10px;
    max-width: 48px;
    text-align: center;
    height: 37px;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .icon img {
    width: auto;
    max-height: 100%;
    object-fit: contain;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt h3 {
    margin-bottom: 6px;
    font-weight: bold;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt p {
    font-size: 14px;
    line-height: 20px;
  }

  .collapse-title {
    font-size: 16px;
  }

  .collapse-title~div {
    font-size: 14px;
  }
}

.video .closeVideo {
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 66;
  cursor: pointer;
}

.swiper-container-horizontal>.slider-pagination-bullets {
  bottom: -20px;
}

// #guidepage {
// height: 100%;
// width: 100%;
// }
</style>

<style lang="less">
// 相关产品swiper-dot样式
.duibiao {
  .slider-pagination-bullets {
    margin-top: 30px;

    .slider-pagination-bullet-active {
      background-color: #007aff;
    }
  }
}

// 案例swiper-dot样式
.anli {
  .slider-pagination-bullets {
    padding: 0 20px;
    text-align: left;
    bottom: 20px;
    display: block;

    .slider-pagination-bullet {
      background: #fff;
    }
  }
}

//了解更多
.resources {
  .swiper-container-horizontal {
    .slider-pagination-bullets {
      margin-top: 20px;

      .slider-pagination-bullet-active {
        background-color: #007aff;
      }
    }
  }
}

.product_detail_experience {
  padding-bottom: 0.7rem;

  .big_title {
    margin-bottom: 42px;
  }
}
</style>

<template>
  <div class="carousel">
    <div class="slider">
      <div v-for="(item, index) in items" :key="index" :class="['slide', { 'active': currentIndex === index }]">
        <img :src="item" class="slide-img" />
      </div>
    </div>
    <div class="controls">
      <button @click="prevSlide">Prev</button>
      <button @click="nextSlide">Next</button>
    </div>
    <div class="swiper-pagination">
      <span class="swiper-pagination-bullet" key="0" :class="[{ 'active': currentIndex === index }]"></span>
      <span class="swiper-pagination-bullet" key="1" :class="[{ 'active': currentIndex === index }]"></span>
      <span class="swiper-pagination-bullet" key="2" :class="[{ 'active': currentIndex === index }]"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      imgItems: [
        require('@/assets/images/shouyepc1.png'),
        require('@/assets/images/banner.png'),
        require('@/assets/images/shouyepc.jpg'),

      ]
    };
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },
    startAutoSlide() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
      }, 3000);
    }
  },
  mounted() {
    this.startAutoSlide();
  }
};
</script>

<style lang="less">
.carousel {
  width: 100%;
  height: 400px;
  position: relative;
}

.slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s;
  opacity: 0;
  position: absolute;
}

.slide.active {
  opacity: 1;
}

.slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

button {
  margin: 0 5px;
  padding: 5px 10px;
}

.swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  position: relative;
  width: 100px;
  border-radius: 0;
  margin: 0 7px;
  height: 3px;
  background: rgba(211, 211, 211, 0.7);
  border: 0;
}

.swiper-pagination-bullet-active::after {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  animation: left-width 4s linear 0s;
  background-color: #fff;
}
@keyframes left-width {
    0% {
        width: 0;
    }
    100% {
        width: 100px;
    }
}
</style>
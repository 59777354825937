<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  created() {

  },
};
</script>

<style lang="less">
@import "assets/css/base.css";

#app {
  height: 100%;
  width: 100%;
}
//路由切换
// 淡出淡入
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.swiper-pagination-bullet-active {
  background: pink none repeat scroll 0 0;
}
</style>

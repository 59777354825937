<template>
  <!-- 注册 -->
  <div id="register" class="wrapper">
    <div class="wrap">
      <!-- 留言咨询 -->
      <div class="window-mask"></div>
      <div class="window-box window-liuzi" :style="{ display: 'block' }">
        <!-- <span class="close"></span> -->
        <div class="hd">
          <h2 class="fnt_42" style="font-size: 24px">完善信息</h2>
          <div class="good-list">
            <ul class="clearfix">
              <li>
                <div class="icon">
                  <img src="@/assets/default/v02/image02/liuzi/icon1.png" alt="" />
                </div>
                <p class="fnt_20">免费体验/试用软件</p>
              </li>
              <li>
                <div class="icon">
                  <img src="@/assets/default/v02/image02/liuzi/icon2.png" alt="" />
                </div>
                <p class="fnt_20">免费下载洞察/报告</p>
              </li>
              <li>
                <div class="icon">
                  <img src="@/assets/default/v02/image02/liuzi/icon3.png" alt="" />
                </div>
                <p class="fnt_20">学习最新产品知识</p>
              </li>
              <li>
                <div class="icon">
                  <img src="@/assets/default/v02/image02/liuzi/icon4.png" alt="" />
                </div>
                <p class="fnt_20">参加线上活动</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="reginfo-box bd clearfix">
          <div class="left">
            <!-- <h3 class="fnt_28">注册个人信息</h3> -->
            <div class="formlists">
              <el-form ref="form" :model="user" :rules="rules" class="form">
                <div class="full-w shd">
                  <div class="lt">
                    <el-form-item prop="name">
                      <div class="ipt-item" @click="clickInput(1)" v-if="hasName == false">
                        <div class="input">
                          <el-input type="text" placeholder="" name="name" id="name" class="color" v-model="user.name" />
                          <label for="" class="nametit information" :class="{ active: isInput == 1 || user.name }"><span
                              class="Required"> * </span><span :class="{ Required1: tips.nameTip !== '姓名' }">{{
                                tips.nameTip }}</span></label>
                        </div>
                      </div>
                      <div class="ipt-item" @click="clickInput(1)" v-if="hasName == true">
                        <div class="input">
                          <el-input type="text" placeholder="" name="name" id="name1" ref="name1" class="color"
                            v-model="user.name" />
                          <label for="" class="nametit information" :class="{ active: isInput == 1 || user.name }"><span
                              class="Required"> * </span><span :class="{ Required1: tips.nameTip !== '姓名' }">{{
                                tips.nameTip }}</span></label>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item prop="phoneNumber">
                      <div class="ipt-item" @click="clickInput(2)" v-if="hasPhone == false">
                        <div class="input">
                          <div class="qh" :class="{ qh1: isInput == 2 || user.phoneNumber }">
                            +86
                          </div>
                          <el-input type="text" placeholder="" name="phone" id="mobil" class="color"
                            v-model="user.phoneNumber" />
                          <label for="" class="nametit information" :class="{
                            active: isInput == 2 || user.phoneNumber,
                          }"><span class="Required"> * </span><span
                              :class="{ Required1: tips.phoneTip !== '手机号' }">{{ tips.phoneTip }}</span></label>
                        </div>
                      </div>
                      <div class="ipt-item" @click="clickInput(2)" v-if="hasPhone == true">
                        <div class="input">
                          <div class="qh" :class="{ qh1: isInput == 2 || user.phoneNumber }">
                            +86
                          </div>
                          <!-- <el-input type="text" placeholder="" name="phone" id="mobil" ref="phoneNum1" :disabled="true"
                            class="color" v-model="user.phoneNumber" /> -->
                          <el-input type="text" placeholder="" name="phone" id="mobil" ref="phoneNum1"
                            class="color" v-model="user.phoneNumber" />
                          <label for="" class="nametit information" :class="{
                            active: isInput == 2 || user.phoneNumber,
                          }"><span class="Required"> * </span><span
                              :class="{ Required1: tips.phoneTip !== '手机号' }">{{ tips.phoneTip }}</span></label>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item prop="code">
                      <div class="ipt-item" @click="clickInput(3)">
                        <div class="input">
                          <el-row>
                            <el-col :span="14"><el-input type="text" placeholder="" name="code" id="code" class="color"
                                v-model="user.code">
                              </el-input>
                              <label for="" class="nametit information" :class="{
                                active: isInput == 3 || user.code,
                              }"><span class="Required"> * </span><span :class="{
  Required1: tips.codeTip !== '验证码',
}">{{ tips.codeTip }}</span></label></el-col>
                            <el-col :span="10"><el-button class="codeBtn" :class="{ codeBtnClick: codeCd }"
                                :disabled="codeCd" @click="handleCaptcha('form')">
                                <span v-if="codeCd">{{ long }}s后可重发</span>
                                <span v-else>获取验证码</span>
                              </el-button></el-col>
                          </el-row>
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="" style="text-align:left;"
                      v-if="needRadio == false"
                    >
                      <el-radio v-model="user.radio" label="1">我是客户</el-radio>
                      <el-radio v-model="user.radio" label="2">我是伙伴</el-radio>
                    </el-form-item>
                    <el-form-item
                      label=""
                      style="text-align: left"
                      v-if="needRadio == true"
                    >
                      <el-radio v-model="user.radio" :disabled="true" label="1"
                        >我是客户</el-radio
                      >
                      <el-radio v-model="user.radio" :disabled="true" label="2"
                        >我是伙伴</el-radio
                      >
                    </el-form-item>
                  </div>
                  <!-- <div class="rt">
                    <el-form-item prop="company">
                      <div class="ipt-item" @click="clickInput(4)" v-if="hasBussiness == false">
                        <div class="input">
                          <el-input
                            type="text"
                            placeholder=""
                            name="cname"
                            id="company"
                            class="color"
                            v-model="user.company"
                          />
                          <label
                            for=""
                            class="nametit information"
                            :class="{ active: isInput == 4 || user.company }"
                            >{{ tips.companyTip }}</label
                          >
                        </div>
                      </div>
                      <div class="ipt-item" @click="clickInput(4)" v-if="hasBussiness == true">
                        <div class="input" v-if="hasBussiness == true">
                          <el-input
                            type="text"
                            placeholder=""
                            name="cname"
                            id="company"
                            ref="bussiness1"
                            class="color"
                            v-model="user.company"
                          />
                          <label
                              for=""
                              class="nametit information"
                              :class="{ active: isInput == 4 || user.company }"
                              >{{ tips.companyTip }}</label
                            >
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item prop="mail">
                      <div class="ipt-item" @click="clickInput(5)" v-if="hasMail == false">
                        <div class="input">
                          <el-input
                            type="text"
                            placeholder=""
                            name="mail"
                            id="mail"
                            class="color"
                            v-model="user.mail"
                          />
                          <label
                            for=""
                            class="nametit information"
                            :class="{ active: isInput == 5 || user.mail }"
                            ><span
                              :class="{ Required1: tips.mailTip !== '邮箱' }"
                              >{{ tips.mailTip }}</span
                            ></label
                          >
                        </div>
                      </div>
                      <div class="ipt-item" @click="clickInput(5)" v-if="hasMail == true">
                        <div class="input">
                            <el-input
                              type="text"
                              placeholder=""
                              name="mail"
                              id="mail"
                              ref="mail1"
                              class="color"
                              v-model="user.mail"
                            />
                             <label
                            for=""
                            class="nametit information"
                            :class="{ active: isInput == 5 || user.mail }"
                            ><span
                              :class="{ Required1: tips.mailTip !== '邮箱' }"
                              >{{ tips.mailTip }}</span
                            ></label
                          >
                        </div>
                      </div>
                    </el-form-item>
                  </div> -->
                </div>
                <div class="full-w textare-item">
                  <el-form-item prop="renewalDesc">
                    <div class="textarea" @click="clickInput(6)">
                      <el-input type="textarea" name="" cols="30" placeholder="" id="describe" class="color"
                        v-model="user.renewalDesc" />
                      <label for="" class="nametit information" :class="{ active: isInput == 6 || user.renewalDesc }">{{
                        tips.renewalDesc }}</label>
                    </div>
                  </el-form-item>
                </div>
                <div class="full-w1">
                  <div class="check">
                    <input type="checkbox" id="xy" v-model="dynamic" />
                    <label for="xy">我愿意接收道可维斯发来的行业最新动态的简讯</label>
                  </div>
                  <div class="check">
                    <input type="checkbox" id="privicy" name="privicy" v-model="policy" />
                    <label for="privicy">选中此框，即表示我已阅读并同意《</label><a href="http://docuvix.com/views/privacy.html" target="_blank"><span
                      style="color: #2386ed">道可维斯隐私政策</span>》</a>
                  </div>
                </div>
                <div class="full-w">
                  <el-button :disabled="!(dynamic && policy)" v-if="DemandType=='问题'" type="primary" class="btn-blue-gradient btn-submit"
                    @click="submitRegister('form')">
                    提交问题
                  </el-button>
                  <el-button :disabled="!(dynamic && policy)" v-else type="primary" class="btn-blue-gradient btn-submit"
                    @click="submitRegister('form')">
                    提交需求
                  </el-button>
                  <!-- <el-button v-else type="primary" class="btn-blue-gradient btn-submit"
                    @click="submitRegister('form')">
                    提交需求
                  </el-button> -->
               
                </div>
              </el-form>
              <!-- <form action="" id="commentForm" onsubmit="return false;">
                <div class="full-w shd">
                  <div class="lt">
                    <div class="ipt-item" @click="isInput = 1">
                      <div class="input">
                        <input
                          type="text"
                          placeholder=""
                          name="name"
                          id="name"
                          class="color"
                          v-model="user.name"
                        />
                        <label
                          for=""
                          class="nametit information"
                          :class="{ active: isInput == 1 }"
                          ><span class="Required"> * </span>姓名</label
                        >
                      </div>
                    </div>
                    <div class="ipt-item" @click="isInput = 2">
                      <div class="input">
                        <div class="qh">+86</div>
                        <input
                          type="text"
                          placeholder=""
                          name="phone"
                          id="mobil"
                          class="color"
                          v-model="user.phoneNumber"
                        />
                        <label
                          for=""
                          class="nametit information"
                          :class="{ active: isInput == 2 }"
                          ><span class="Required"> * </span>手机</label
                        >
                      </div>
                    </div>
                    <div class="ipt-item" @click="isInput = 5">
                      <div class="input input-yzm">
                        <input
                          type="text"
                          placeholder=""
                          autocomplete="off"
                          name="verification"
                          id="code"
                          v-model="user.code"
                        />
                        <label
                          for=""
                          class="nametit"
                          :class="{ active: isInput == 5 }"
                          ><span class="Required"> * </span>验证码</label
                        >
                        <span
                          id="TencentCaptcha"
                          data-appid="2060285122"
                          data-cbfn="tencentCaptcha"
                          data-biz-state="data-biz-state"
                          class="getcode codeText"
                          >获取验证码</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="rt">
                    <div class="ipt-item" @click="isInput = 6">
                      <div class="input">
                        <input
                          type="text"
                          placeholder=""
                          name="cname"
                          id="company"
                          class="color"
                          v-model="user.company"
                        />
                        <label
                          for=""
                          class="nametit information"
                          :class="{ active: isInput == 6 }"
                          >公司名称</label
                        >
                      </div>
                    </div>
                    <div class="ipt-item" @click="isInput = 3">
                      <div class="input">
                        <input
                          type="text"
                          placeholder=""
                          name="email"
                          id="email"
                          class="color"
                          v-model="user.mail"
                        />
                        <label
                          for=""
                          class="nametit information"
                          :class="{ active: isInput == 3 }"
                          >邮箱</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-w textare-item">
                  <div class="textarea" @click="isInput = 7">
                    <textarea
                      name=""
                      cols="30"
                      placeholder=""
                      id="describe"
                      class="color"
                      v-model="user.renewalDesc"
                    ></textarea>
                    <label
                      for=""
                      class="nametit information"
                      :class="{ active: isInput == 7 }"
                      >需求描述</label
                    >
                  </div>
                </div>
                <div class="full-w">
                  <div class="full-w-w">
                      <div class="check">
                      <input type="checkbox" id="xy" />
                      <label for="xy"
                        >我愿意接收道可维斯发来的行业最新动态的简讯</label
                      >
                    </div>
                    <div class="check">
                      <input type="checkbox" id="privicy" name="privicy" />
                      <label for="privicy"
                        >选中此框，即表示我已阅读并同意《<a
                          href="https://cloud.kingdee.com/passport/#/policy-agree/privacy-policy"
                          style="color: #2386ed"
                          >道可维斯中国隐私政策</a
                        >》</label
                      >
                    </div>
                  </div>
                </div>
                <div class="full-w">
                  <button type="submit" class="btn-blue-gradient btn-submit" @click="submitRegister">
                    提交注册
                  </button>
                </div>
              </form> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="qrCodeDialogVisible"
      :width="offsetWidth>768 ? '30%':'50%'"
      @opened="qrCodeCreate"
      :before-close="qrCodeHandleClose"
      @close="qrCodeHandleClose"
    >
      <div>
        <!-- <div id="qrCode" ref="qrCode"></div> -->
         <div id="login_container" style="text-align: center"></div>
      </div>
      <!-- <span slot="footer" class="dialog-footer"> </span> -->
    </el-dialog>
    
    <el-dialog
      title="请先关注公众号"
      :visible.sync="officialAccountDialogVisible"
      :width="offsetWidth>768 ? '20%':'50%'"
      center
    >
      <div class="officialAccount">
        <div class="officialAccount_code">
          <img
            src="@/assets/mobile/v0.1/images/mobil_product_xc/code.png"
            alt=""
            style="width: 80%; height: 80%"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="openScanCodeLogin" plain
          >我已关注</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import { regEx } from "@/utils/regex";
export default {
  name: "Register",
  data() {
    //姓名验证
    var validateName = (rule, value, callback) => {
      if (value === "") {
        this.tips.nameTip = "请输入姓名";
        callback(new Error(" "));
      } else {
        this.tips.nameTip = "姓名";
        callback();
      }
    };
    //手机号验证
    var validatePhoneNumber = (rule, value, callback) => {
      if (value === "") {
        this.tips.phoneTip = "请输入手机号";
        callback(new Error(" "));
      } else if (!regEx.mobile.test(value)) {
        this.tips.phoneTip = "手机号格式不正确";
        callback(new Error(" "));
      } else {
        this.tips.phoneTip = "手机号";
        callback();
      }
    };
    //验证码验证
    var checkCode = (rule, value, callback) => {
      if (value === "") {
        this.tips.codeTip = "请输入验证码";
        callback(new Error(" "));
      } else if (value.length != 6) {
        this.tips.codeTip = "验证码格式不正确";
        callback(new Error(" "));
      } else {
        this.tips.codeTip = "验证码";
        callback();
      }
    };
    //邮箱验证
    var checkMail = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (!regEx.email.test(value)) {
        this.tips.mailTip = "邮箱格式不正确";
        callback(new Error(" "));
      } else {
        this.tips.mailTip = "邮箱";
        callback();
      }
    };
    //问题验证
    var checkQuestion = (rule, value, callback) => {
      if (value === "") {
        this.tips.renewalDesc = "请输入您的问题或意见";
        callback(new Error(" "));
      } else {
        this.tips.renewalDesc = "需求描述";
        callback();
      }
    };

    return {
      userMsg: {
        type: "请选择",
      },
      isInput: 0,
      userInfo: {
        type: "1",
      },
      // user: {
      //   name: "", //姓名
      //   phoneNumber: "", //手机号
      //   code: "", //验证码
      //   company: "1", //公司名称
      //   mail: "1", //邮箱
      //   renewalDesc: "", //续期描述
      //   radio:"",//选择客户或伙伴
      // },
      user: {
        name: "", //姓名
        phoneNumber: "", //手机号
        code: "", //验证码
        company: "", //公司名称
        mail: "", //邮箱
        renewalDesc: "", //续期描述
        radio: "",//选择客户或伙伴
      },
      // 验证码是否CD
      codeCd: false,
      // CD长度
      long: 60,
      rules: {
        name: [{ validator: validateName, trigger: "blur", require: "true" }],
        phoneNumber: [
          { validator: validatePhoneNumber, trigger: "blur", require: "true" },
        ],
        code: [{ validator: checkCode, trigger: "blur", require: "true" }],
        mail: [{ validator: checkMail, trigger: "blur", require: "true" }],
      },
      tips: {
        nameTip: "姓名",
        phoneTip: "手机号",
        codeTip: "验证码",
        companyTip: "公司名称",
        mailTip: "邮箱",
        renewalDesc: "需求描述",
      },
      dynamic: false,
      policy: false,
      type: "",//线索类型
      hasName: false,//是否有名字
      hasPhone: false,//是否有电话
      needCode: false,//是否有电话和需要填写验证码
      hasMail: false,//是否有邮箱
      hasBussiness: false,//是否有公司
      needRadio: false,//是否已经选择合作伙伴
      DemandType:'',
      qrCodeDialogVisible:false,
      officialAccountDialogVisible: false, //公众号二维码
      offsetWidth: document.body.offsetWidth, //页面宽度
      systemPingtai:true
    };
  },
  created() {
    this.type = this.$route.query.type ? this.$route.query.type : ""
    this.DemandType = this.$route.query.DemandType ? this.$route.query.DemandType : ""
    console.log(this.type)
    // if (!sessionStorage.getItem('userInfo') || sessionStorage.getItem('userInfo') == '') {
    //   this.$message({
    //     message: '暂未登录，请先登录',
    //     type: 'warning',
    //     duration: 1000,
    //     onClose: () => {
    //       this.$router.go('/guidepage')
    //     }
    //   })
    // }
    let system = {};
    system.pingtai =
      /(Win32|Win16|WinCE|Mac68K|MacIntel|MacIntel|MacPPC|Linux mips64)/i.test(
        navigator.platform
      );
    if (system.pingtai) {
      this.systemPingtai=true
    }else{
      this.systemPingtai=false
      const userAgent = navigator.userAgent.toLowerCase();
      //是否微信浏览器
      if (userAgent.includes('micromessenger')) {//是
        this.getCode();
      }
      
    }
  },
  mounted() {
    if (sessionStorage.getItem('userDetialInfo')) {
      let detial = sessionStorage.getItem('userDetialInfo')
      detial = JSON.parse(detial)
      console.log(detial)
      if (detial.Name != "") {
        this.hasName = true
        setTimeout(() => {
          let a = this.$refs.name1
          // console.log(1)
          // console.log(a)
          a.value = detial.Name
          // a.disabled = false
          this.user['name'] = detial.Name
        }, 10)
      }
      if (detial.PhoneNum != "" && detial.PhoneNum != null) {
        this.hasPhone = true
        setTimeout(() => {
          let b = this.$refs.phoneNum1
          // b.disabled = false
          b.value = detial.PhoneNum
          this.user['phoneNumber'] = detial.PhoneNum
        }, 10)

      }
      if (detial.IsVertify == 1) {
        this.needCode = true
      }
      if (detial.Bussiness != "" && detial.Bussiness != null) {
        this.hasBussiness = true
        setTimeout(() => {
          let c = this.$refs.bussiness1
          // console.log(1)
          // console.log(a)
          c.value = detial.Bussiness
          // a.disabled = false
          this.user['company'] = detial.Bussiness
        }, 10)
      }

      if (detial.Email != "" && detial.Email != null) {
        this.hasMail = true
        setTimeout(() => {
          let d = this.$refs.mail1
          // console.log(1)
          // console.log(a)
          d.value = detial.Email
          // a.disabled = false
          this.user['mail'] = detial.Email
        }, 10)
      }
      if (detial.PartnerType != "" && detial.PartnerType != null) {
        this.needRadio = true;
        if (detial.PartnerType == "客户") {
          this.user.radio = "1";
        }
        if (detial.PartnerType == "伙伴") {
          this.user.radio = "2";
        }
      }
    }
    // else{
    //   alert('数据不完全')
    //   this.$router.go(-1)
    // }
  },
  watch: {
    $route(to, from) {
      let code = this.$route.query.code;
      if (code) {
        console.log(1111,code);
        // this.officialAccountDialogVisible = false
        // this.qrCodeDialogVisible = false;
        this.LoginByScanCode(code);
      }
    },
  },
  methods: {
    getCode() {
      // 非静默授权，第一次有弹框
      this.code = "";
      this.appid = "wx27e60c547a5ab724"; //公众号里有自己查，不会找的查一下百度
      this.code = this.getUrlCode().code; // 截取code

      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          encodeURIComponent(window.location.href) +
          "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
        sessionStorage.setItem("code", this.code);
      } 
    },
    
    // 截取url中的code方法
    getUrlCode() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest);
      return theRequest;
    },
    qidai() {
      // this.$message({
      //   message:"敬请期待！",
      //   type:"warning"
      // })
      alert("敬请期待！")
    },
    openNewWindow(){
      // const { href } = this.$router.resolve({
      //   path: "/views/privacy.html",
      // });
      // window.open("http://docuvix.com/views/privacy.html", "_blank");
      window.open("http://localhost:8080/views/privacy.html", "_blank");
    },
    //点击输入框
    clickInput(num) {
      this.isInput = num;
      switch (num) {
        case 1:
          this.tips.nameTip = "姓名";
          break;
        case 2:
          this.tips.phoneTip = "手机号";
          break;
        case 3:
          this.tips.codeTip = "验证码";
          break;
        case 4:
          this.tips.companyTip = "公司名称";
          break;
        case 5:
          this.tips.mailTip = "邮箱";
          break;
        case 6:
          this.tips.renewalDesc = "需求描述";
          break;
      }
    },
    // 获取手机验证码
    handleCaptcha(form) {
      console.log(this.codeCd);
      this.$refs[form].validateField("phoneNumber", async (valid) => {
        if (!valid) {
          // 获取验证码
          let param = {
            mobile: this.user.phoneNumber
          }
          this.$api.beyoudsky.SendVerify(param).then(res => {
            if (res.data.errorCode == "-99") {
              alert(res.data.errorText)
            }
          })
          // 开启计时
          this.codeCd = true;
          const timer = setInterval(() => {
            this.long--;
            if (this.long <= 0) {
              this.long = 60;
              this.codeCd = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //打开微信扫码
    openScanCodeLogin(){
      this.qrCodeDialogVisible = true;
    },
    //打开二维码窗口
    qrCodeCreate() {
      let url = "http://docuvix.com/#/guidepage"
    //  let url
    //   if (this.systemPingtai) {
    //     url = "http://docuvix.com/#/guidepage"
    //   }else{
    //     url = "http://http://192.168.110.84:8080/#/"+this.$router.currentRoute.fullPath
    //   }
      this.$nextTick(()=>{
      var obj = new WxLogin({
        self_redirect:false,
        id:"login_container",
        appid: "wx8e2d0530d1ba539c",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(url),
        state: "login,0",
        style: "black",
        href: ""
      });
      console.log(obj);
      })
    },

    //dialog弹窗关闭回调
    qrCodeHandleClose() {
      this.qrCodeDialogVisible = false;
    },
    LoginByScanCode(code) {
      // this.codeShow1 = false;
      let param = {
        Code: code,
      };
      this.$api.beyoudsky.LoginByScanCode(param).then((res) => {
        console.log(res);
        if (res.data.errorCode == "1") {
          console.log(res);
          sessionStorage.setItem("userDetialInfo", res.data.data);
          let data = JSON.parse(res.data.data);
          let userinfo = {
            openid: data.WeChatID ? data.WeChatID : "",
            nickname: data.NickName ? data.NickName : "",
          };
          sessionStorage.setItem("userInfo", JSON.stringify(userinfo));
          this.$message({
              message: "登录成功",
              type: "success",
          });
          // this.codeShow = false;
          this.officialAccountDialogVisible=false
          this.qrCodeDialogVisible = false;
          // this.nickname = JSON.parse(sessionStorage.getItem("userInfo")).nickname;
        } else {
          // alert(`${res.data.errorText}`);
          console.log(`${res.data.errorText}`);
        }
      });
    },
    //提交注册
    // TODO：
    submitRegister(formName) {
      // console.log(this.dynamic);
      // console.log(this.policy)
      // console.log(this.user.radio)
      if (this.user.radio == '') {
        alert('客户伙伴类型必须选择')
        return
      }
      // let userInfo={}
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(userInfo)
      if (userInfo == null) {
        this.officialAccountDialogVisible = true
      }else{
      // console.log('++++', userInfo);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            Email: this.user.mail, //邮箱
            WeChatID: userInfo.openid ? userInfo.openid : "", //用户id
            Bussiness: this.user.company, //公司名称
            Name: this.user.name, //姓名（需更改）
            PhoneNum: this.user.phoneNumber, //手机号
            Code: this.user.code, //验证码（需更改）
            Demand: this.user.renewalDesc, //续期描述（需更改）
            PartnerType: this.user.radio == "1" ? "客户" : "伙伴",
            nickName:userInfo.nickname ? userInfo.nickname : "", //用户昵称
          };
          // console.log(param)

          this.$api.beyoudsky.UpdateWXUserInfo(param).then((res) => {
            if (res.data.errorCode == "1") {
              alert("提交成功！");
              console.log(this.user)
              // 提交需求/问答记录
              let addMsgParam = new FormData();
              let addMsgParamTmp = {
                Content: this.user.renewalDesc, //需求/问题内容 
                WeChatID: userInfo.openid ? userInfo.openid : "", //用户id/openid
                Type: this.type ? this.type : "",//类型
                // this.user.radio == "1" ? "客户" : "伙伴", 
                name: this.user.name?this.user.name:'', //姓名
                phoneNumber: this.user.phoneNumber?this.user.phoneNumber:'', //手机号
                nickName: userInfo.nickname ? userInfo.nickname : "", //昵称
                // DemandType:this.type == "营销" ? "问题" : "需求"
                DemandType:this.DemandType,
              }
              console.log(addMsgParamTmp);
              addMsgParam.append('InputJson', JSON.stringify(addMsgParamTmp))
              this.$api.beyoudsky.AddMsgHistory(addMsgParam).then((res) => {
                if (res.data.errorCode == "1") {
                  console.log('需求/问答记录添加成功！');
                  // alert("需求/问答记录添加成功！");
                  this.$router.push('/')
                }if (res.data.errorCode == "-99") {
                  alert(res.data.errorText)
                }
              })


              //新增线索调新增线索接口
              
              // let paramQues1 = new FormData();
              // let param1 = {
              //   PhoneNum: this.user.phoneNumber ? this.user.phoneNumber : "",
              //   Name: this.user.name ? this.user.name : "",
              //   NickName: userInfo.nickname ? userInfo.nickname : "",
              //   ClueType: this.type ? this.type : "",
              // }
              // paramQues1.append("InputJson", JSON.stringify(param1));



              // this.$refs[formName].resetFields();

              
              
              // 新增线索并返回首页
              // this.SaveClue(paramQues1);
            } else {
              console.log('error:', res);
              alert(`${res.data.errorText}`);
            }
          });
        } else {
          alert(`请检查姓名、手机号、验证码是否填写！`);
          console.log("error submit!!");
          return false;
        }
      });
    }
    },
    //新增线索
    // SaveClue(data) {
    //   console.log(data)
    //   this.$api.beyoudsky.SaveClue(data).then(res => {
    //     if (res.data.errorCode == "1") {
    //       // alert('新增线索成功')
    //       this.$router.push('/')
    //     }
    //     if (res.data.errorCode == "-99") {
    //       alert(res.data.errorText)
    //     }
    //   })
    // },
    selectType(val) {
      this.userMsg.type = val;
      this.isInput = 0;
      console.log();
    },
    submitForm() {
      this.$refs["userInfo"].validate((valid) => {
        // console.log(valid);
        if (valid) {
          // alert("注册成功");
          this.$router.push({
            path: "/personalinfo",
            query: { type: this.userInfo.type },
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "./assets/mobile/v0.1/css/new-common.css";
@import "./assets/mobile/v0.1/css/iconfont.css";
@import "./assets/mobile/v0.1/css/animate.min.css";
@import "./assets/mobile/v0.1/css/swiper.min.css";
@import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
@import "./assets/mobile/v02/css/mo_register_inquiry.css";
@import "./assets/mobile/v0.1/css/header.css";
@import "./assets/mobile/v0.1/css/footer.css";

.isBlock {
  display: block;
  // transition: all 0.5s;
}

.window-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.window-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.window-liuzi {
  background-color: #fff;
  max-height: 100vh;
  overflow-y: auto;
  width: 1300px;
}

.window-liuzi .close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  /* background: url(../images/liuzi/close.png) 0 0/cover no-repeat; */
  transition: all 0.5s;
  cursor: pointer;
}

.window-liuzi .close:hover {
  transform: rotate(-180deg);
}

.window-liuzi::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.window-liuzi::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

.window-liuzi::-webkit-scrollbar-thumb {
  background-color: #999;
}

@media screen and (min-device-width: 1024px){
  .wrapper{
    height: 0;
    overflow: hidden;
  }
  // .window-liuzi::-webkit-scrollbar-track {
  //   display: none;
  // }

  // .window-liuzi::-webkit-scrollbar {
  //   display: none;
  // }

  // .window-liuzi::-webkit-scrollbar-thumb {
  //   // display: none;
  // }
}

.formlists #privicy-error {
  color: red;
  font-size: 14px;
}

.window-liuzi .hd {
  // background: url(../images/liuzi/bg_liuzi.png) center center/cover no-repeat;
  padding: 0.6rem 0 120px;
  text-align: center;
}

.window-liuzi .hd h2 {
  color: #006edc;
  font-weight: bold;
  margin-bottom: 34px;
}

.window-liuzi .good-list {
  width: 91.11111%;
  margin: 0 auto 0;
}

.window-liuzi .good-list li {
  width: 25%;
  float: left;
}

.window-liuzi .good-list li p {
  color: #212121;
  margin-top: 10px;
  font-weight: bold;
}

.window-liuzi .good-list li .icon {
  width: 93px;
  margin: 0 auto;
}

.window-liuzi .good-list li .icon img {
  width: 100%;
}

.window-liuzi .bd {
  width: 91.11111%;
  margin: -80px auto 0;
  border-radius: 15px;
  background-color: #fff;
  padding: 50px 20px 66px 5%;
  box-shadow: 0 0 16px rgba(198, 210, 216, 0.1);
}

.window-liuzi .bd h3 {
  font-weight: bold;
  margin-bottom: 25px;
}

.window-liuzi .left {
  float: left;
  width: 72.25914%;
  padding-right: 9.1954%;
  border-right: 1px solid #e6e6e6;
}

.window-liuzi .right {
  float: right;
  width: 27.24252%;
  padding-left: 4%;
}

.window-liuzi .right a {
  width: 131px;
  height: 44px;
  line-height: 44px;
  display: block;
  font-size: 16px;
  margin-bottom: 40px;
}

.window-liuzi .right p {
  color: #353535;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.window-liuzi .right p b {
  color: #0071dc;
}

.formlists .shd {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.formlists .shd>div {
  width: 47.22222%;
}

.formlists .ipt-item {
  position: relative;
  margin-bottom: 12px;
}

.formlists .input {
  position: relative;
}

.formlists .input input {
  height: 53px;
  border: 1px solid #e9e9e9;
  width: 100%;
  font-size: 16px;
  padding: 10px 22px;
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 0.5s;
  position: relative;
  z-index: 2;
  background-color: transparent !important;
}

.formlists .input input:focus {
  border-color: #006edc;
}

.formlists .input input::-webkit-input-placeholder {
  color: #717171;
  opacity: 1;
}

.formlists .input input:-internal-autofill-selected {
  background-color: transparent !important;
}

.formlists .input .Required {
  color: red;
  font-weight: bold;
  font-size: 16px;
  vertical-align: middle;
}

.formlists .input label.error {
  color: red;
}

.formlists .input .qh {
  position: absolute;
  left: 22px;
  top: 0;
  line-height: 53px;
  opacity: 0;
  z-index: 3;
}

.formlists .input .qh.show {
  opacity: 1;
}

.formlists .input input[name="phone"] {
  padding-left: 50px;
}

.formlists .input-select {
  cursor: pointer;
}

.formlists .input-select::after {
  content: "\e683";
  transform: translateY(-1px) rotate(90deg);
  transition: all 0.4s ease;
  font-family: "iconfont";
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 20px;
  top: 12px;
  color: #999;
  font-size: 20px;
  transition: all 0.5s;
}

.formlists .input-select span.val {
  height: 53px;
  line-height: 33px;
  display: block;
  border: 1px solid #e9e9e9;
  color: #717171;
  width: 100%;
  font-size: 16px;
  padding: 10px 22px;
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 0.5s;
  position: relative;
  z-index: 2;
  background-color: transparent;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
}

.formlists .input-select span.val>div {
  opacity: 1;
}

.formlists .input-select span.val.focus {
  border-color: #006edc;
}

.formlists .input-select span.val.int>div {
  opacity: 0;
}

.formlists .input-select ul {
  display: none;
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.formlists .input-select ul li {
  padding: 10px 22px;
  transition: all 0.5s;
  font-size: 16px;
  cursor: pointer;
}

.formlists .input-select ul li:hover {
  background-color: #eee;
}

.formlists .input-select.active::after {
  transform: translateY(-1px) rotate(-90deg);
}

.formlists label.nametit {
  position: absolute;
  left: 22px;
  display: inline-block;
  font-size: 16px;
  color: #717171;
  top: 18px;
  transition: all 0.5s;
  z-index: 1;
  line-height: 1;
}

.formlists label.nametit.active {
  transform: translate(-5px, 0);
  top: -8px;
  background-color: #fff;
  padding: 0 5px;
  z-index: 3;
  color: #006edc;
  font-size: 14px;
}

.formlists .input-yzm {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}

.formlists .input-yzm input {
  width: 100% !important;
}

.formlists .getcode {
  width: 41.17647%;
  height: 53px;
  border-color: #e9e9e9;
  background-color: #ecf0f5;
  color: #717171;
  font-size: 16px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  line-height: 53px;
  cursor: pointer;
  transition: all 0.5s;
}

.formlists .getcode:hover {
  background-color: #ccc;
}

.formlists .full-w {
  width: 100%;
  margin-bottom: 12px;

  button {
    // width: 180px;
    // width: 90%;
    // height: 48px;
    // font-weight: lighter;
    border-radius: 4px;
    font-weight: 500;
    height: 53px;
  }

  .full-w-w {
    width: 40%;
    margin: 0 auto;
    text-align: left;
  }
}

.formlists .textarea {
  position: relative;
}

.formlists .textarea textarea {
  width: 100%;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  font-size: 16px;
  padding: 15px 22px;
  transition: all 0.5s;
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.formlists .textarea textarea::-webkit-input-placeholder {
  color: #717171;
  opacity: 1;
}

.formlists .textarea textarea:focus {
  border-color: #006edc;
}

.formlists .check {
  position: relative;
  color: #717171;
  font-size: 16px;
}

.formlists .check input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.formlists .check input[type="checkbox"]+label:before {
  content: "";
  border: 1px solid #e9e9e9;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  box-sizing: border-box;
  margin-right: 13px;
}

.formlists .check input[type="checkbox"]:checked+label:before {
  // background: url(../images/liuzi/icon_gouxuan.svg) center center/90% no-repeat;
  background-color: #1780e7;
  border-color: transparent;
}

.formlists .check a {
  color: #717171;
}

.formlists .check a:hover {
  color: #006edc;
}

.formlists .btn-submit {
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-weight: lighter;
  margin-top: 20px;
  border: 1px solid #e9e9e9;
}

.btn-blue-gradient {
  // text-align: center;
  // border-radius: 5px;
  // border: 1px solid #fff;
  color: #fff;
  // box-sizing: content-box;
  // background-image: linear-gradient(135deg, #0068dd, #00aeee);
  // background-color: #0360c3;
  // border: none;
}

.btn-blue-gradient:after {
  transform: translateY(-1px);
  // content: "";
  // transition: all 0.4s ease;
  // font-family: "iconfont";
  // display: inline-block;
  // vertical-align: middle;
}

.btn-blue-gradient:hover:after {
  content: "";
  margin-left: 0px;
}

.arcode {
  width: 175px;
  margin-top: 20px;
}

.arcode p {
  margin-bottom: 5px;
}

.arcode img {
  width: 100%;
}

@media only screen and (max-width: 1210px) {
  .window-liuzi {
    width: 100%;
    padding: 0;
  }

  .formlists .input-select ul li {
    font-size: 14px;
    padding: 8px 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .window-liuzi .good-list li .icon {
    width: 70px;
  }

  .formlists .btn-submit {
    width: 100%;
    height: 48px;
    font-size: 16px;
    margin-top: 10px;
  }

  .window-liuzi .left {
    padding-right: 30px;
  }

  .formlists .input input {
    font-size: 14px;
  }

  .formlists .getcode {
    font-size: 14px;
  }

  .formlists .check {
    font-size: 14px;
  }

  .formlists .input-select ul li {
    padding: 6px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .window-liuzi .good-list li {
    width: 50%;
    margin-bottom: 20px;
  }

  .window-liuzi .left {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }

  .window-liuzi .right {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
    position: relative;
  }

  .window-liuzi .bd {
    width: calc(100% - 40px);
    padding: 20px;
  }

  .window-liuzi .right a {
    position: absolute;
    right: 0;
    top: -10px;
  }

  .window-liuzi {
    max-height: 100vh;
  }

  .formlists .btn-submit {
    margin: 0 auto;
  }

  .window-liuzi .right {
    margin-bottom: 40px;
  }

}

@media only screen and (max-width: 640px) {
  .formlists .check {
    text-align: left;
  }

  .formlists .ipt-item {
    width: 100%;
  }

  .window-liuzi .bd {
    width: 100%;
  }

  .window-liuzi .right a {
    margin: 0 0 20px;
  }

  .formlists .shd>div {
    width: 100%;
  }

  .formlists .input-select::after {
    right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .window-liuzi .good-list li .icon {
    width: 70px;
  }

  .formlists .btn-submit {
    width: 100%;
    height: 48px;
    font-size: 16px;
    margin-top: 10px;
  }

  .window-liuzi .left {
    padding-right: 30px;
  }

  .formlists .input input {
    font-size: 14px;
  }

  .formlists .getcode {
    font-size: 14px;
  }

  .formlists .check {
    font-size: 14px;
  }

  .formlists .input-select ul li {
    padding: 6px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .window-liuzi .good-list li {
    width: 50%;
    margin-bottom: 20px;
  }

  .window-liuzi .left {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }

  .window-liuzi .right {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
    position: relative;
  }

  .window-liuzi .bd {
    width: calc(100% - 40px);
    padding: 20px;
  }

  .window-liuzi .right a {
    position: absolute;
    right: 0;
    top: -10px;
  }

  .window-liuzi {
    max-height: 100vh;
  }

  .formlists .btn-submit {
    margin: 0 auto;
  }

  .window-liuzi .right {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .formlists .check {
    text-align: left;
  }

  .formlists .ipt-item {
    width: 100%;
  }

  .window-liuzi .bd {
    width: 100%;
  }

  .window-liuzi .right a {
    margin: 0 0 20px;
  }

  .formlists .shd>div {
    width: 100%;
  }

  .formlists .input-select::after {
    right: 10px;
  }
}

@media only screen and (min-width: 1024px) {

  /* 注册 */
  .window-liuzi {
    width: 100% !important;
    height: 100vh;
  }

  .reginfo-box {
    width: 60% !important;
  }

  .window-liuzi .left {
    width: 100% !important;
    border-right: 0px !important;

    .lt {
      .ipt-item {
        width: 100% !important;
        margin-top: 15px;

      }

    }

    .rt {
      .ipt-item {
        width: 100%;
        margin-top: 20px;
      }
    }

  }

  .formlists .input input {
    width: 100% !important;
  }

  .formlists .btn-submit {
    width: 30% !important;
  }

  .full-w {
    text-align: center;
    // display: flex;
    justify-content: center;

    .full-w-w {
      width: 90% !important;
    }
  }

  .formlists .shd>div {
    width: 99%;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide {
    width: 49% !important;
  }
  
}


.formlists .input .qh1 {
  opacity: 1;
}

.form .el-form-item__error {
  margin-bottom: 15px !important;
  margin-top: -11px !important;
}

// .formlists .input-yzm input {
//   width: 70% !important;
// }
.Required1 {
  color: #f56c6c;
}

.form .codeBtn {
  height: 53px;
  width: 90%;
  float: right;
  // color: #909399;
  // border-color: #f5f7fa;
  // background-color: #f5f7fa;
  color: white;
  border-color: #6ab0f6;
  background-color: #6ab0f6;
}

.form .codeBtnClick {
  color: white;
  border-color: #8f9092;
  background-color: #8f9092;
}

.form .codeBtnClick:hover {
  color: #909399;
  border-color: #f5f7fa;
  background-color: #f5f7fa;
}

.el-dialog__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    display: flex;
    align-items: baseline;
    justify-content: center;
    /deep/.el-dialog__body {
      // height: 30vh !important;
      .msg {
        margin-bottom: 20px;
      }
      .tip {
        margin-bottom: 20px;
      }
    }
    /deep/.el-dialog__header{
        height: 60px !important;
        line-height: 60px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      /deep/.el-dialog__footer{
        height: 60px !important;

        .el-button{
          width: 100px;
          height: 35px;
          border-radius: 5px;
        }
      }
}

.officialAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_code {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
  
export const caseDetails = [
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg: require("@/assets/images/shouyepc.jpg"),
            logo: "https://www.kingdee.com/dsfile-static/temp/logo_0166_德施曼_1641882261727.jpg",
            companyName: "德施曼",
            tags: [
                {
                    tag: "制造",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "高成长型企业",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "用科技让生活更简单",
            quotation: "智能锁行业领导品牌德施曼在高速发展中选择与金蝶合作，选择金蝶作为德施曼信息化战略的基石，项目方案切实可行且成功落地，满足了德施曼多元化的业务需求，实现业财税、产供销协同一体化，为德施曼高速发展提供了强有力的保障，同时也感谢项目组专业、积极的支持配合，希望尽快推进二期项目合作！",
            leaderName: "——袁良军 财务总监",
            data: [
                {
                    describe: "财务自动化",
                    percent: "95%"
                },
                {
                    describe: "电商对账提升",
                    percent: "40%"
                },
                {
                    describe: "生产效率提升",
                    percent: "20%"
                }
            ]
        },
        Overview: [
            "德施曼机电（中国）有限公司，是一家专注于智能家居及安防领域的企业，产品涉及智能猫眼、智能摄像头、智能手环、智能保险箱等，涵盖以智能门锁为核心的智能家居产业链，智能锁行业龙头企业，入选中国物联网企业50强的唯一智能锁企业。连续3年，京东/天猫平台销量遥遥领先。",
            "德施曼的愿景是让每一个家庭用上智能锁，成为最受信赖的品牌。专业、差异化的产品，助力德施曼在中国家居安防领域取得多项成绩。公司建立了集研发、生产、销售、服务为一体的国际化的高科技家居安防基地，并在全国设立了近百个集销售和服务为一体的分支机构，随着业务规模的高速发展，渠道、业务模式、生态链的协同、成本精细化核算变得越来越重要。",
            "匹配发展需要，德施曼急需一个业财税一体、产供销一体的数字化信息系统来支撑企业，打通各业务板块的壁垒，全面整合业务、财务数据，构建数字化管理平台，实现智慧管理分析与决策。"
        ],
        Challenges: {
            showFlag:true,
            summary: ["德施曼作为中国智能锁龙头企业，企业管理面临着重大挑战，原有的信息系统已不能满足其业务发展的需要，业务的创新发展促使企业要进行数字化转型。"],
            summaryPic: "",
            parts: [
                {
                    title: "信息化系统之间孤立，数据未有效连通",
                    imgSrc: "",
                    content: ["德施曼原使用K/3 WISE系统，与德施曼的其它系统没有实现业务信息的充分共享，未形成统一的数据标准。内部小嘀电商系统与K/3 WISE只做了简单对接，业务连贯性、追溯性、对账等方面存在一定断层以及不匹配的情况。"]
                },
                {
                    title: "业财未打通，业务处理效率低",
                    imgSrc: "",
                    content: ["德施曼由于业务的复杂性，以及系统建设的滞后，造成大量业务与财务脱节，一些基础财务核算管理业务也需要花费大量时间和精力进行人工处理。如在系统外进行付款申请，由于不与采购订单关联，后续在花费大量的人工进行核对。从而造成财务数据滞后、准确度低。进而内部管理报表也需要耗费额外人力统计分析。"]
                },
                {
                    title: "多元化销售业务，订单量大，收入对帐确认困难",
                    imgSrc: "",
                    content: ["德施曼拥有多种销售渠道和模式。淘宝、天猫等各大电商平台日单量最高达10万，在工程行业也有超2000个楼盘，需要按照智能锁的“安装”时点进行收入确认，并且销售收入确认还与客户金税开票相匹配；同时还有经销商、OEM等销售渠道。业务种类多，数据量大，导致对账困难。"]
                },
                {
                    title: "产供销计划协同不畅，成本核算不精细",
                    imgSrc: "",
                    content: ["由于业务系统的数据并不互通，在产供销业务协同时，需耗费大量的人工和时间进行数据的获取，核准和转换，现在线下通过EXCEL来实现的计划预测与追踪表，数据量大、计算复杂，无法实现明细物料的预测和计划。同时费用分摊标准粗略，成本督导、考核、改善的规程不完善，难以做到精准核算成本。"]
                },
                {
                    title: "上下游链接不足，业务协同效率低",
                    imgSrc: "",
                    content: ["德施曼以往依靠邮箱等线下方式与供应商进行互动，存在信息滞后，以及与供应商扯皮的情况，也很难掌握供应商的送货进度情况，不利于供应商备货及信息后续核查，同时存在供应商开票与收货不匹配的情况，发票勾稽工作量大，导致与供应商对账花费很多精力。"]
                }
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["在确立了信息化战略：实现集团化、精益化，实现创新驱动、产业协同和精准管理之后，德施曼希望从流程管理和数据中心两方面拉通业务领域，实现流程规范化，流程效率提升、以及流程节点及系统固化，数据资产的管理和应用，最终实现智慧运营，支撑企业创新发展。"],
            summaryPic: "https://www.kingdee.com/dsfile-static/jeditor/12_1642067162447.png",
            parts: [
                {
                    title: "消除“信息孤岛”，实现六个统一、五个规范、四个体系、三个一体化",
                    imgSrc: "",
                    content: ["德施曼借助金金蝶云·星空实现搭建统一的数字化运营平台，进行基础管理全面梳理和优化，统一了物料主数据、客商数据、BOM、会计科目表、核算与管控体系、数据标准化，同时规范了端到端流程、业务操作规则、管理制度、操作标准、绩效考核，并且构建了业务架构体系、运营管控体系、系统架构体系、IT运维与安全体系，从而搭建了业务财务税务一体化、产供销一体化、经营分析一体化的数字化平台。"]
                },
                {
                    title: "业财税一体化，提高数据及时性，环环相扣，高效合规",
                    imgSrc: "",
                    content: ["项目实现与业务系统全面打通，规范业务操作，同时与金税系统直连，在线查验发票真伪，降低税务风险，实现报表自动取数，便于统计分析。整体业务满足IPO合规性要求，同时提高了数据及时性，实现了业务财务税一体化。德施曼总账会计单红燕说到：”这次的系统升级，在原有K/3系统上进行了很大的优化改进，会计再也不用生成凭证后自己修改了，存货核算比以前线下表格计算效率提升了很多，费用和发票业务也减轻了很大的工作量，费用报销、采购付款和银企互联等线上一体化非常好用，既实现与业务打通，同时也满足内控要求，降低经验风险。”"]
                },
                {
                    title: "自动大数据量对帐，打造多元化销售渠道",
                    imgSrc: "",
                    content: [
                        "针对日单量最高达10万电商销售模式，采取大数据处理方式，实现了出库及按照智能锁的“安装”时点进行收入确认，提升单据处理效率。目前已平稳度过“618”等重大节日。",
                        "而对于工程项目销售模式实现了合同-项目-订单的线上全流程跟踪，订单上可以查询工程项目所需的智能锁信息，同时增加里程碑事件，实现里程碑节点管理，提升了工程业务的可追溯性，同时可以查询整个工程业务智能锁安装的各个节点，工程对账单可批量自动生成，提升对账效率。"
                    ]
                },
                {
                    title: "产供销协同，打造精益化生产系统，实现精确核算成本",
                    imgSrc: "",
                    content: [
                        "按照德施曼精益生产的管理要求，通过实施计划管理功能，并对BOM设计及流程进行优化，建立产供销协同的计划和执行体系，方便生产人员快速掌控生产进度，实现产供销平衡。正如计划物控主管邱三凤说到：“将物料按照采购周期分为15天、30天、45天和60天以上四种，并考虑对应的车间制定相应的计划运算方案，通过计划运算产生详细完备的预留关系网络，方便查询与追踪，也极大降低了新员工上手的难度。”&nbsp;",
                        "新系统的JIT功能，实现按车间产能需要进行物料配送，减少库存积压，提升出库效率。生产经理程昭上提到：这次升级至星空，不仅仅是系统功能的完善和丰富，更重要的是生产流程和车间管理制度的确定，在流程及制度设计时以系统单据为依据，将工作与责任划分清晰划分，使生产的效率提高了20%。",
                        "精益化生产系统为成本核算提供数据基础。财务总监袁良军说：“通过整个生产环节的打通，不仅有效地管控了生产过程中的各个环节，提升了业务处理效率，成本核算也从原来的6天缩减到现在的1天“。"
                    ]
                },
                {
                    title: "链接伙伴，与供应商多维度协同",
                    imgSrc: "",
                    content: ["实现了与供应商的订单协同，交货协同，信息协同、库存协同等；供应商可以实时掌握订单进度以及各种消息，并及时进行处理，信息沟通线上留痕，减少与供应商的扯皮现象，业务协同更高效。“德施曼每天与供应商之间存在大量的信息互动，如采购订单、订单变更、交货计划、质量信息、财务对账信息等。供应商协同平台和提料计划的使用，建立了跨组织双赢的业务联动机制，通过双方数据信息的实时共享，实现采购、生产、质量、仓储、财务等部门与供应商的业务协同化管理，提高内外部的信息交互速度，以及时响应市场需求。“采购总监沈伟提到。"]
                }
            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "",
            summaryPic: "",
            parts: [
                {
                    title: "一体化信息运营平台，统一规范管理",
                    imgSrc: "",
                    content: "搭建了统一的信息化平台，统一基础资料、规范业务流程及核算政策，建立供应商协同平台，连接企业上下游形成生态圈，确保服务及时，管理透明。同时实现与小嘀系统、协同办公等系统集成，为德施曼搭建一套一体化运营平台。"
                },
                {
                    title: "业财一体化，实现了高效合规",
                    imgSrc: "",
                    content: "建设业财一体化管控及运营平台，95%凭证自动生成，实现产供销人财物全面协同；同时业务系统与财务系统的信息集成。星空多组织特性，同一数据中心管理以及统一基础资料、业务流程，消除信息孤岛。"
                },
                {
                    title: "销售服务平台，为多元化销售业务赋能提效",
                    imgSrc: "",
                    content: "对多元销售业务进行了整合，新系统日处理单量高达10万单，为多元化的销售业务提供了保障，其中，对电商业务的细分，预计根据智能锁安装时点做收入确认，满足业务合规性；项目销售管理统领下游供应链单据，实时获取销售、生产、发货、收付款数据，整个销售业务可统一查询，便于管理分析与决策；"
                },
                {
                    title: "精益生产，精细化管理降本增效",
                    imgSrc: "",
                    content: "生产过程精细化管理，JIT系统的应用，车间实现智能锁生产过程的精细化管理，对BOM设计进行优化，考虑替代件、跳层件、损耗率等，方便生产人员快速掌控生产进度，管控生产过程。动态齐套拉动，通过物料齐套分析，拉动库存周转，提高库存周转，实现供应商按需提料配送，降低经营成本，同时也实现了成本的透明化、精细化管理。"
                },
                {
                    title: "多组织业务协同，供应链上下游合作共赢",
                    imgSrc: "",
                    content: "与供应商实现询报价、订单、发货、入库、对账协同；实现查看供应商备货进度、供应商库存；打通供应链上下游企业关系，与供应商紧密合作，互信互利，提升供应效率。"
                }
            ]
        },
        TransformationValue: [
            "搭建统一的产供销协同、业财税一体化管理平台，帮助德施曼重构数据驱动的能力，实现了科学高效的成本计算过程，智能化、透明化，精细化使成本计算更准确及时，做到事前预测成本、事中控制成本，事后管理成本，满足IPO内控管理的要求，支撑起德施曼实施战略与业务模式转型。",
            "通过金蝶云·星空平台建设，帮助德施曼实现由ERP向EBC转型，实现连接客户、连接员工、连接供应商，连接数据服务，共同打造生态利益共同体，信息化建设过程中链接50+家供应商到供应协同平台中；通过报销全员应用等，服务全公司90%以上员工，实现战略与业务模式转型。"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg: "http://www.colourlife.com/uploads/img1/20210331/606494fbb8395.jpg",
            logo: "https://gw.colourlife.com/Public/Home/default/images/logo.png",
            companyName: "彩生活",
            tags: [
                {
                    tag: "物业服务",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "社区生活服务集团",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "互联网社区的开拓者",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "会计档案整理工作效率提升",
                    percent: "8倍"
                },
                {
                    describe: "会计档案查询审计效率提升",
                    percent: "3倍"
                },
                {
                    describe: "银行水单与凭证匹配效率提升",
                    percent: "5倍"
                }
            ]
        },
        Overview: [
            "彩生活服务集团是一家集物业服务 、楼宇智能、社区服务为一体的科技型、综合型社区服务企业。目前已经形成以深圳总部为核心辐射至华北、东北、西南、华南、华东、华中等全国战略布局，服务范围已扩展至越南等海外市场。"
        ],
        Challenges: {
            showFlag:true,
            summary: [
                "金税四期建设的顶层设计已完成，形成了征管方式从“收税”到“报税”再到“算税”、征管流程从“上机”到“上网”再到“上云”、征管效能从“经验管税”到“以票管税”再到“以数治税”的智慧税务蓝图！",
                "随着金税四期的建设，企业税务管理将会出现很多新变化。如随着全电发票的推广，企业未来将不依赖税控设备，完全实现发票电子化闭环流转；随着财税信息化与全电发票的并行推进，税务机关将进一步提升发票安全、合规的监管力度。",
                "彩生活服务集团持续探索实践数字化，已搭建财务共享中心，打通业务与财务核算的壁垒。但由于集团规模日益扩大，业务多元创新加速，财务系统中产生的原始凭证数量不计其数，这给企业传统的会计档案管理带来诸多不便，可能存在会计档案的缺失引发的税务风险。"
            ],
            summaryPic: require("@/assets/companyImgs/彩生活业务场景.jpg"),
            parts: [
                {
                    title: "在传统模式中仍存在以下问题：",
                    imgSrc: "",
                    content: []
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["会计档案管理模式在收集、管理、查阅等方面工作量繁重，需要大量人力物力做纸质凭证的整理、打印电子凭证，还需要装订管理等。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["人工归档纸质档案耗时耗力，员工在调阅利用档案时也存在检索困难，查找时间长的问题。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["传统纸质档案在存储和保管上易造成破坏，借阅后也无法及时跟踪闭环，严重威胁着档案的安全，给企业带来税务审计风险。"]
                }
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["电子会计档案系统解决方案，实现企业会计档案收集、整理、归档、保管、借阅等的全流程的数字化管理。"],
            summaryPic: require("@/assets/companyImgs/彩生活解决方案.jpg"),
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: ["通过与OA、EAS系统对接，打通信息壁垒，实现电子会计档案的自动归档，节省人力劳动，免去纸张打印，减少纸张耗材。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["通过业务关系，实现电子档案自动勾稽关联，充分检查电子档案完整性，强化风险管控，提高档案管理的合规性，规避税务审计风险。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "财务相关信息实现了电子化，并相互自动关联，可根据关键字段进行快速检索，也可以通过信息系统关联查询，提高档案的利用效率，极大减少档案的查找时间。"
                    ]
                },

            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "",
            summaryPic: "",
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: "会计档案整理工作效率提升8倍"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "会计档案查询审计效率提升3倍"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "银行水单与凭证匹配效率提升5倍"
                },

            ]
        },
        TransformationValue: [
            "促进跨系统的业财数字化融合",
            "实现企业数字资产的在线保全与利用",
            "实现企业数字化合规能力"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg: "http://www.hansenzy.com/uploadfile/2019/1224/20191224032910894.jpg",
            logo: "http://www.hansenzy.com/statics/hs/img/index_02.png",
            companyName: "汉森制药",
            tags: [
                {
                    tag: "中药研制",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "国内领先的综合性医药上市企业",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "汇聚善力量",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "跨业务档案移交准时率提升",
                    percent: "91%"
                },
                {
                    describe: "跨组织档案查询效率提升",
                    percent: "300%"
                },
                {
                    describe: "档案全生命周期管理工作量下降",
                    percent: "87%"
                }
            ]
        },
        Overview: [
            "汉森制药股份有限公司于2010年在深交所中小板上市，公司以生产中药制剂为主，化学药为辅，拥有药品批准文号155个，其中73个列入国家医保目录，57个列入国家基本药物目录。现有员工1300人，公司注册资金5.032亿元，总资产逾20亿元。"
        ],
        Challenges: {
            showFlag:true,
            summary: [
                "在国家积极推动档案信息发展的背景下，加上药监局GMP监管的要求，医疗制药企业档案数字化是档案管理发展的必然趋势。某制药企业的主要经营内容是药物的研发、生产与销售，而药物从研发到临床试验到再生产面世存在着一系列极为复杂的管理程序，不论在哪个细节出现问题都将直接影响企业的运营与盈利，档案管理数字化迫在眉睫。"
            ],
            summaryPic: require("@/assets/companyImgs/汉森业务场景.jpg"),
            parts: [
                {
                    title: "汉森制药的传统档案管理中，存在以下问题：",
                    imgSrc: "",
                    content: []
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["企业归档文件涵盖经营生产管理、生产管理、行政管理、产品生产、设备仪器、科研研发、会计业务、证券投资等范围。档案数量庞杂，人工归档、管理档案工作量大、效率低，且难以兼顾电子和纸质档案的管理。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["电子档案散落在员工电脑、共享网盘等位置，文件分散管理，档案无法被共享。企业员工通过将电子文档打印成纸质文件进行电子档案集中归档，然而纸质档案归档占用存放空间大、保管成本高，且纸质档案难以快速、精准定位，档案查阅效率低。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["新药研制周期长且过程复杂，一些药品研发材料及生产技术资料分散掌握在相关工作人员手中，没有进行定期收集和归档，一旦发生泄漏或丢失，企业知识产权得不到保护，企业将蒙受重大损失。"]
                }
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["该方案将档案管理流程与数字化相融合，实现企业档案管理的集中化、自动化，提高企业档案管理的效率和安全。 "],
            summaryPic: require("@/assets/companyImgs/汉森解决方案.jpg"),
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: ["通过梳理企业的档案范围，建立符合企业实际的索引规则，将档案管理数智化，打破传统人工管理档案的模式，对纸质文档和电子文档进行统一管理，提高企业档案管理的规范性和合规性。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["与OA、EAS、云之家、生产管理等多个系统集成，实现档案自动化采集、集中归档，大大减少企业员工工作量，释放档案存放的物理空间，降低档案的保管成本。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "建立企业档案的全生命周期管理，档案人员可通过系统对档案进行合理存放以及精确定位查找，业务人员在可控安全的环境内对档案进行查阅与借阅，提高档案管理的利用效率及安全性。"
                    ]
                },

            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "",
            summaryPic: "",
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: "跨业务档案移交准时率提升91%"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "跨组织档案查询效率提升300%"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "档案全生命周期管理工作量下降87%"
                },

            ]
        },
        TransformationValue: [
            "促进跨系统的业财数字化融合",
            "实现企业数字资产的在线保全与利用",
            "实现企业数字化合规能力"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg: "https://www.zsjr.com/templates/dist/img/s9.jpg",
            logo: "https://www.zsjr.com/templates/dist/img/logo.png",
            companyName: "中顺洁柔",
            tags: [
                {
                    tag: "纸业",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "国内头部生活用纸上市企业",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "只在乎您",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "月均出库单量3年增长",
                    percent: "500%"
                },
                {
                    describe: "单笔业务客户签收回执时长缩减",
                    percent: "10倍"
                },
                {
                    describe: "企业收入确认时效性提升",
                    percent: "2.7倍"
                }
            ]
        },
        Overview: [
            "中顺洁柔纸业股份有限公司（简称中顺洁柔）是集研发、生产及销售为一体的多元化集团企业，是国内首批A股上市的主营生活用纸企业。",
            "中顺洁柔分别在广东江门、广东云浮、四川成都、浙江嘉兴、湖北孝感、河北唐山建立了六大绿色生产基地，年产能近百万吨；销售网络辐射华南、云贵广、鲁豫皖、西南、西北、华东、华中、华北、东北和中国香港/中国澳门十大区域，同时产品远销东南亚、东北亚、大洋洲、北美等海外市场。"
        ],
        Challenges: {
            showFlag:true,
            summary: [],
            summaryPic: "",
            parts: [
                {
                    title: "一，国家政策变化",
                    imgSrc: "",
                    content: [
                        "《企业会计准则第14号-收入》的规定。企业应当按照《企业会计准则第14号——收入》（财会〔2017〕22号，以下简称收入准则）第十一条：相关收入应当在客户取得相关商品或服务（以下简称商品）控制权的时点确认。",
                        "解读：企业收入确认从企业方出库改为客户方签收！"
                    ]

                },
                {
                    title: "二，洁柔所受到的影响",
                    imgSrc: "",
                    content: ["2019年每月纸巾出库单近5000笔，财务客户结算、承运商运费结算、销售业绩提成结算的体系需要重新适应，同时也希望能提⾼结算效率。 "]
                },
            ]
        },
        Solution: {
            showFlag:true,
            summary: [],
            summaryPic: "",
            parts: [
                {
                    title: "e企签收产品如何帮助JR企业快速适应新规则？",
                    imgSrc: "",
                    content: []
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["①ERP出库单数据传输e企签收，由e企签收⾃动⽣成电⼦版交货运单。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "②货物装车完成后，仓管根据⾃⼰电脑ERP系统出货单通知司机领货，司机领取仓管打印的纸质版客户出货单，司机用手机扫纸质出货单的二维码，确认二维能识别，并确认在e企签收系统移动端能显示出货单信息。"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "③门卫检查司机手上纸质出货单、车牌号和装车情况，并确认同意放⾏。门卫另外在TMS系统根据车牌号进行出场确认。"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "④经销商收货验收盖章确认纸质版客户出货单，差异数据由司机在e企签收系统⼿机端填写，盖章版纸质客户出货单或者是客户⾃⼰的收货单，由司机实时拍照回传系统。也可以由司机把货单发给物流公司人员，由物流公司人员拍照上传。"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "⑤e企签收系统自动检查客户盖章与备案章是否⼀致"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "⑥e企签收系统把客户实际收货数据和盖章收货单回传ERP"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "⑦企业财务实时核算⼊账客户应收款、承运商运费结算、销售业绩提成 "
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "⑧企业物流监控岗全程后台数据监控 ，物流签收专员监控系统数据，及时跟进签收情况。"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "⑨企业销售在ERP系统查看客户签收结果。"
                    ]
                },
            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "",
            summaryPic: "",
            parts: [
                {
                    title: "e企签收应用效果",
                    imgSrc: "",
                    content: "JR公司的e企签收系统自2020年1月1日正式上线运行，至今有3年2个月。⽬前分别有167个账号在供多人使用，包括司机、仓管、物流监控岗、财务。"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "2022年度⽉平均出库单量已经达到约30000，对比2019年，增长了500%"
                },

            ]
        },
        TransformationValue: [
            "e企签收系统主要实现了签收功能，帮助JR企业提高了签收的效率，根据拍照签收确认收入，不需要等几天纸质回单再确认收入。",
            "JR企业管理层认为最大价值是满足了国家会计制度的要求，实现了收入确认从出库改为签收，实现了及时确认收入。",
            "JR执行层认为最大价值是进行签收工作时能有系统支撑，满足了业务操作的需求，满足了数据统计的需求。"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg: require("@/assets/companyImgs/欧诗漫案例背景图.png"),
            logo: "http://www.osmun.com.cn/images/logo.png?v=3",
            companyName: "欧诗漫",
            tags: [
                {
                    tag: "珍珠护肤品",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "研发生产连锁门店模式",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "始于1967  源于珍珠 专于珍珠",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "发票重复和异常报销拦截率提升",
                    percent: "95%"
                },
                {
                    describe: "员工报销周期缩短",
                    percent: "71%"
                },
                {
                    describe: "财务自动化效率提升",
                    percent: "44%"
                }
            ]
        },
        Overview: [
            "欧诗漫创始于1967年，是全球珍珠产业的领军品牌，国产护肤品的龙头企业。作为美妆行业数字化践行的先锋企业，欧诗漫积极推进行企业信息化建设和数字化运营，加速实施企业数字化战略，助力企业发展全面提速。",
            "欧诗漫在企业财务领域上，以企业信息化为基石，重塑财务管理流程，建立财务共享的管理模式，提高企业内部财务工作的工作效率以及有效控制企业财务成本。但伴随集团业务量及业务范围扩大，欧诗漫对财务核算时效与财务管理数字化水平提出了新要求。"
        ],
        Challenges: {
            showFlag:true,
            summary: [
                "欧诗漫企业财务共享建设的过程中，已采购了一套OA系统，将财务流程数字化，将原本分散、重复、可标准化记账、算账工作，集中式通过线上流程处理 ，提升财务效率。但财务数据的集中处理也衍生出了财务工作量陡增的现象，存在财务工作效率跟不上日益增长的财务管理需求的问题。"
            ],
            summaryPic: require("@/assets/companyImgs/欧诗漫业务场景.jpg"),
            parts: [
                {
                    title: "如何解决问题？在企业费用报销的实际操作中，仍存在以下问题：",
                    imgSrc: "",
                    content: []
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["随着业务范围的扩大，在外员工无法及时报销，领导在外无法通过移动端看到审批流程中的票据，领导盲审增加财务风险。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["报销人填单时，需要手动输入发票金额和发票税号，人工操作准确率较低，需要花费较长的时间填单，降低报销人的填单效率，进而影响报销人其他工作开展的进度。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["财务审核人员需要人工手动对发票进行识别查验，票据审核工作量大，人工操作准确性较低，业务处理速度慢，影响报销流程的推进效率。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["财务管理流程主要是以纸质单据或者原始单据的流转，在过程中难免会出现丢单、损坏等问题。"]
                }
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["协同OA系统通过电子影像集成，结合OCR技术和发票识别查重服务，实现财务全流程电子化，减少人工的重复操作，提高流程准确度，加快流程的推进。"],
            summaryPic: require("@/assets/companyImgs/欧诗漫解决方案移动端.jpg"),
            parts: [
                {
                    title: "",
                    imgSrc: require("@/assets/companyImgs/欧诗漫解决方案pc端.jpg"),
                    content: []
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["增加移动端电子影像系统，支持手机上传票据影像，满足企业人员移动办公过程中的报销需求，在外领导可以通过移动端调阅票据影像，满足领导移动审批的需求。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["发票的自动识别查重功能，避免重复报销的问题，帮助财务人员减少查重困难，提升流程效率。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "引入OCR识别技术，自动回写发票分录，减少人工录入时间，降低人工误差，提高流程的准确率。"
                    ]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "流程审批结束后，通知影像系统进行影像数据的留存、归档，帮财务人员解决纸质数据保管难、查找资料难等问题。"
                    ]
                },

            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "",
            summaryPic: "",
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: "发票重复和异常报销拦截率提升95%"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "员工报销周期缩短71%"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "财务自动化效率提升44%"
                },

            ]
        },
        TransformationValue: [
            "电子影像系统协同OA系统以一体化的系统整合，支撑管理与业务、财务融合，实现费控全过程闭环管理，助力企业实现精细化、合规化、高效化的财务管理，赋能  企业智慧化转型。"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            // backgroundImg: "https://www.tbea.com/resource/cn/home/static/img/sy055.jpg",
            backgroundImg:require("@/assets/companyImgs/沈阳特变案例背景.jpg"),
            // logo: "https://www.tbea.com/resource/cn/home/static/img/sy001.jpg",
            logo: require("@/assets/companyImgs/logo/特变电工logo.png"),
            companyName: "特变电工",
            tags: [
                {
                    tag: "新材料",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "高端铝材国产化替代",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "装备中国 装备世界",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "订单拆图工序平均时耗下降",
                    percent: "3倍"
                },
                {
                    describe: "订单交期准确率提升",
                    percent: "69%"
                },
                {
                    describe: "生产时间缩短",
                    percent: "50.8%"
                }
            ]
        },
        Overview: [
            "特变电工是为全球能源事业提供系统解决方案的服务商，是国家级高新技术企业和中国大型能源装备制造企业，由2万余名员工组成，培育了以能源为基础，“输变电高端制造、新能源、新材料”一高两新国家三大战略性新兴产业，成功构建了特变电工（股票代码600089）、新疆众和（股票代码600888）、新特能源（股票代码HK1799）三家上市公司。",
            "特变电工已发展成为世界输变电行业的排头兵企业，我国多晶硅新材料研制及大型铝电子出口基地，大型太阳能光伏、风电系统集成商，国内拥有多个制造业工业园，海外建有2个基地。变压器年产量达2.6亿kVA，位居世界第一位，光伏EPC总量排名全球第一。特变电工集团综合实力位居世界机械500强第228位、中国机械100强第6位、ENR国际总承包商全球排名第80位，品牌价值逾500亿元，排名中国500最具价值品牌第47位。",
            "随着现代社会科技的发展，在全球激烈的市场竞争下，企业基于质量和成本的竞争已经日益转化为基于时间的竞争，如何快速响应瞬息万变的市场需求，更快完成生产订单交付？这已成为生产型企业面临的一大痛点。",
            "承接市场客户订单或承接集团中心分解订单，在业务模式上采用订单式生产，且订单图纸往往不是CAD源文件，而是PDF电子版。随着知识产权意识的提高，订单图纸以源文件的方式，在需求企业与生产企业之间传递的越来越少，以PDF图纸文件传递的方式越来越成为主流。",
            "BOM数据贯穿产品的全生命周期，是销售部、工艺部、采购部、财务部和生产部相互协作的重要纽带。在进行生产之前，工艺部的技术人员需要从销售订单中，获取产品的设计数据信息，编制出工艺BOM，从而确保工艺数据的准确性、完整性和可执行性。然后生产部门通过工艺BOM编制出制造BOM,为生产组织管理、原料采购、车间备料、价格定额以及成本核算提供数据支撑。"
        ],
        Challenges: {
            showFlag:true,
            summary: ["但在企业的实际数字化实践中，存在自动化程度高，但信息化水平不足的现象。具体如下："
            ],
            summaryPic: "",
            parts: [
                {
                    title: "数据不互通造成人工成本增大",
                    imgSrc: require("@/assets/companyImgs/沈阳特变业务1.jpg"),
                    content: ["生产企业接收到的订单信息中，包含产品的设计图纸，其中的图纸格式通常是PDF,主流的工业数字化软件均还不具备将图纸PDF数据转为结构化数据，在各系统软件中传递。这时，工艺部的技术人员需要将图纸上的数据人工搬运到EXCEL中，制作成工艺BOM，开始进入生产流通。",
                        "技术人员手工建立BOM，需要在大量图纸中建立产品结构树，然后汇总图纸数据录入在EXCEL中。这种人工模式速度慢，易出错。在企业的销售旺季，由于订单爆满，技术人员无法满足繁重的拆图需求，如果增加人力，则在企业的销售淡季，出现技术人员的闲置，企业的人力成本=增加的人力成本+闲置的人力成本，一个技术人员薪资一般在8k/月（一线城市），成本较高。"]
                },
                {
                    title: "数据质量低影响生产质量和进度",
                    imgSrc: require("@/assets/companyImgs/沈阳特变业务2.jpg"),
                    content: ["在企业BOM流通的过程中，工艺部的技术人员根据订单信息编制的工艺BOM是企业BOM数据的源头，也是最重要的基础数据。一旦出现差错，生产部根据其编制的生产安排将出现差错，采购部根据其采购的物流出现差错，从而导致生产的产品与图纸不符，产品的生产周期延长，最终影响订单的交期，从而给企业的技术实力和信誉带来严重影响。"]
                },
                {
                    title: "图纸缺乏管理难追溯影响生产协作",
                    imgSrc: require("@/assets/companyImgs/沈阳特变业务3.jpg"),
                    content: ["工艺部的技术人员借助图纸PDF建立了产品结构树，导出excel生成BOM数据。这一过程产品结构树与图纸之间缺乏关联，其他部门的员工无法通过产品结构树快速、准确地找到图纸，生产各环节人员调阅图纸时间增加，沟通成本高昂，企业内部生产协同损耗大。同时，图纸文件缺乏管理，散落在各自的电脑中，易出现图纸文件使用混乱和遗失的情况，给企业日常经营增加风险。",
                        "企业数字化转型是一个漫长的道路，需要不断优化完善。初级的企业数字化转型是上线系统，重塑企业流程，提高自动化程度。而中级的企业数字化，则是将转型覆盖企业的全业务领域，同时打通系统数据，提高企业信息化，让信息数据在企业全业务流程流动起来。"]
                },
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["以AI智能识别技术为主，人工校验为辅的智能拆图工具，对PDF图纸进行智能能识别，提取每张图纸的明细栏，汇总生成产品BOM表，支持一键导出。降低人工重复操作，提高BOM源头数据采集的准确性和高效性。",
                "还对PDF图纸包构建产品结构树，自动建立图纸之间的层级关系，支持企业人员点击产品结构树的节点，即可展开图纸源文件。方便企业人员快速、准确找到图纸，提高企业内部的协同效率，减少内部损耗。"],
            summaryPic: require("@/assets/companyImgs/沈阳特变解决方案.jpg"),
            parts: []
        },
        ProjectValue: {
            showFlag:true,
            summary: "将工艺部的订单拆图工序平均时耗从15天降至5天，提高效率200%，提高订单交期准确度，生产时间缩短50.8%，助力制造企业实现精益生产。",
            summaryPic: "",
            parts: []
        },
        TransformationValue: []
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg:"http://www.yingde.com/uploadfile/c5c4efbf1dda48beb662a0a1faa911b0.jpg",
            logo: "http://www.yingde.com/uploadfile/606d1e5b349e489e88e138af2be634dd.svg",
            companyName: "盈德气体",
            tags: [
                {
                    tag: "工业气体",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "集团公司",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "万象盈德，不凡气韵",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "跨系统文档共享能力提升",
                    percent: "95%"
                },
                {
                    describe: "跨业务文档互联能力提升",
                    percent: "82%"
                },
                {
                    describe: "供应链商业协作效率提升",
                    percent: "54%"
                }
            ]
        },
        Overview: [
            "盈德气体集团是亚太地区规模最大的独立工业气体服务商，多年来专注于供应氧、氮、氩等高品质工业气体产品，并提供基于氦气及一氧化碳的合成气清洁能源解决方案，在在冶金、化工、半导体、光伏、等诸多与国民经济紧密相连的领域中持续创造价值。"
        ],
        Challenges: {
            showFlag:true,
            summary: [
                "为助力集团国际化战略，集团企业陆续上线OA、EAS、BSF、NC等业务系统，以效率促进效益，实现企业数字化转型。但在推行过程中，由于影像系统基础建设不足，企业客户采用“人工分散处理+纸质文档流转+纸质库房保存”的业务模式，全链条数字化不足严重影响了业务效率。"
            ],
            summaryPic: require("@/assets/companyImgs/盈德气体业务场景.jpg"),
            parts: [
                {
                    title: "在实际的企业业务场景中，存在以下问题：",
                    imgSrc: "",
                    content: []
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["各大业务系统互通数据但不共享文档，致使经办人需要在业务上下游环节重复上传文档，审核人需要跨系统审批；加重业务人员的工作量，耗费服务器更多存储空间，造成较高的系统运维成本。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["当前业务模式依赖纸质文档流转，流转慢、错误率高且易丢失，产生较高的纸张打印成本，同时纸质文档的线下归档，占用大量物理存储空间，严重影响企业降本增效的目的。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["在文档管理上，电子信息与文档难以整合归档，且纸质文档查找检索费时费力，维护起来也非常麻烦。在审计调阅文件时，还容易出现因为找不到关联文档引起的税务审计风险。"]
                }
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["电子影像中台解决方案将文档转为非结构化数据，深度集成多个系统，完成多个系统的高效协同，实现文档数据的实时共享，帮助企业改善业务流程，提高业务效率。"],
            summaryPic: require("@/assets/companyImgs/盈德气体解决方案.jpg"),
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: ["自动化采集文档数据，打通多个业务系统，在后续业务流程中可多次利用，避免文档重复打印与上传。减少了纸张的消耗与人工重复打印上传匹配的工作量，提高业务流程的便捷性。"]
                },
            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "",
            summaryPic: "",
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: "通过电子影像系统，将纸质文档转为电子影像，实现无纸化办公，降低纸质文档的传输成本，减少了纸质文档流转过程中的丢失与毁损，提高文档的安全性和流转效率。"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "在文档管理上，文档数据自动化采集、存储与归档，方便业务人员快速、准确调阅文档影像信息，提高文档的利用效率，有效减少人为差错，规避审计税务风险。"
                },

            ]
        },
        TransformationValue: [
            "电子影像中台解决方案帮助企业构建无纸化、数字化、自动化的电子影像管理系统，消除个系统之间的信息孤岛，对企业业务进行全链条的影像数字化支援，协同各业务系统实现价值最大化，助力企业达到以效率促进效益的目标。"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg: "https://www.cmport.com.hk/Image/2015-12/201512011404139981250.jpg",
            // logo: "https://www.cmport.com.hk/images/logo.png",
            logo:require("@/assets/companyImgs/logo/招商局港口logo.jpeg"),
            companyName: "招商港口",
            tags: [
                {
                    tag: "海运码头",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "央企",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "迈向世界一流的港口综合服务商",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "财务计费账单效率提升",
                    percent: "56%"
                },
                {
                    describe: "业主对账效率提升",
                    percent: "40%"
                },
                {
                    describe: "财务人员工作强度下降",
                    percent: "68%"
                }
            ]
        },
        Overview: [
            "招商港口现为世界领先的港口投资、开发和营运商，截至2021年底，招商港口业务覆盖全球6大洲26个国家和地区的50个港口。2021年，招商港口完成集装箱总吞吐量13,613万TEU，同比增长11.8%，其大中华地区项目完成10,282万TEU，同比增长9.6%；海外项目完成3,331万TEU，同比增长15.3%。2021年，招商港口集团散杂货业务量完成6.1亿吨。",
            "招商港口坚持战略引领，以“提升核心能力、坚持质效并举、把握时代机遇、迈向世界一流”为总体工作思路，以母港建设、业务拓展、创新发展、综合开发、运营管理、资本运作、市场商务七个方面为重点工作方向，全面务实地推进各项工作，致力于向“成为世界一流港口综合服务商”的战略目标迈进。",
            "招商港口为实现世界一流的港口综合服务商的愿景，陆续推动智慧港口建设。"
        ],
        Challenges: {
            showFlag:true,
            summary: [
                "在企业数字化建设初期，上线计费系统解决港口计费繁杂的问题。但在企业的实践中，缺乏基础数据的支持和能力，从而影响新系统的业务利用。"
            ],
            summaryPic: require("@/assets/companyImgs/招商港口业务场景.jpg"),
            parts: [
                {
                    title: "在实际的港口计费业务场景中，存在以下问题：",
                    imgSrc: "",
                    content: []
                },
                {
                    title: "港口计费种类繁杂，单据文件较多",
                    imgSrc: "",
                    content: ["经办人需要花费很长的时间查阅并找到与计费单关联的单据文件，这个过程容易出现错漏，从而最终影响计费单的准确性。"]
                },
                {
                    title: "计费管理流程主要是以纸质单据或者原始单据的流转，在过程中难免会出现丢单、损坏等问题。",
                    imgSrc: "",
                    content: []
                },
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["电子影像系统解决方案为港口计费业务提供了基础的数据支持，实现将纸质单据电子化，集中生成数据被企业高效便捷利用，与计费系统对接，实现信息实时共享，让计费系统发挥重要作用。 "],
            summaryPic: require("@/assets/companyImgs/招商港口解决方案.jpg"),
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: ["电子影像系统通过统一的外部接口与其他系统互联互通，实现数据的自动化采集，方便财务人员快速、准确调阅单据影像信息，提高企业收款效率，加速企业回款。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["通过电子影像系统，将纸质单据转为电子影像，降低纸质单据传输成本，并大大减少了纸质单据流转过程中的丢失与毁损。"]
                }

            ]
        },
        ProjectValue: {
            showFlag:true,
            summary: "港口计费影像系统解决方案，通过与计费系统集成，让计费系统发挥应有价值；解决单据流转、分拣的繁琐，以及财务审核低效问题，深化企业数字化改革。",
            summaryPic: "",
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: "上线时长：2017年至今超过6年"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "应用范围：蛇口SCT码头、赤湾CCT码头"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "计费单业务规模：近10，000笔/月"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "软件系统版本升级：3次"
                },
                {
                    title: "",
                    imgSrc: "",
                    content: "下一步计划：推广其他码头"
                },

            ]
        },
        TransformationValue: [
            "①减少了财务人员如下5个重复性工序：搜索、截图与粘贴、另存文档、打印匹配、邮寄",
            "②财务人员工作效率提升56%",
            "③与业主对账时长缩短到一个周内"
        ]
    },
    {
        BasicInfo: {
            showFlag:true,
            backgroundImg:require("@/assets/companyImgs/正通案例背景图.png"),
            logo: "https://www.zhengtongauto.com/images/Icon_n1.gif",
            companyName: "正通汽车",
            tags: [
                {
                    tag: "汽车4S连锁",
                    link: "/success-stories/list-manufacture/"
                },
                {
                    tag: "豪华汽车经销与服务集团",
                    link: "/success-stories/list-mediumsize/"
                }
            ],
            subject: "享受高品质汽车生活",
            quotation: "",
            leaderName: "",
            data: [
                {
                    describe: "汽车供应链业财档案共享能力提升",
                    percent: "89%"
                },
                {
                    describe: "汽车零配件售后合规率提升",
                    percent: "85%"
                },
                {
                    describe: "财务复核自动化提升",
                    percent: "78%"
                }
            ]
        },
        Overview: [
            "正通汽车服务控股有限公司是国内领先的4S经销店集团，在全国拥有118家经营网点，是中国汽车经销商集团综合实力前十。目前集团企业拥有较大的体量，集团部门架构较多，流程繁杂，步入数字化以后，文档数量陡增。为提高集团企业的档案管理效率，正通汽车集团企业上线了电子档案系统。"
        ],
        Challenges: {
            showFlag:true,
            summary: [
                "但在企业实践中发现，档案借阅功能在档案管理中使用较为频繁。原档案借阅流程缺乏过程管控，管理各环节随意性较大，存在档案丢失、信息泄露等多种风险，影响档案管理的安全性和合规性。"
            ],
            summaryPic: require("@/assets/companyImgs/正通业务场景.jpg"),
            parts: [
                {
                    title: "在实际的档案借阅管理中，存在以下问题：",
                    imgSrc: "",
                    content: ""
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["借阅人发起借阅——档案管理员审批流程缺少领导审批节点，存在保密不周的隐患；各部门之间线下反复沟通，导致档案借阅周期长， 不利于档案查阅利用。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["纸质档案在库外运行或外借时，受人为因素的影响很大，无法实时跟踪档案状态，一旦档案丢失很难找到。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["在档案利用中，存在部分用户档案归还不及时，造成档案丢失，直接导致纸质档案收集不齐全、不规范。"]
                }
            ]
        },
        Solution: {
            showFlag:true,
            summary: ["电子档案系统中的档案借阅功能与EAS系统集成，打通不同系统之间的信息孤岛；增加档案接收、转递的信息采集，消除档案借阅流程的管控盲点；提高档案管理的安全性、合规性和高效性。"],
            summaryPic: require("@/assets/companyImgs/正通解决方案.jpg"),
            parts: [
                {
                    title: "",
                    imgSrc: "",
                    content: ["集成EAS系统，将借阅审批流与业务流的高效融合，实现数据共享，加强档案借阅流程的合规性，提高档案的借阅效率和档案信息的安全性。 "]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: ["通过记录档案的转递信息，实现纸质档案在线实时追踪，规范档案流转的全流程管理，提高档案的安全性。"]
                },
                {
                    title: "",
                    imgSrc: "",
                    content: [
                        "加强档案的借还管理，在借阅期到达时，系统自动回收电子档案的阅读全，或自动对纸质文件发出归还提醒，同时支持档案的延期申请，让档案文件的利用更加规范、有序。 "
                    ]
                },

            ]
        },
        ProjectValue: {
            showFlag:false,
            summary: "",
            summaryPic: "",
            parts: []
        },
        TransformationValue: [
            "X-SMART系统协同EAS系统以一体化的系统整合，实现集团企业内跨组织、跨公司、跨部门档案借阅全过程电子化跟踪管理，帮助集团企业的档案借阅管理过程更便捷清晰、更合规安全。"
        ]
    }
]
<template>
  <!-- 首页-公司介绍页面 -->
  <div id="guidepage">
    <el-container>
      <el-header class="header"
        ><img
          src="@/assets/images/logo.png"
          alt="广州道可维斯技术有限公司"
          class="companyIcon"
      /></el-header>
      <el-main>
        <div class="mainTitle">
          <P>我们要一起做什么(标题)</P>
        </div>
        <div class="firmvVdeo">
          <video
            ref="videoPlayer"
            id="videoPlayer"
            class="video"
            width="60%"
            autoplay
            muted
            controls
          >
            <source src="@/assets/example1.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="description">
          <p class="des">
            [几句总结性活语]例:革命性的文档字化智能软件中台，开启企业离散文档整合高速公路，体验收发管存极致快捷的便利。
          </p>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "companyProfile",
  data() {
    return {
      checked: false,
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.$refs.videoPlayer.muted = false;
      this.$refs.videoPlayer.play();
    });
  },
  methods: {
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
  },
};
</script>

<style lang="less" scoped>
#guidepage {
  height: 100%;
  width: 100%;
  position: relative;
  .el-container {
    width: 100%;
    height: 100%;
    .el-header {
      height: 50px !important;
      .companyIcon {
        float: left;
        margin-top: 15px;
        width: 80px;
      }
    }

    .el-main {
      height: 100%;
      padding: 0;
      // 主标题样式
      .mainTitle {
        height: 25%;
        p {
          color: #000;
          text-align: center;
          font-size: 32px;
        }
      }
      .firmvVdeo {
        //首页视频样式
        video {
          width: 100%;
        }
      }
      // 首页描述部分样式
      .description {
        // height: 100%;
        width: 100%;
        padding: 15px 10px;
        p {
          color: #000;
          word-break: break-all !important;
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    questionType: "使命",
    questionMsg: ""
  },
  getters: {
  },
  mutations: {
    changeType(state, data) {
      state.questionType = data[0]
      state.questionMsg = `感谢您对道可维斯的${data[1]}产品提出宝贵意见!`
    },
    
  },
  actions: {
  },
  modules: {

  }
})
